/*
 * Esta pagina es para mostra un menu de opciones para el departamento de educacion
 */
import { useEffect, useContext } from "react";

import DeptoContext from "../../../context/Departments/Context";
import ProtectedDepartment from "../ProtectedDepartment";

// members
import LiberationMenuRetreats from "./members/LiberationMenuRetreats";
import LiberationMenuRetreatsAttendance from "./members/LiberationMenuRetreatsAttendance";
import LiberationMenuOptions from "./members/LiberationMenuOptions";
import LiberationPrayPetitions from "./members/LiberationPrayPetitions";
// admin
import LiberationHeaderAdmin from "./director/LiberationHeaderAdmin";
import LiberationIntegrants from "./director/LiberationIntegrants";
import LiberationCreateRetreats from "./director/LiberationCreateRetreats";
import LiberationRetreats from "./director/LiberationRetreats";

export default function LiberationContainer() {
  const { setLiberationMenu, user } = useContext(DeptoContext);

  const changeMemberMenu = () => {
    switch (JSON.parse(localStorage.getItem("liberation_menu"))?.menu) {
      case "OPTIONS":
        return <LiberationMenuOptions />;
      case "RETREATS":
        return <LiberationMenuRetreats />;
      case "RETREATS-ATTENDANCE":
        return <LiberationMenuRetreatsAttendance />;
      case "PRAY-PETITIONS":
        return <LiberationPrayPetitions />;
      default:
        return <LiberationMenuOptions />;
    }
  };

  const changeAdminMenu = () => {
    switch (JSON.parse(localStorage.getItem("liberation_menu"))?.menu) {
      case "INTEGRANTS":
        return <LiberationIntegrants />;
      case "CREATE-RETREATS":
        return <LiberationCreateRetreats />;
      case "RETREATS-ADMIN":
        return <LiberationRetreats />;
      default:
        return <LiberationIntegrants />;
    }
  };

  useEffect(() => {
    const currentMenu = JSON.parse(
      localStorage.getItem("liberation_menu")
    )?.menu;

    // esto es para que cuando se cambie el menu en los miembros, se reinicie en el admin a "INTEGRANTS"
    if (user?.is_director) {
      if (
        (!localStorage.getItem("liberation_menu") &&
          currentMenu === "OPTIONS") || // member option
        currentMenu === "RETREATS" || // member option
        currentMenu === "RETREATS-ATTENDANCE" // member option
      ) {
        localStorage.setItem(
          "liberation_menu",
          JSON.stringify({ menu: "INTEGRANTS" }) // restart admin menu to "INTEGRANTS"
        );
      }
      // si no hay nada en el localstorage
      if (!localStorage.getItem("liberation_menu")) {
        localStorage.setItem(
          "liberation_menu",
          JSON.stringify({ menu: "INTEGRANTS" }) // restart admin menu to "INTEGRANTS"
        );
      }
    }

    setLiberationMenu();
  }, [user]);

  return (
    <ProtectedDepartment>
      <div className=" w-full h-full">
        {user?.is_director && <LiberationHeaderAdmin />}
        <div
          className={` ${user?.is_director ? "h-[calc(100%-75px)]" : "h-full"}`}
        >
          {Object.keys(user).length > 0 ? (
            user?.is_director ? (
              changeAdminMenu()
            ) : (
              changeMemberMenu()
            )
          ) : (
            <div>
              <h1>Cargando...</h1>
            </div>
          )}
        </div>
      </div>
    </ProtectedDepartment>
  );
}
