import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useNotifications } from "reapop";

import DeptoContext from "../../context/Departments/Context";
import { fonts } from "../../assets/styles";

export default function NetworksDiscipleshipDetailsAsignModal() {
  const { selectedDiscipleship } = useContext(DeptoContext);
  const [cdps, setCdps] = useState([]);
  const [cdpsByDiscipleship, setCdpsByDiscipleship] = useState([]); // cdps with discipleship

  // notifications
  const { notify } = useNotifications();

  const handleAsign = async (cdpId) => {
    const { data } = await axios.put(
      `/api/v1/cdps/assign_discipleship/${cdpId}`,
      { id_discipleship: selectedDiscipleship.id }
    );
    if (data.status_code === 201) {
      getAllCdpsByDiscipleshipId();
      getAllCdps();
      notify({
        title: "Asignación exitosa",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
  };
  const handleRemove = async (cdpId) => {
    const { data } = await axios.put(
      `/api/v1/cdps/remove_discipleship/${cdpId}`
    );
    if (data.status_code === 201) {
      getAllCdpsByDiscipleshipId();
      getAllCdps();
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
  };

  const getAllCdpsByDiscipleshipId = async () => {
    const { data } = await axios.get(
      `/api/v1/cdps/discipleship/${selectedDiscipleship.id}`
    );
    setCdpsByDiscipleship(data);
  };
  // cdps without discipleship
  const getAllCdps = async () => {
    const { data } = await axios.get("/api/v1/cdps/discipleship");
    setCdps(data);
  };
  useEffect(() => {
    getAllCdpsByDiscipleshipId();
    getAllCdps();
  }, []);

  return (
    <div className=" mms:px-[10px] mm:px-[20px] min-h-[500px] max-h-[600px] overflow-y-auto ">
      {/* title */}
      <div className=" mb-4 text-center ">
        <h1 className={` mms:text-[24px] mb-1 text-title`}>
          Detalles del Discipulado
        </h1>
      </div>

      {/* content */}
      <div className=" mms:flex-col flex justify-between ">
        {/* selected discipleship*/}
        <div className=" mms:w-full mb-8 px-[10px] w-[400px] ">
          {/* discipleship info */}
          <div className="  mb-6  w-full h-[200px] bg-bg3 rounded-[10px] overflow-y-auto ">
            <div className=" p-[10px] w-full h-[100px] ">
              <p className=" text-third">Líder 1 de Discipulado:</p>
              <p className=" text-content">
                {selectedDiscipleship.leader_one_fullname}
              </p>
              <p className=" text-content">
                {selectedDiscipleship.leader_one_email}
              </p>
            </div>
            {selectedDiscipleship.leader_two_fullname && (
              <div className=" p-[10px] w-full h-[100px] border-t border-options ">
                <p className=" text-third">Líder 2 de Discipulado:</p>
                <p className=" text-content">
                  {selectedDiscipleship.leader_two_fullname}
                </p>
                <p className=" text-content">
                  {selectedDiscipleship.leader_two_email}
                </p>
              </div>
            )}
            <div className=" p-[10px] w-full h-[70px] border-t border-options ">
              <p className=" text-third ">
                {selectedDiscipleship.day} | {selectedDiscipleship.hour}
              </p>
              <small
                className=" inline-block w-full text-content overflow-hidden whitespace-nowrap text-ellipsis "
                title={selectedDiscipleship.address}
              >
                {selectedDiscipleship.address}
              </small>
            </div>
          </div>

          {/* cdp asignations */}
          <div className=" relative p-[20px] pt-0 w-full h-[280px] bg-bg3 rounded-[10px] overflow-y-auto">
            <div className=" sticky top-0 pb-2 text-center text-third bg-bg3 ">
              <small>Casas de Paz de este Discipulado</small>
            </div>
            <div className={`text-start w-full `}>
              {cdpsByDiscipleship.length > 0 ? (
                cdpsByDiscipleship.map((cdp) => (
                  <div className=" flex justify-between items-center mb-[15px] px-[10px] py-1 bg-bg4 rounded ">
                    <div className={`${fonts.body1} text-title`}>
                      <p>{cdp.leader_one_fullname}</p>
                      {cdp.leader_two_fullname && (
                        <p>{cdp.leader_two_fullname}</p>
                      )}
                    </div>
                    <div>
                      <button
                        onClick={() => handleRemove(cdp.id)}
                        className={` px-[10px] py-1 ${fonts.body1} bg-Red text-title rounded `}
                      >
                        Remover
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className=" text-center text-content ">
                  <p>No hay Cdps</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* cdps list to asign */}
        <div className="mms:w-full px-[10px] w-[400px]">
          <div className=" relative p-[10px] pt-0 h-[calc(100%-30px)] max-h-[550px] overflow-y-auto bg-bg3 rounded-[10px] ">
            <div className=" sticky top-0 min-h-[30px] text-center text-third bg-bg3 ">
              <small>
                Asignar Casas de Paz (sin discipulado) a este Discipulado
              </small>
            </div>
            {cdps.length > 0 ? (
              cdps.map((cdp) => (
                <div
                  className=" mms:flex-col mms:items-start mms:py-2 tm:flex-row tm:items-center flex justify-between mb-[15px] px-[10px] py-1 bg-bg4 rounded "
                  key={cdp.id}
                >
                  <div className={`${fonts.body1} text-title`}>
                    <p>{cdp.leader_one_fullname}</p>
                    {cdp.leader_two_fullname && (
                      <p>{cdp.leader_two_fullname}</p>
                    )}
                  </div>
                  <div className=" mms:mt-2 tm:mt-0 ">
                    <button
                      onClick={() => handleAsign(cdp.id)}
                      className={` px-[10px] py-1 ${fonts.body1} bg-primary text-title rounded `}
                    >
                      Asignar
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <div className=" w-full text-center text-content ">
                <span>No hay CDPs sin discipulado</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
