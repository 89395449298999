import axios from "axios";
import { useEffect, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import DeptoContext from "../context/Departments/Context";
import { useNotifications } from "reapop";
import { fonts } from "../assets/styles";

import Layout from "../components/Layout";
import HeaderMenu from "../components/admin/HeaderMenu";

export default function AdminDeptsManageOne() {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    directorsByApartment,
    getDirectorsByDepartment,
    getAvailableMembers,
    membersByDepartment,
    getMembersByDepartment,
  } = useContext(DeptoContext);
  const deptoName = searchParams.get("depto");
  const { deptoId } = useParams();
  const { notify } = useNotifications();

  // remove director from the selected department
  const removeDirectorFromDepartment = async (directorId) => {
    const { data } = await axios.delete(
      `/api/departments/remove/director/${directorId}/`
    );
    getDirectorsByDepartment(deptoId); // update directors list
    getAvailableMembers(); // update available members list
    notify({
      title: "¡Éxito!",
      message: data.message,
      status: "success",
    });
  };

  useEffect(() => {
    getDirectorsByDepartment(deptoId);
    getMembersByDepartment(deptoId);
  }, []);

  return (
    <Layout>
      <HeaderMenu />

      <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] ll:px-[80px] py-[20px] w-auto h-[calc(100%-75px)] overflow-y-auto">
        {/* title */}
        <div className=" mb-4 text-center w-full">
          <h1 className="text-title text-32px">{deptoName}</h1>
        </div>

        {/* content */}
        <div className=" mms:flex-col-reverse mms:px-[10px] mm:px-[20px] ml:px-[40px] lm:flex-row flex justify-between py-[40px] min-h-[500px] max-h-auto w-full bg-bg2 rounded-[25px] overflow-y-auto">
          <div className=" mms:w-full lm:w-[40%]">
            {/* depto directors */}
            <div className="mb-8">
              <p className="mb-1 text-title">Director (es)</p>
              <div className="p-[20px] w-full h-[130px] min-h-[130px] max-h-[130px] bg-bg3 rounded-[10px] overflow-y-auto">
                {directorsByApartment.length > 0 ? (
                  directorsByApartment.map((director) => (
                    <div
                      className="flex justify-between items-center h-[44px] border-b border-options"
                      key={director.id}
                    >
                      <span className="text-content">
                        {director.name} {director.lastname}
                      </span>
                      <button
                        onClick={() =>
                          removeDirectorFromDepartment(director.id)
                        }
                        className="w-[80px] h-[28px] bg-Red rounded-[5px]"
                      >
                        Remover
                      </button>
                    </div>
                  ))
                ) : (
                  <span className="text-content">
                    {deptoName} no tiene directores.
                  </span>
                )}
              </div>
            </div>

            {/* depto members */}
            <div className="mb-8">
              <p className="mb-1 text-title">Integrantes</p>
              <div className="p-[20px] w-full min-h-[200px] max-h-[200px] bg-bg3 rounded-[10px] overflow-y-auto">
                {membersByDepartment.length > 0 ? (
                  membersByDepartment.map((member) => (
                    <div
                      className="flex justify-between items-center h-[44px] border-b border-options"
                      key={member.id}
                    >
                      <span className="text-content">
                        {member.name} {member.lastname}
                      </span>
                      {/* <button className="w-[80px] h-[28px] bg-Red rounded-[5px]">
                        Quitar
                      </button> */}
                    </div>
                  ))
                ) : (
                  <span className="text-content">
                    {deptoName} no tiene miembros.
                  </span>
                )}
              </div>
            </div>
          </div>
          <UserListContainer />
        </div>
      </div>
    </Layout>
  );
}

const UserListContainer = () => {
  const {
    availableMembers,
    getAvailableMembers,
    getDirectorsByDepartment,
    getMembersByDepartment,
  } = useContext(DeptoContext);
  const { deptoId } = useParams();
  const { notify } = useNotifications();

  // add director
  const addDirector = async (userId) => {
    try {
      const { data } = await axios.post("/api/v1/directors/", {
        id_department: deptoId,
        id_member: userId,
      });
      notify({
        title: "¡Éxito!",
        message: data.message,
        status: "success",
      });
      getAvailableMembers();
      getDirectorsByDepartment(deptoId); // update directors list
    } catch (error) {}
  };

  // // add member
  // const addMember = async (userId) => {
  //   try {
  //     const { data } = await axios.post("/api/v1/depto-member/", {
  //       id_department: deptoId,
  //       id_member: userId,
  //     });
  //     notify({
  //       title: "¡Éxito!",
  //       message: data.message,
  //       status: "success",
  //     });
  //     getAvailableMembers();
  //     getMembersByDepartment(deptoId); // update members list
  //   } catch (error) {}
  // };

  useEffect(() => {
    getAvailableMembers();
  }, []);

  return (
    <div className=" mms:w-full lm:w-[55%] h-full">
      <div className="mb-8">
        <p className="mb-1 text-title">Usuarios</p>
        <div className="p-[20px] w-full h-[400px] max-h-[400px] bg-bg3 rounded-[10px] overflow-y-auto">
          {availableMembers.length > 0 ? (
            availableMembers.map((user) => (
              <div
                className="flex justify-between items-center h-[56px] border-b border-options"
                key={user.id}
              >
                <div>
                  <p className="text-subtitle">
                    {user.name} {user.lastname}{" "}
                  </p>
                  <small className={` text-content ${fonts.body2}`}>
                    Asignaciones {user.asignations}
                  </small>
                </div>
                <div>
                  <button
                    onClick={() => addDirector(user.id)}
                    className=" mr-[10px] w-[80px] h-[28px] bg-primary rounded-[5px]"
                  >
                    Director
                  </button>

                  {/* <button
                    onClick={() => addMember(user.id)}
                    className="w-[95px] h-[28px] bg-Green rounded-[5px]"
                  >
                    Integrante
                  </button> */}
                </div>
              </div>
            ))
          ) : (
            <span className="text-content">No hay usuarios disponibles.</span>
          )}
        </div>
      </div>
    </div>
  );
};
