import { useContext } from "react";

import DeptoContext from "../../../../context/Departments/Context";

export default function HeaderAdmin() {
  const { setShowModal, setMenuModal, liberationMenu, setLiberationMenu } =
    useContext(DeptoContext);

  return (
    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] flex items-center px-[80px] h-[75px] w-full border-b border-borders overflow-x-auto ">
      {/* integrants */}
      <div className="flex items-center ">
        <button
          onClick={() => {
            localStorage.setItem(
              "liberation_menu",
              JSON.stringify({
                menu: "INTEGRANTS",
              })
            );
            setLiberationMenu();
          }}
          className={` flex justify-center items-center h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            liberationMenu?.menu === "INTEGRANTS"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          Integrantes
        </button>
        {liberationMenu?.menu === "INTEGRANTS" && (
          <button
            onClick={() => {
              setMenuModal("ADD-MEMBER");
              setShowModal(true);
            }}
            className={` flex justify-center items-center ml-2 h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
              true ? "bg-primary text-white" : "bg-bg2 text-content"
            }`}
          >
            Agregar
          </button>
        )}
      </div>

      <div className=" mx-2 w-[1px] h-[36px] border-r border-options "></div>

      {/* activities */}
      <div className="flex items-center mr-4">
        <button
          onClick={() => {
            localStorage.setItem(
              "liberation_menu",
              JSON.stringify({
                menu: "RETREATS-ADMIN",
              })
            );
            setLiberationMenu();
          }}
          className={` flex justify-center items-center h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            liberationMenu?.menu === "RETREATS-ADMIN"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          Retiros
        </button>
        <button
          onClick={() => {
            localStorage.setItem(
              "liberation_menu",
              JSON.stringify({
                menu: "CREATE-RETREATS",
              })
            );
            setLiberationMenu();
          }}
          className={` flex justify-center items-center ml-2 w-[120px] h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            liberationMenu?.menu === "CREATE-RETREATS"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          Crear Retiro
        </button>
      </div>
    </div>
  );
}
