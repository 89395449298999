import axios from "axios";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { fonts } from "../assets/styles";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "reapop";

import { setAxiosSettings } from "../utils/helpers";
// import logo from "/logo-dlg.png";
import logo from "../assets/images/logo-dlg.png";

const schema = yup.object({
  email: yup
    .string()
    .email("Ingrese un correo válido por favor")
    .max(64, "Máximo 64 carácteres")
    .required("Ingrese su correo por favor"),
  password: yup
    .string()
    .required("Ingrese su contraseña por favor.")
    .max(64, "Máximo 64 carácteres"),
});

function Login() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const { notify } = useNotifications();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (formdata) => {
    try {
      const { data } = await axios.post("/api/v1/login/", formdata);
      if (data.status_code === 200) {
        Cookies.set("access_token", data.access_token, {
          secure: true,
          sameSite: "none",
        });
        setAxiosSettings();
        localStorage.setItem("user_data", JSON.stringify(data.user_data));
        navigate("/dashboard/departamentos");
      } else {
        setErrorMessage("Datos incorrectos");
      }
    } catch (error) {
      notify({
        title: "Error",
        message: "Ha habido un error, intente más tarde",
        status: "error",
      });
    }
  };

  useEffect(() => {
    // clear error message after 3 seconds
    const timer = setTimeout(() => {
      setErrorMessage(null);
    }, 3000);
    return () => clearTimeout(timer);
  }, [errorMessage]);

  // if already logged in, redirect to dashboard
  const token = Cookies.get("access_token");
  if (token !== undefined) {
    return <Navigate to="/dashboard/departamentos" />;
  }

  return (
    <div className=" mms:w-screen mms:min-h-[700px] mms:h-screen mms:py-[20px] mms:overflow-y-auto h-screen flex justify-center items-center bg-bg ">
      <div className=" mms:min-h-[650px] ">
        {/* logo */}
        <div className=" flex justify-center ">
          <img
            src={logo}
            alt="Logo app"
            width={100}
            height={100}
            className=" mms:w-[150px] mms:h-[150px] ts:w-[185px] ts:h-[185px] "
          />
        </div>
        {/* form container */}
        <div className=" mms:h-full ts:w-[500px] h-auto ">
          <h1
            className={` mms:text-[44px] ts:text-[48px] text-center text-white `}
          >
            ¡Bienvenido!
          </h1>
          <p
            className={` mms:text-[18px] mms:w-[90%] mms:mx-auto ts:text-base my-2 ${fonts.base} text-subtitle text-center`}
          >
            Por favor proporcione sus datos para ingresar
          </p>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className={` mms:mx-auto mms:p-[10px] ts:p-[40px] mms:w-[90%] w-[540px] ${
              errorMessage ? " h-[370px]" : " h-[340px]"
            } bg-bg2 rounded-[25px] `}
          >
            {errorMessage && (
              <div className=" flex items-center mb-2 pl-2 w-[150px] h-[30px] bg-[#C06060] text-[#371616] rounded ">
                <p>{errorMessage}</p>
              </div>
            )}
            <div className=" mms:mb-6 mms:w-full">
              <label className=" mms:text-[18px] ts:text-base text-subtitle mb-2 block">
                Correo Electrónico <span className="text-Red">*</span>
              </label>
              <input
                {...register("email")}
                className=" mms:h-[48px] mms:text-[18px] ts:h-[44px] ts:text-base indent-2 w-full bg-bg4 text-content outline-none rounded "
                maxLength={65}
              />
              <small className="text-red-500">{errors.email?.message}</small>
            </div>

            <div className=" mt-6 w-full">
              <label className="  mms:text-[18px] ts:text-base text-subtitle block mb-2">
                Contraseña <span className="text-Red">*</span>
              </label>{" "}
              <div className=" flex justify-between ">
                <input
                 type={showPassword ? "text" : "password"}
                  className=" mms:h-[48px] mms:text-[18px] ts:h-[44px] ts:text-base indent-2 w-full h-[44px] bg-bg4 text-content outline-none rounded-tl rounded-bl "
                  {...register("password")}
                  maxLength={65}
                />
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className=" flex justify-center items-center w-[44px] bg-bg text-white cursor-pointer rounded-tr rounded-br "
                >
                  <i className={` fa-solid ${showPassword ? "fa-eye-slash" : "fa-eye"} `}></i>
                </div>
              </div>
              <small className="text-red-500">{errors.password?.message}</small>
            </div>

            <div className=" flex justify-start mt-6 w-ful">
              <button className=" mms:h-[48px] mms:text-[18px] ts:h-[44px] ts:text-base bg-primary text-white w-[150px] rounded-[10px]">
                Ingresar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
