import { useContext } from "react";

import DeptoContext from "../../../../context/Departments/Context";

export default function IntersetionHeaderAdmin() {
  const { setShowModal, setMenuModal, intersetionMenu, setIntersetionMenu } =
    useContext(DeptoContext);

  return (
    <div
      className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px]
    flex items-center px-[80px] h-[75px] w-full border-b border-borders overflow-x-auto "
    >
      {/* integrants */}
      <div className="flex items-center mr-4">
        <button
          onClick={() =>
            setIntersetionMenu({
              menu: "INTEGRANTS",
            })
          }
          className={` flex justify-center items-center h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            intersetionMenu.menu === "INTEGRANTS"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          Integrantes
        </button>
        {intersetionMenu.menu === "INTEGRANTS" && (
          <button
            onClick={() => {
              setMenuModal("ADD-MEMBER");
              setShowModal(true);
            }}
            className={` flex justify-center items-center ml-2 h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
              true ? "bg-primary text-white" : "bg-bg2 text-content"
            }`}
          >
            Agregar
          </button>
        )}
        <div className=" mx-2 w-[1px] h-[36px] border-r border-options "></div>
        <button
          onClick={() =>
            setIntersetionMenu({
              menu: "PETITIONS",
            })
          }
          className={` flex justify-center items-center h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            intersetionMenu.menu === "PETITIONS"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          Peticiones
        </button>
      </div>
    </div>
  );
}
