import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { format } from "date-fns";
import { useNotifications } from "reapop";
import { confirmAlert } from "react-confirm-alert";

import DeptoContext from "../../../../context/Departments/Context";
import { fonts } from "../../../../assets/styles";

export default function LiberationRetreatsList({ member = false }) {
  const { setLiberationMenu } = useContext(DeptoContext);
  const [retreats, setRetreats] = useState([]);

  const getAllRetreats = async () => {
    const { data } = await axios.get("/api/v1/retreat/");
    setRetreats(data);
  };
  useEffect(() => {
    getAllRetreats();
  }, []);

  return (
    <div
      className=" mms:px-[10px] mms:h-auto mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px]
    tl:h-full pb-[20px] w-full overflow-y-auto "
    >
      {/* title */}
      <div className=" mb-8 pt-3 text-center bg-bg ">
        <h1 className={` ${fonts.heading4} uppercase`}>Liberacion</h1>
        <p className=" text-content ">
          Próximos retiros de hace 7 días en adelante
        </p>
      </div>

      {/* breadcrumbs */}
      {member && (
        <div className=" mms:flex-col-reverse mms:items-start ml:flex-row ml:items-end flex justify-between mb-2 ">
          {/* content */}
          <div className=" mms:mt-1 ">
            <button
              onClick={() => {
                localStorage.setItem(
                  "liberation_menu",
                  JSON.stringify({
                    menu: "OPTIONS",
                  })
                );
                setLiberationMenu();
              }}
            >
              Opciones
            </button>
            {" > "} <span className=" text-content ">Próximos retiros</span>
          </div>
          <div className=" ">
            <button
              onClick={() => {
                localStorage.setItem(
                  "liberation_menu",
                  JSON.stringify({ menu: "RETREATS-ATTENDANCE" })
                );
                setLiberationMenu();
              }}
              className=" px-[10px] h-[36px] bg-primary rounded "
            >
              Asistencias
              <i className=" ml-2 fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      )}

      <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] flex justify-start flex-wrap gap-10 py-[20px] w-full min-h-[450px] h-auto bg-bg2 rounded-[10px] ">
        {retreats.length > 0 ? (
          retreats.map((retreat) => (
            <RetreatCard
              retreat={retreat}
              getAllRetreats={getAllRetreats}
              member={member}
              key={retreat.id}
            />
          ))
        ) : (
          <div className=" w-full text-center text-content ">
            <span>No hay retiros programados</span>
          </div>
        )}
      </div>
    </div>
  );
}

const RetreatCard = ({ retreat, getAllRetreats, member }) => {
  const { notify } = useNotifications();

  let retreat_type = "Retiro de Bienvenida";
  if (retreat.id_retreats_type === 2) {
    retreat_type = "Retiro de Discipulo";
  } else if (retreat.id_retreats_type === 3) {
    retreat_type = "Retiro de Lider";
  }

  const confirmDeleteRetreat = () => {
    confirmAlert({
      title: "Confirmar",
      message: "¿Está seguro de borrar este retiro?",
      buttons: [
        {
          label: "Si",
          onClick: () => deleteRetreat(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteRetreat = async () => {
    const { data } = await axios.delete(`/api/v1/retreat/${retreat.id}`);
    if (data.status_code === 200) {
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
    getAllRetreats();
  };

  return (
    <div className=" mb-[20px] p-[10px] w-[300px] h-auto bg-bg3 rounded-[10px]">
      <div className=" mb-3 pb-2 border-b border-options">
        <p className=" text-third">Tipo:</p>
        <p className=" text-content">{retreat_type}</p>
      </div>
      <div className=" mb-3  pb-2 border-b border-options">
        <p className=" text-third">Fecha y Hora:</p>
        <p className=" text-content">
          {format(new Date(retreat.date), "dd 'de' MMM 'de' yyyy")}
          {" | "}
          {retreat.hour}
        </p>
      </div>
      <div className=" mb-3 pb-2 border-b border-options">
        <p className=" text-third">
          {retreat.location.startsWith("https://") ? "Ubicación" : "Dirección"}
        </p>
        <div>
          {retreat.location.startsWith("https://") ? (
            <a
              href={retreat.location}
              className=" flex justify-center items-center mt-1 w-[80px] bg-Green rounded "
              target="_blank"
              rel="noreferrer"
            >
              Abrir <i className=" ml-2 fa-solid fa-location-arrow "></i>
            </a>
          ) : (
            <p className=" text-content">{retreat.location}</p>
          )}
        </div>
      </div>
      <div className=" mb-3 pb-2 border-b border-options">
        <p className=" text-third">Encargados:</p>
        <ul className=" max-h-[70px] overflow-y-auto">
          {retreat.managers.split("\n").map((manager, index) => (
            <li className=" text-content" key={index}>
              {" "}
              - {manager}
            </li>
          ))}
        </ul>
      </div>
      {!member && (
        <div className=" mt-4 ">
          {/* delete retreat button */}
          <button
            onClick={() => confirmDeleteRetreat()}
            className=" px-[10px] h-[40px] bg-Red rounded "
          >
            Borrar Retiro
          </button>
        </div>
      )}
    </div>
  );
};
