import axios from "axios";
import { useEffect, useState, useContext } from "react";

import DeptoContext from "../../context/Departments/Context";

import { fonts } from "../../assets/styles";

export default function AddMemberTutor() {
  const [cdps, setCdps] = useState([]);

  const getAllCdps = async () => {
    const { data } = await axios.get("/api/v1/cdps/");
    setCdps(data);
  };
  useEffect(() => {
    getAllCdps();
  }, []);

  return (
    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] w-full h-full">
      <div className="mb-8">
        <div className=" mb-2 text-center">
          <h1 className={` ${fonts.heading3} text-title`}>
            Seleccionar Casa de Paz
          </h1>
          {/* <p className={` ${fonts.body1} text-content`}>
            Agregar integrantes disponibles al departamento de {deptoName}
          </p> */}
          <small className=" text-content ">Agregar Tutor del miembro</small>
        </div>
        <div className="py-[20px] w-full h-[400px] max-h-[400px] bg-bg3 rounded-[10px] overflow-y-auto">
          {cdps.length > 0 ? (
            cdps.map((cdp) => <CdpCardList key={cdp.id} cdp={cdp} />)
          ) : (
            <div className=" w-full text-center text-content ">
              <p>No hay cdps</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// components userList
const CdpCardList = ({ cdp }) => {
  const { setShowModal, setTutor } = useContext(DeptoContext);

  // go to the backend to get the tutor info based on the cdp id
  const handleSelectCdp = async (cdpId) => {
    const { data } = await axios.get(
      `/api/v1/cdps/add_new_convert_tutor/${cdpId}` // networks
    );
    setTutor(data); // return the cdp leader one info
    setShowModal(false);
  };

  return (
    <div className=" mms:flex-col mms:items-start mms:py-2 mms:h-auto tm:flex-row tm:items-center flex justify-between mb-[20px] px-[10px] h-[56px] text-title bg-bg3 border-b border-options ">
      <div className=" w-[200px] text-start">
        <p className=" overflow-hidden whitespace-nowrap text-ellipsis">
          <span className=" text-content ">Líder 1: </span>
          {cdp.leader_one_fullname}
        </p>
        {cdp.leader_two_fullname && (
          <p className=" overflow-hidden whitespace-nowrap text-ellipsis">
            <span className=" text-content ">Líder 2: </span>
            {cdp.leader_two_fullname}
          </p>
        )}
      </div>

      <div className=" mms:mt-2 mms:text-start tm:mt-0 w-[120px] text-center ">
        <button
          onClick={() => handleSelectCdp(cdp.id)}
          className=" px-[10px] py-1 bg-primary text-white rounded "
        >
          <span className="">Seleccionar</span>
        </button>
      </div>
    </div>
  );
};
