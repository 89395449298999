import axios from "axios";
import { useEffect, useState, useContext } from "react";

import DeptoContext from "../../../../context/Departments/Context";
import { fonts } from "../../../../assets/styles";

export default function NetworksMenuDiscipleshipByNetwork() {
  const { selectedNetwork, setSelectedNetwork, setNetworksMemberMenu } =
    useContext(DeptoContext);

  const [discipleships, setDiscipleships] = useState(null);

  const getAllDiscipleships = async () => {
    const { data } = await axios.get(
      `/api/v1/discipleships/network/${selectedNetwork.id}`
    );
    setDiscipleships(data);
  };

  // load networks once
  useEffect(() => {
    // console.log(selectedNetwork);
    getAllDiscipleships();
    // setNetworksMemberMenu();
  }, []);

  return (
    <div className=" mms:px-[10px] mms:h-auto mm:px-[20px] ml:px-[40px] tm:px-[60px] tl:h-full ll:px-[80px] pb-[20px] w-full overflow-y-auto">
      <div className=" my-4 text-center bg-bg ">
        <h1 className={` mms:text-[24px] ts:text-[32px] text-title uppercase `}>
          Discipulados
        </h1>
      </div>
      {/* breadcrumbs */}
      <div className=" flex mb-2 w-full ">
        <button
          onClick={() => {
            setNetworksMemberMenu({
              menu: "NETWORKS",
            });
          }}
          className=" text-title "
        >
          {selectedNetwork?.name + " > "}
        </button>
        <p className=" ml-1 text-content ">Discipulados</p>
      </div>

      {/* content */}
      <div className=" p-[40px] w-full min-h-[450px] h-auto bg-bg2 rounded-[10px] ">
        {discipleships === null ? (
          <div className=" text-center text-content ">
            <p>Seleccione un red</p>
          </div>
        ) : discipleships.length > 0 ? (
          discipleships.map((discipleship) => (
            <DiscipleshipCard
              discipleship={discipleship}
              key={discipleship.id}
            />
          ))
        ) : (
          <div className=" text-center text-content ">
            <p>No hay discipulados en esta red</p>
          </div>
        )}
      </div>
    </div>
  );
}

const DiscipleshipCard = ({ discipleship }) => {
  const { setSelectedDiscipleship, setNetworksMemberMenu } =
    useContext(DeptoContext);

  return (
    <div className=" mms:flex-col mms:items-start txl:flex-row txl:items-center flex justify-between mb-[20px] p-[10px] w-full h-auto bg-bg3 rounded-[10px]">
      <div className=" mms:flex-col ts:flex-row items-center flex border-options">
        <p className=" mr-2 text-content ">Líder (es): </p>
        <div className=" mms:flex-col ts:flex-row ts:items-center flex ">
          <p className=" text-content ">{discipleship.leader_one_fullname}</p>
          {discipleship.leader_two_fullname && (
            <div className=" text-content ">
              <span className=" mms:hidden ts:inline mx-2 ">&</span>
              <span>{discipleship.leader_two_fullname}</span>
            </div>
          )}
        </div>
      </div>
      <div className=" mms:mt-2 ">
        <button
          onClick={() => {
            setSelectedDiscipleship(discipleship);
            setNetworksMemberMenu({
              menu: "DISCIPLESHIPS-ATTENDANCE",
            });
          }}
          className=" px-[10px] h-[32px] bg-primary text-white rounded "
        >
          Detalles
          <i className=" ml-2 fa-solid fa-up-right-from-square"></i>
        </button>
      </div>
    </div>
  );
};
