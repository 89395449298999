import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function HeaderMenu() {
  const [_createUser, setCreateUser] = useState(false);
  const [_usersList, setUserList] = useState(false);
  const [_deptos, setDeptos] = useState(false);
  const [_deptosManage, setDeptosManage] = useState(false);
  const [_createActivity, setCreateActivity] = useState(false);
  const [_activities, setActivities] = useState(false);

  const location = useLocation();

  const usersList = "/dashboard/admin/usuarios";
  const createUser = "/dashboard/admin/usuarios/crear";
  const deptos = "/dashboard/admin/departamentos";
  const deptosManage = "/dashboard/admin/departamentos/administrar";
  const activityCreate = "/dashboard/admin/actividades/crear";
  const activities = "/dashboard/admin/actividades";

  useEffect(() => {
    if (location.pathname === createUser) {
      setCreateUser(true);
    } else if (location.pathname === usersList) {
      setUserList(true);
    } else if (location.pathname === activityCreate) {
      setCreateActivity(true);
    } else if (location.pathname === activities) {
      setActivities(true);
    } else {
      setDeptos(true);
    }
  }, [location.pathname]);

  return (
    <div
      className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px]
    flex items-center px-[80px] w-full h-[75px] border-b border-options overflow-x-auto "
    >
      <div className="flex">
        <LinkMenu isPrimary={_usersList} to={usersList}>
          Usuarios
        </LinkMenu>
      </div>
      <div className=" ml-2 ">
        <LinkMenu isPrimary={_createUser} to={createUser}>
          Crear
        </LinkMenu>
      </div>
      <div className=" mx-[10px] h-[36px] text-content border-r border-borders "></div>
      <div className="flex ">
        <LinkMenu isPrimary={_deptos} to={deptos}>
          Departamentos
        </LinkMenu>
      </div>
      <div className=" mx-[10px] h-[36px] text-content border-r border-borders "></div>
      <div className="flex ">
        <LinkMenu isPrimary={_activities} to={activities}>
          Actividades
        </LinkMenu>
      </div>
      <div className="flex ml-[20px]">
        <LinkMenu isPrimary={_createActivity} to={activityCreate}>
          Crear
        </LinkMenu>
      </div>
    </div>
  );
}

const LinkMenu = ({ to, children, isPrimary = false }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate(to)}
      className={` flex justify-center items-center h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
        isPrimary ? "bg-primary text-white" : "bg-bg2 text-content"
      }`}
    >
      {children}
    </button>
  );
};
