import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { useNotifications } from "reapop";

import DeptoContext from "../../context/Departments/Context";
import { fonts } from "../../assets/styles";

export default function AsignCDP() {
  const { selectedCdp } = useContext(DeptoContext);
  const [showGetMembers, setShowGetMembers] = useState(false);
  const [peopleToAsignCdp, setPeopleToAsignCdp] = useState([]);

  const { notify } = useNotifications();

  const getPeopleToAsignCdp = async () => {
    if (showGetMembers) {
      const { data } = await axios.get("/api/v1/members/cdp_disciples"); // no cdp_leader niether cdp_disciple
      data.sort((a, b) => a.name.localeCompare(b.name));
      setPeopleToAsignCdp(data);
    } else {
      const { data } = await axios.get("/api/v1/new_converts/cdp_disciples"); // no cdp_leader niether cdp_disciple
      data.sort((a, b) => a.name.localeCompare(b.name));
      setPeopleToAsignCdp(data);
    }
  };

  const asignCdpToDisciple = async (disciple) => {
    try {
      const discipleData = {
        name: disciple.name,
        lastname: disciple.lastname,
        phone: disciple.phone,
        is_member: showGetMembers,
        code_reference: disciple.code,
        id_cdp: selectedCdp.id,
      };
      const { data } = await axios.post("/api/v1/disciples", discipleData);
      if (data.status_code === 201) {
        getPeopleToAsignCdp(); // get all people again to refresh the list
        notify({
          title: "Discipulo asignado",
          message: data.message,
          status: "success",
        });
      } else {
        notify({
          title: "Aviso",
          message: data.message,
          status: "error",
        });
      }
    } catch (error) {
      notify({
        title: "Error",
        message:
          "Ha habido un error al asignar al discipulo. Intente nuevamente por favor.",
        status: "error",
      });
    }
  };

  useEffect(() => {
    getPeopleToAsignCdp();
  }, [showGetMembers]);

  return (
    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] w-full h-full ">
      <div className="mb-8">
        {/* title */}
        <div className=" mb-4 text-center">
          <h1 className={`mb-1 ${fonts.heading3} text-title`}>
            Asignar Casa de Paz
          </h1>
        </div>

        {/* show people to be student */}
        {/* <div className=" mms:mb-2 mms:px-[10px] mms:bg-bg2 ml:px-0 ml:bg-bg2 flex items-center h-[32px] overflow-x-auto rounded-[25px] "> */}
        <div className=" mms:mb-2 mms:bg-bg3 ml:px-0 mm:bg-bg2 flex justify-start items-center h-auto overflow-x-auto rounded-[25px] border-2 border-pink-600 ml:max-w-[350px] ">
          {/* <div className=" flex items-center px-[0px] w-[300px] h-[28px] bg-[#D9D9D9] text-content rounded-[25px] "> */}
          <div className=" flex items-center px-[2px] mms:w-full w-[300px] h-[32px] bg-[#D9D9D9] text-content rounded-[25px] border-2 border-yellow-400 ">
            <button
              onClick={() => setShowGetMembers(false)}
              // className={` w-[150px] h-[28px] ${
              className={` mms:w-[50%] h-[28px] ${
                !showGetMembers
                  ? " bg-primary text-white "
                  : " bg-[#d9d9d9] text-content"
              } rounded-[25px] outline-none`}
            >
              Creyentes
            </button>
            <button
              onClick={() => setShowGetMembers(true)}
              // className={` w-[150px] h-[28px] ${
              className={` mms:w-[50%] h-[28px] ${
                showGetMembers
                  ? " bg-primary text-white "
                  : " bg-[#d9d9d9] text-content"
              } rounded-[25px] outline-none`}
            >
              Usuarios
            </button>
          </div>
        </div>

        {/* selected cdp */}
        <div className=" my-[20px] px-[20px] py-[10px] h-auto bg-bg3 rounded-[10px]">
          <div className=" flex text-content">
            <p className="mr-2 text-title">Líder 1</p>
            <p className="text-content">{selectedCdp.leader_one_fullname}</p>
          </div>
          {selectedCdp.leader_two_fullname && (
            <div className=" flex text-content">
              <p className="mr-2 text-title">Líder 2</p>
              <p className="text-content">{selectedCdp.leader_two_fullname}</p>
            </div>
          )}
        </div>

        {/* people list */}
        <div className=" w-full">
          <small className="text-content">
            Solo se muestran las personas que no son discipulos aún.
          </small>
        </div>
        <div className="p-[20px] w-full h-[300px] bg-bg3 rounded-[10px] overflow-y-auto ">
          {peopleToAsignCdp.length > 0 ? (
            peopleToAsignCdp.map((person) => (
              <div
                className=" mms:flex-col mms:items-start mms:py-2 mms:h-auto flex justify-between items-center mb-[20px] px-[20px] h-[56px] bg-bg4 rounded-[10px]"
                key={person.id}
              >
                <div className=" mms:mb-2 ">
                  <p>
                    {person.name} {person.lastname}
                  </p>
                  {person.is_cdp_leader && (
                    <small className=" text-Green">
                      <li>Es Líder</li>
                    </small>
                  )}
                </div>
                <div>
                  <button
                    onClick={() => asignCdpToDisciple(person)}
                    className={` px-[10px] w-auto h-[28px] bg-primary ${fonts.body1} rounded`}
                  >
                    Asignar
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p className="text-content">No hay personas disponibles.</p>
          )}
        </div>
      </div>
    </div>
  );
}
