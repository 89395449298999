import React from "react";
import logo from "../assets/images/logo-dlg.png";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div className=" mms:w-screen mms:min-h-[700px] mms:h-screen mms:py-[20px] mms:overflow-y-auto h-screen flex justify-center items-center bg-bg ">
      <div className=" ">
        {/* logo */}
        <div className=" flex justify-center ">
          <img
            src={logo}
            alt="Logo app"
            width={100}
            height={100}
            className=" mms:w-[150px] mms:h-[150px] ts:w-[185px] ts:h-[185px] "
          />
        </div>
        <div className=" mt-[40px] text-center ">
          <h1 className=" text-xl text-title ">Página no encontrada</h1>
          <div className=" mt-[20px] text-white ">
            <Link
              to="/dashboard/departamentos"
              className=" flex justify-center items-center bg-primary w-[200px] h-[40px] rounded "
            >
              Volver al inicio <i className=" ml-2 fa-solid fa-right-long"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
