import axios from "axios";
import { useEffect, useState, useContext } from "react";

import DeptoContext from "../../../../context/Departments/Context";
import { fonts } from "../../../../assets/styles";

export default function AfirmationCreateCDP() {
  const [cdps, setCdps] = useState([]);

  const getAllCdps = async () => {
    const { data } = await axios.get("/api/v1/cdps/");
    data.sort((a, b) => a.leader_one_fullname.localeCompare(b.leader_one_fullname));
    setCdps(data);
  };
  useEffect(() => {
    getAllCdps();
  }, []);

  return (
    // container
    <div
      className=" mms:px-[10px] mms:py-[20px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px]
      w-auto h-full bg-bg overflow-y-auto "
    >
      <div className=" mb-4 text-center w-full">
        <h1 className={`text-title ${fonts.heading4} uppercase`}>
          Casas de paz
        </h1>
      </div>
      {/* content */}
      <div className=" mms:px-[10px] mm:px-[20px] min-h-[500px] max-h-auto w-full bg-bg2 rounded-[10px] overflow-y-auto">
        <div className=" mms:hidden px-[40px] h-[64px] bg-bg3 rounded-tr-[25px] rounded-tl-[25px]">
          <div
            className={` flex justify-between items-center mb-[20px] px-[20px] h-full text-white ${fonts.heading3} bg-bg3 rounded-[10px`}
          >
            <div className=" w-[200px] text-start">
              <p className=" overflow-hidden whitespace-nowrap text-ellipsis">
                Líder 1
              </p>
            </div>
            <div className=" w-[200px] text-center">
              <p className=" overflow-hidden whitespace-nowrap text-ellipsis">
                Líder 2
              </p>
            </div>
            <div className=" w-[120px] text-center">
              <p className=" overflow-hidden whitespace-nowrap text-ellipsis">
                Detalles
              </p>
            </div>
            <div className=" w-[120px] text-center ">
              <p className=" overflow-hidden whitespace-nowrap text-ellipsis">
                Acciones
              </p>
            </div>
          </div>
        </div>
        {/* cdp list */}
        <div className=" py-[20px]">
          {cdps.length > 0 ? (
            cdps.map((cdp) => <CdpCardList key={cdp.id} cdp={cdp} />)
          ) : (
            <div className=" text-center text-content ">
              <p>No hay CDPs</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// components userList
const CdpCardList = ({ cdp }) => {
  const { setShowModal, setMenuModal, setSelectedCdp, setAfirmationMenu } =
    useContext(DeptoContext);

  return (
    <div className=" mms:flex-col mms:items-start mms:h-auto mms:py-2 lm:flex-row lm:items-center flex justify-between items-center mb-[20px] px-[20px] h-[56px] text-title bg-bg3 rounded-[10px] ">
      <div className=" mms:flex-col ts:flex-row flex justify-between w-full ">
        {/* leader one */}
        <div className=" w-[200px] text-start">
          <p
            className=" text-third overflow-hidden whitespace-nowrap text-ellipsis"
            title={cdp.leader_one_fullname}
          >
            {cdp.leader_one_fullname}
          </p>
        </div>

        {/* leader two */}
        <div className=" mms:text-start text-center w-[200px] ">
          {cdp.leader_two_fullname ? (
            <p
              className=" text-third overflow-hidden whitespace-nowrap text-ellipsis"
              title={cdp.leader_two_fullname}
            >
              {cdp.leader_two_fullname}
            </p>
          ) : (
            <p className=" text-content">Sin Líder 2</p>
          )}
        </div>
      </div>

      <div className=" mms:flex-col ts:flex-row ts:items-center flex justify-between w-full ">
        {/* details */}
        <div className=" mms:text-start w-[200px] text-center ">
          <button
            onClick={() => {
              setSelectedCdp(cdp);
              localStorage.setItem(
                "afirmation_menu",
                JSON.stringify({ menu: "CDP-DETAILS" })
              );
              setAfirmationMenu();
            }}
          >
            <span className=" underline">Detalles</span>
            <i className=" ml-2 fa-solid fa-up-right-from-square"></i>
          </button>
        </div>

        {/* assign */}
        <div className=" mms:mt-2 mms:text-start w-[200px] text-center ">
          <button
            onClick={() => {
              setSelectedCdp(cdp);
              setMenuModal("ASIGN-CDP");
              setShowModal(true);
            }}
            className=" px-[10px] py-1 bg-primary text-white rounded "
          >
            <span className="">Asignar</span>
            <i className=" ml-2 fa-solid fa-up-right-from-square"></i>
          </button>
        </div>
      </div>
    </div>
  );
};
