import axios from "axios";
import Cookies from "js-cookie";

// this function is used to set the axios settings
export const setAxiosSettings = () => {
  // axios.defaults.baseURL = process.env.REACT_APP_BACKEND_BASE_URL;
  const token = Cookies.get("access_token");
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  axios.defaults.headers.post["Content-Type"] = "application/json";
};
