/*
 * Esta pagina es para mostra un menu de opciones para el departamento de "Damos por Gracia" (members and directors)
 */
import { useContext } from "react";

import DeptoContext from "../../../context/Departments/Context";
import ProtectedDepartment from "../ProtectedDepartment";

// admin
import GiveGraceHeaderAdmin from "./director/GiveGraceHeaderAdmin";
import GiveGraceIntegrants from "./director/GiveGraceIntegrants";
// members
import GiveGraceHelpRequests from "./members/GiveGraceHelpRequests";

export default function Afirmacion() {
  const { user } = useContext(DeptoContext);

  return (
    <ProtectedDepartment>
      <div className=" w-full h-full">
        {user?.is_director && <GiveGraceHeaderAdmin />}
        <div
          className={` ${user?.is_director ? "h-[calc(100%-75px)]" : "h-full"}`}
        >
          {Object.keys(user).length > 0 ? (
            user?.is_director ? (
              <GiveGraceIntegrants />
            ) : (
              <GiveGraceHelpRequests />
            )
          ) : (
            <div>
              <h1>Cargando...</h1>
            </div>
          )}
        </div>
      </div>
    </ProtectedDepartment>
  );
}
