/*
 * Esta pagina es para mostra un menu de opciones para el departamento de educacion
 */
import { useEffect, useContext } from "react";

import DeptoContext from "../../../context/Departments/Context";
import ProtectedDepartment from "../ProtectedDepartment";

// members
import MenuProcess from "./members/MenuProcess";
// import MenuStudents from "./members/MenuStudents";
// admin
import EducationHeaderAdmin from "./director/EducationHeaderAdmin";
import EducationIntegrants from "./director/EducationIntegrants";
import UnsubscribeStudents from "./director/UnsubscribeStudents";

export default function Education() {
  const { setEducationMenu, user } = useContext(DeptoContext);

  const changeAdminMenu = () => {
    // switch (educationMenu.menu) {
    switch (JSON.parse(localStorage.getItem("education_menu"))?.menu) {
      case "ADMIN-INTEGRANTS":
        return <EducationIntegrants />;
      case "ADMIN-UNSUBSCRIBE-STUDENTS":
        return <UnsubscribeStudents />;
      default:
        return <EducationIntegrants />;
    }
  };

  useEffect(() => {
    if (user?.is_director) {
      // si no hay nada en el localstorage
      if (!localStorage.getItem("education_menu")) {
        localStorage.setItem(
          "education_menu",
          JSON.stringify({ menu: "ADMIN-INTEGRANTS" }) // restart admin menu to "INTEGRANTS"
        );
      }
    }

    setEducationMenu();
  }, [user]);

  return (
    <ProtectedDepartment>
      <div className=" w-full h-full">
        {user?.is_director && <EducationHeaderAdmin />}
        <div
          className={` ${user?.is_director ? "h-[calc(100%-75px)]" : "h-full"}`}
        >
          {Object.keys(user).length > 0 ? (
            user?.is_director ? (
              changeAdminMenu()
            ) : (
              <MenuProcess />
            )
          ) : (
            <div className="">
              <h1>Cargando...</h1>
            </div>
          )}
        </div>
      </div>
    </ProtectedDepartment>
  );
}
