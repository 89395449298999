import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import DeptoContext from "../../../../context/Departments/Context";
import { fonts } from "../../../../assets/styles";

export default function EvangelismMenuOptions() {
  const { educationMenu, setLiberationMenu, user } = useContext(DeptoContext);
  const [processes, setProcesses] = useState([]);

  const getProcesses = async () => {
    const { data } = await axios.get("/api/v1/processes/");
    setProcesses(data);
  };
  useEffect(() => {
    getProcesses();
  }, []);

  return (
    <div className=" mms:px-[10px] mms:h-auto mm:px-[20px] ml:px-[40px] tm:px-[60px] tl:h-full ll:px-[80px] border border-bg ">
      <div className=" text-center my-8 ">
        <h1 className={` mms:text-[24px] tl:text-[32px] text-title uppercase`}>
          Liberacion
        </h1>
        <p className=" text-content ">Opciones</p>
      </div>

      {/* options */}
      <div className=" mms:h-[300px] ts:h-[400px] flex flex-col justify-center items-center w-full ">
        <div className=" text-start mb-8 w-full h-[64px] ">
          <button
            onClick={() => {
              localStorage.setItem(
                "liberation_menu",
                JSON.stringify({
                  menu: "RETREATS",
                })
              );
              setLiberationMenu();
            }}
            className=" mms:text-base ts:text-xl flex justify-between items-center px-[20px] w-full h-full text-start text-content bg-bg2 hover:bg-bg3 hover:text-title duration-200 rounded-[10px] "
          >
            Próximos retiros
            <i className=" fa-solid fa-caret-right"></i>
          </button>
        </div>
        <div className=" text-start w-full h-[64px] ">
          <button
            onClick={() => {
              localStorage.setItem(
                "liberation_menu",
                JSON.stringify({
                  menu: "PRAY-PETITIONS",
                })
              );
              setLiberationMenu();
            }}
            className=" mms:text-base ts:text-xl flex justify-between items-center px-[20px] w-full h-full text-start text-content bg-bg2 hover:bg-bg3 hover:text-title duration-200 rounded-[10px] "
          >
            Peticiones de oración
            <i className=" fa-solid fa-caret-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
}
