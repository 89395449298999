/*
 * Página para listar todos los departamentos de forma publica
 */
import axios from "axios";
import { useEffect, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { fonts } from "../assets/styles";
import Layout from "../components/Layout";
import { useNotifications } from "reapop";

import DeptoContext from "../context/Departments/Context";

export default function Departments() {
  const token = Cookies.get("access_token");
  const { departments, getDepartments } = useContext(DeptoContext);

  useEffect(() => {
    getDepartments();
  }, []);

  if (token === undefined) {
    return <Navigate to="/" />;
  }
  return (
    <Layout>
      <div className=" mms:px-[10px] mms:h-auto mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px] tl:h-full  px-[80px] bg-bg mms:overflow-y-auto ">
        <div className="my-8 text-center ">
          <h1
            className={` mms:text-[24px] tm:text-[32px] text-title uppercase `}
          >
            Departamentos
          </h1>
        </div>
        <div
          className=" mms:grid-cols-1 tl:grid-cols-2 lm:grid-cols-4 mms:h-auto tl:content-between tl:h-auto ll:h-[500px]
          grid  justify-center items-center w-full "
        >
          {departments.map((depto) => (
            <DepartmentCard depto={depto} key={depto.id} />
          ))}
        </div>
      </div>
    </Layout>
  );
}

const DepartmentCard = ({ depto }) => {
  const { user } = useContext(DeptoContext);
  const basePath = "/dashboard/departamentos";

  const { notify } = useNotifications();
  const navigate = useNavigate();

  const handleRedirectDepartment = async (deptoId) => {
    // vefiricar que el director o miembro y el departamento existan
    const { data } = await axios.get(
      `/api/v1/director-member/${user.id}/department/${deptoId}/`
    );
    // redirigir a miembro a su departamento
    if (data.status_code === 200) {
      const to = `${basePath}/${depto.id}?depto=${encodeURIComponent(
        depto.name
      )}`;
      return navigate(to);
    } else {
      notify({
        title: "¡Información!",
        message: "Al parecer no eres integrante de este departamento",
        status: "info",
      });
    }
  };

  const verifyNetworksIntegrants = async () => {
    // verify if the member is a director
    const { data } = await axios.get(
      `/api/v1/director-member/${user.id}/department/${7}/` // 7 es el id del departamento de redes
    );
    if (data.status_code === 200) {
      const to = `${basePath}/${depto.id}?depto=${encodeURIComponent(
        depto.name
      )}`;
      return navigate(to);
    } else {
      // verify if the member is an integrant
      const nIntegrantResp = await axios.get(
        `/api/v1/networks_integrants/${user.id}`
      );
      if (nIntegrantResp.data.status_code === 200) {
        const to = `${basePath}/${depto.id}?depto=${encodeURIComponent(
          depto.name
        )}`;
        return navigate(to);
      } else {
        notify({
          title: "¡Información!",
          message: "Al parecer no eres integrante de este departamento",
          status: "info",
        });
      }
    }
  };

  return (
    <button
      onClick={() =>
        depto.id === 7
          ? verifyNetworksIntegrants()
          : handleRedirectDepartment(depto.id)
      }
      className={` mms:mb-[20px] mms:w-full mms:h-[56px] mms:px-[10px] mms:justify-between mms:rounded 
        tl:w-[200px] tl:h-[200px] tl:justify-center lm:w-[180px] lm:h-[180px]  ll:w-[200px] ll:h-[200px] flex items-center bg-bg2
      text-content border border-borders hover:text-title hover:bg-bg3 duration-200`}
    >
      <p className=" mms:text-[20px] mms:text-start tl:text-[28px] tl:w-full font-extralight tl:text-center ">
        {depto.name}
      </p>
      <i className=" mms:inline text-base tl:hidden fa-solid fa-caret-right "></i>
    </button>
  );
};
