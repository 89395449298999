import axios from "axios";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

export default function ProtectedDepartment({ children }) {
  const { deptoId } = useParams();
  const navigate = useNavigate();

  const basePath = "/dashboard/departamentos";

  const handleRedirect = async () => {
    const userId = JSON.parse(localStorage.getItem("user_data")).id;
    // vefiricar que el director o miembro y el departamento existan
    const { data } = await axios.get(
      `/api/v1/director-member/${userId}/department/${deptoId}/`
    );
    // redirigir a miembro a su departamento
    if (data.status_code === 200) {
      const deptoResp = await axios.get(`/api/v1/departments/${deptoId}/`);
      const to = `${basePath}/${deptoId}?depto=${encodeURIComponent(
        deptoResp.data.name
      )}`;
      return navigate(to);
    } else {
      const nIntegrantResp = await axios.get(
        `/api/v1/networks_integrants/${userId}`
      );
      if (nIntegrantResp.data.status_code === 200) {
        const deptoResp = await axios.get(`/api/v1/departments/${deptoId}/`);
        const to = `${basePath}/${deptoId}?depto=${encodeURIComponent(
          deptoResp.data.name
        )}`;
        return navigate(to);
      } else {
        confirmAlert({
          title: "¡Advertencia!",
          message:
            "Al parecer no eres integrante de este departamento. Serás redirigido.",
          buttons: [
            {
              label: "Aceptar",
              onClick: () => {
                return navigate("/dashboard/departamentos");
              },
            },
          ],
        });
        return navigate("/dashboard/departamentos");
      }
    }
  };

  useEffect(() => {
    handleRedirect(JSON.parse(localStorage.getItem("user_data")).id);
  }, []);

  return children;
}
