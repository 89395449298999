import axios from "axios";
import { useEffect, useState } from "react";
import { format } from "date-fns";
import jsPDF from "jspdf";
import "jspdf-autotable";


export default function MenuProgressPhases() {
  const [prayPetitions, setPrayPetitions] = useState([]);

  const generatePDF = (data) => {
    const pdf = new jsPDF();
    pdf.setFontSize(11);

    let usedHeight = 0;
    let y = 10;
    data.forEach((item, index) => {
      const {
        for_fullname,
        for_phone,
        date,
        made_by_fullname,
        depto_from,
        made_by_phone,
        petition,
      } = item;

      const x = 10;

      usedHeight += y;

      if (pdf.internal.pageSize.getHeight() - usedHeight < y) {
        pdf.addPage();
        usedHeight = 10;
      }

      const textLines = pdf.splitTextToSize(
        `Nombre de persona: ${for_fullname}\n` +
          `Telefono de persona: ${for_phone}\n` +
          `Fecha: ${date}\n` +
          `Hecha por: ${made_by_fullname}\n` +
          `Departamento: ${depto_from}\n` +
          `Teléfono: ${made_by_phone}\n` +
          "Petición:\n" +
          petition,
        pdf.internal.pageSize.getWidth() - x * 2
      );

      pdf.text(textLines, x, usedHeight);

      y = Math.ceil(petition.length / 88) * 5 + 40; // 88 is the number of characters that fit in a line
    });

    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1; // JavaScript months are 0-based
    const year = date.getFullYear();
    pdf.save(`peticiones-de-oracion-${day}-${month}-${year}.pdf`);
  };

  const getAllPrayPetitions = async () => {
    const { data } = await axios.get("/api/v1/only/pray_petitions/");
    setPrayPetitions(data);
  };
  useEffect(() => {
    getAllPrayPetitions();
  }, []);

  return (
    <div className="  mms:px-[10px] mms:pt-[20px] mms:pb-[40px] mms:h-auto mm:px-[20px] ml:px-[40px] tm:px-[60px] tm:h-full ll:px-[80px] overflow-y-auto bg-bg ">
      {/* title */}
      <div className=" mb-8 text-center bg-bg ">
        <h1 className={` mms:text-[24px] ts:text-[32px] text-title uppercase `}>
          Intercesores
        </h1>
        <p className="text-Red">
          El listado de peticiones de oración se borra cada domingo a las 12:00
          am.
        </p>
      </div>

      {/* print button */}
      <div className=" mb-2 text-end w-full ">
        <button
          onClick={() => generatePDF(prayPetitions)}
          className=" px-[10px] h-[44px] bg-Green rounded"
        >
          Imprimir listado <i className=" fa-solid fa-print"></i>
        </button>
      </div>

      {/* content */}
      <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] py-[40px] w-full h-auto bg-bg2 rounded-[10px] overflow-y-auto ">
        {prayPetitions.length > 0 ? (
          prayPetitions.map((prayPetition) => (
            <PrayPetitionCard
              prayPetition={prayPetition}
              key={prayPetition.id}
            />
          ))
        ) : (
          <div className=" w-full text-center text-content ">
            <p>No hay peticiones de oración</p>
          </div>
        )}
      </div>
    </div>
  );
}

const PrayPetitionCard = ({ prayPetition }) => {
  return (
    <div className=" mms:px-[10px] mms:h-auto mm:px-[20px] ts:px-[40px] mb-[20px] py-[10px] bg-bg3 rounded-[10px] ">
      <div className=" mms:flex-col mms:items-start ll:flex-row ll:items-center flex justify-between mb-[10px] pb-2 border-b border-options">
        <div className=" mms:flex-col flex ">
          <p className=" mms:text-start text-title">Nombre de persona:</p>
          <p className=" mms:ml-0 ml-2 text-content">
            {prayPetition.for_fullname}
          </p>
        </div>
        <div className=" mms:ml-0 ml-8 flex ">
          <p className=" text-title">Teléfono de persona:</p>
          <p className=" ml-2 text-content">{prayPetition.for_phone}</p>
        </div>
        <div className=" mms:ml-0 ml-8 flex">
          <p className=" text-title">Fecha:</p>
          <p className=" ml-2 text-content">
            {format(new Date(prayPetition.date), "dd 'de' MMM 'de' yyyy")}
          </p>
        </div>
      </div>
      <div className=" mms:flex-col ll:flex-row ll:items-center flex justify-between mb-[10px] pb-2 border-b border-options">
        <div className=" flex">
          <p className=" text-title">Hecha por:</p>
          <p className=" ml-2 text-content">{prayPetition.made_by_fullname}</p>
        </div>
        <div className=" mms:ml-0 ml-8 flex ">
          <p className=" text-title">Departamento:</p>
          <p className=" ml-2 text-content">{prayPetition.depto_from}</p>
        </div>
        <div className=" mms:ml-0 ml-8 flex ">
          <p className=" text-title">Teléfono de persona:</p>
          <p className=" ml-2 text-content">{prayPetition.made_by_phone}</p>
        </div>
      </div>
      <div>
        <p className=" text-title">Petición:</p>
        <p className=" max-h-[100px] text-content overflow-y-auto">
          {prayPetition.petition}
        </p>
      </div>
    </div>
  );
};
