import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NotificationsProvider, setUpNotifications } from "reapop";
import DepartmentState from "./context/Departments/State";
// react-confirm-alert
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./assets/custom-confirm-alert.css"; // import the custom CSS file
// react datepicker
import "react-datepicker/dist/react-datepicker.css";

import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import DepartmentsHome from "./pages/DepartmentsHome";
import NewBelieverCreate from "./pages/NewBelieverCreate";
import NewBelieverSelected from "./pages/NewBelieverSelected";
import NewBelieverList from "./pages/NewBelieverList";
import PersonalAccount from "./pages/PersonalAccount";
// admin
import Admin from "./pages/Admin";
import AdminUsersList from "./pages/AdminUsersList";
import AdminUsersCreate from "./pages/AdminUsersCreate";
import AdminDepts from "./pages/AdminDepts";
import AdminDeptsManage from "./pages/AdminDeptsManage";
import AdminActivityCreate from "./pages/AdminActivityCreate";
import AdminActivities from "./pages/AdminActivities";
// departments
import DepartmentSelected from "./pages/DepartmentSelected";
import ProtectedRoute from "./components/ProtectedRoute";
// not found
import NotFound from "./pages/NotFound";

// reapop configuration
setUpNotifications({
  defaultProps: {
    position: "top-right",
    dismissible: true,
    dismissAfter: 3000,
  },
});
// ---

function App() {
  return (
    <DepartmentState>
      <NotificationsProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* redirecciona a /dashboard/departamentos */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            {/* admin */}
            <Route
              path="/dashboard/admin"
              element={
                <ProtectedRoute>
                  <Admin />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/admin/usuarios"
              element={
                <ProtectedRoute>
                  <AdminUsersList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/admin/usuarios/crear"
              element={
                <ProtectedRoute>
                  <AdminUsersCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/admin/departamentos"
              element={
                <ProtectedRoute>
                  <AdminDepts />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/admin/departamentos/:deptoId"
              element={
                <ProtectedRoute>
                  <AdminDeptsManage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/admin/actividades"
              element={
                <ProtectedRoute>
                  <AdminActivities />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/admin/actividades/crear"
              element={
                <ProtectedRoute>
                  <AdminActivityCreate />
                </ProtectedRoute>
              }
            />
            {/* public */}
            <Route
              path="/dashboard/departamentos"
              element={
                <ProtectedRoute>
                  <DepartmentsHome />
                </ProtectedRoute>
              }
            />
            {/* personal account */}
            <Route
              path="/dashboard/cuenta"
              element={
                <ProtectedRoute>
                  <PersonalAccount />
                </ProtectedRoute>
              }
            />
            {/* new converts */}
            <Route
              path="/dashboard/nuevos-creyentes"
              element={
                <ProtectedRoute>
                  <NewBelieverList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/nuevos-creyentes/:newConvertCode"
              element={
                <ProtectedRoute>
                  <NewBelieverSelected />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/nuevo-creyente/crear"
              element={
                <ProtectedRoute>
                  <NewBelieverCreate />
                </ProtectedRoute>
              }
            />
            {/* departments */}
            <Route
              path="/dashboard/departamentos/:deptoId"
              element={
                <ProtectedRoute>
                  <DepartmentSelected />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </NotificationsProvider>
    </DepartmentState>
  );
}

export default App;
