/*
 * Esta pagina es para mostra un menu de opciones para el departamento de educacion
 */
import { useContext, useEffect } from "react";

import DeptoContext from "../../../context/Departments/Context";
import ProtectedDepartment from "../ProtectedDepartment";

// members
import EvangelismMenuOptions from "./members/EvangelismMenuOptions";
import EvangelismMenuNewConverts from "./members/EvangelismMenuNewConverts";
import EvangelismMenuActivities from "./members/EvangelismMenuActivities";
// admin
import EvangelismHeaderAdmin from "./director/EvangelismHeaderAdmin";
import EvangelismIntegrants from "./director/EvangelismIntegrants";
import EvangelismActivities from "./director/EvangelismActivities";
import EvangelismActivitiesAdd from "./director/EvangelismActivitiesAdd";

export default function EvangelismContainer() {
  const { setEvangelismMenu, user } = useContext(DeptoContext);

  const changeMemberMenu = () => {
    switch (JSON.parse(localStorage.getItem("evangelism_menu"))?.menu) {
      case "OPTIONS":
        return <EvangelismMenuOptions />;
      case "NEW-CONVERTS":
        return <EvangelismMenuNewConverts />;
      case "ACTIVITIES":
        return <EvangelismMenuActivities />;
      default:
        return <EvangelismMenuOptions />;
    }
  };

  const changeAdminMenu = () => {
    switch (JSON.parse(localStorage.getItem("evangelism_menu"))?.menu) {
      case "INTEGRANTS":
        return <EvangelismIntegrants />;
      case "ACTIVITIES-ADMIN":
        return <EvangelismActivities />;
      case "CREATE-ACTIVITY":
        return <EvangelismActivitiesAdd />;
      default:
        return <EvangelismIntegrants />;
    }
  };

  useEffect(() => {
    const currentMenu = JSON.parse(
      localStorage.getItem("evangelism_menu")
    )?.menu;

    // esto es para que cuando se cambie el menu en los miembros, se reinicie en el admin a "INTEGRANTS"
    if (user?.is_director) {
      if (
        (!localStorage.getItem("evangelism_menu") &&
          currentMenu === "OPTIONS") || // member option
        currentMenu === "NEW-CONVERTS" || // member option
        currentMenu === "ACTIVITIES" // member option
      ) {
        localStorage.setItem(
          "evangelism_menu",
          JSON.stringify({ menu: "INTEGRANTS" }) // restart admin menu to "INTEGRANTS"
        );
      }
      // si no hay nada en el localstorage
      if (!localStorage.getItem("evangelism_menu")) {
        localStorage.setItem(
          "evangelism_menu",
          JSON.stringify({ menu: "INTEGRANTS" }) // restart admin menu to "INTEGRANTS"
        );
      }
    }
    setEvangelismMenu();
  }, [user]);

  return (
    <ProtectedDepartment>
      <div className=" w-full h-full">
        {user?.is_director && <EvangelismHeaderAdmin />}
        <div
          className={` ${user?.is_director ? "h-[calc(100%-75px)]" : "h-full"}`}
        >
          {Object.keys(user).length > 0 ? (
            user?.is_director ? (
              changeAdminMenu()
            ) : (
              changeMemberMenu()
            )
          ) : (
            <div>
              <h1>Cargando...</h1>
            </div>
          )}
        </div>
      </div>
    </ProtectedDepartment>
  );
}
