import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useNotifications } from "reapop";

import DeptoContext from "../../../../context/Departments/Context";
import { fonts } from "../../../../assets/styles";

export default function MenuProgressPhases() {
  const { setShowModal, setMenuModal, studentsByUnit, getStudentsByUnit } =
    useContext(DeptoContext);
  // process
  const [process, setProcess] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState(() => {
    const process = JSON.parse(localStorage.getItem("process"));
    return process !== null ? process : null;
  });
  // units
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(() => {
    const unit = JSON.parse(localStorage.getItem("unit"));
    return unit !== null ? unit : null;
  });
  // classes
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  // show select options
  const [showProcessOptions, setShowProcessOptions] = useState(false);
  const [showUnitOptions, setShowUnitOptions] = useState(false);
  const [showClassOptions, setShowClassOptions] = useState(false);

  /*
   * Necesitamos traer a todos los estudiantes que pertenezcan a una Unidad de un Proceso especificos.
   * Luego se debe seleccionar la clase a la que se le quiere marcar asistencia por cada estudiante.
   */

  const { notify } = useNotifications();

  /*
   * Clase seleccionada (cambiada)
   */
  useEffect(() => {
    if (selectedClass !== null) {
      setShowUnitOptions(false);
      setShowProcessOptions(false);
    }
  }, [selectedClass]);

  /*
   * Cargar todos los estudiantes y clases por unidad
   */
  const getClassesByUnit = async () => {
    const classesResp = await axios.get(
      `/api/v1/classes/unit/${selectedUnit.id}`
    );
    classesResp.data.sort((a, b) => a.name.localeCompare(b.name)); // ordenar las semanas por orden alfabetico
    setClasses(classesResp.data);
  };
  useEffect(() => {
    setSelectedClass(null);

    if (selectedUnit !== null) {
      setShowClassOptions(false); // hide all select options
      setShowProcessOptions(false); // hide all select options
      getClassesByUnit(); // get all classes by unit
      getStudentsByUnit(selectedUnit.id); // get all students by unit
      localStorage.setItem("unit", JSON.stringify(selectedUnit));
    }
  }, [selectedUnit]);

  /*
   * Cargar todas las unidades por proceso
   */
  const getUnitsByProcess = async () => {
    const { data } = await axios.get(
      `/api/v1/units/process/${selectedProcess.id}`
    );
    setUnits(data);
  };
  useEffect(() => {
    if (selectedProcess !== null) {
      getUnitsByProcess();
      setShowUnitOptions(false); // hide all select options
      setShowClassOptions(false); // hide all select options
      if (
        selectedProcess.id !== JSON.parse(localStorage.getItem("process"))?.id
      ) {
        setSelectedUnit(null);
        getStudentsByUnit(0); // this only to make studentsByUnit null and clear students list when process is changed
        localStorage.removeItem("unit");
        localStorage.setItem("process", JSON.stringify(selectedProcess));
        setSelectedClass(null);
      }
    }
  }, [selectedProcess]);

  /*
   * Cargar todos los procesos
   */
  const getProcesses = async () => {
    const { data } = await axios.get("/api/v1/processes/");
    setProcess(data);
  };
  useEffect(() => {
    getProcesses();
  }, []);

  return (
    <div className=" mms:px-[10px] mms:h-auto mm:px-[20px] ml:px-[40px] tm:px-[60px] tl:h-full ll:px-[80px] px-[80px] pt-[20px] pb-[40px] overflow-y-auto bg-bg ">
      {/* title */}
      <div className=" mb-4 text-center w-full ">
        <h1 className={` ${fonts.heading4} uppercase text-title `}>
          Estudiantes
        </h1>
      </div>

      {/* header options */}
      <div
        className=" mms:flex-col mms:px-[10px] mms:py-2 mms:h-auto mm:px-[20px] ml:px-[40px] ls:flex-row
      flex justify-between items-center mb-[24px] w-full h-[110px] bg-bg2 rounded-[10px]"
      >
        <div className=" mms:mb-2 mms:w-full ls:w-[180px] flex flex-col justify-center h-full ">
          <SelectOptions
            title={selectedProcess}
            noTitle="Seleccionar Proceso"
            items={process}
            setSelection={setSelectedProcess}
            showOptions={showProcessOptions}
            setShowOptions={setShowProcessOptions}
          />
        </div>
        <div className=" mms:mb-2 mms:w-full ls:w-[220px] flex flex-col justify-center h-full">
          <SelectOptions
            title={selectedUnit}
            noTitle="Seleccionar unidad"
            items={units}
            setSelection={setSelectedUnit}
            showOptions={showUnitOptions}
            setShowOptions={setShowUnitOptions}
          />
        </div>
        <div className=" mms:hidden h-full border-l-2 border-borders"></div>
        {/* shows only on tablet and desktop design */}
        <div className=" mms:mb-2 mms:w-full  ls:w-[130px] flex flex-col justify-center h-full">
          {/* <small className=" text-content ">Semana de asistencias:</small> */}
          <SelectOptions
            title={selectedClass}
            noTitle="Asistencias"
            items={classes}
            setSelection={setSelectedClass}
            showOptions={showClassOptions}
            setShowOptions={setShowClassOptions}
          />
        </div>

        <div className=" mms:w-full ls:w-[110px] flex flex-col justify-center items-end h-full border-l-2 border-borders">
          <button
            onClick={() => {
              if (selectedUnit !== null) {
                setShowModal(true);
                setMenuModal("ADD-STUDENT-TO-UNIT");
              } else {
                notify({
                  title: "Aviso",
                  message: "Seleccione una unidad por favor.",
                  status: "warning",
                });
              }
            }}
            className=" mms:mb-2 mms:w-full w-[120px] h-[56px] bg-primary rounded"
          >
            Agregar
            <i className=" text-[18px] ml-2 fa-solid fa-arrow-up-right-from-square"></i>
          </button>
        </div>
      </div>

      {/* breadcrumbs */}
      <div
        className={` mms:flex-col txl:flex-row txl:items-center flex justify-between mb-1 text-title w-full ${fonts.body1} `}
      >
        <div className=" mms:flex-col mms:w-full txl:flex-row txl:w-1/2 flex ">
          <p>
            {selectedProcess?.name}
            {selectedProcess && " > "}
          </p>
          <p className=" txl:ml-1 ">{selectedUnit?.name}</p>
        </div>
        {selectedClass && (
          <div className=" mms:justify-start mms:mt-2 mms:w-full txl:justify-end txl:mt-0 txl:w-1/2 flex justify-end ">
            <p className=" text-content">Asistencias de la clase: </p>
            <p className=" text-Green ml-1 font-bold ">{selectedClass?.name}</p>
          </div>
        )}
      </div>

      {/* students container */}
      <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] py-[40px] w-full min-h-[400px] h-auto bg-bg2 rounded-[10px]">
        {selectedUnit === null && (
          <p className=" mms:text-sm ts:text-base text-content text-center ">
            Aquí se mostraran los estudiantes del Proceso y Unidad seleccionados
          </p>
        )}
        {selectedUnit !== null &&
        studentsByUnit !== null &&
        studentsByUnit.length === 0 ? (
          <p className=" mms:text-sm ts:text-base text-center text-content ">
            No hay estudiantes en esta unidad
          </p>
        ) : (
          studentsByUnit?.map((student) => (
            <StudentCard
              student={student}
              selectedClass={selectedClass}
              key={student.id}
            />
          ))
        )}
      </div>
    </div>
  );
}

const SelectOptions = ({
  title,
  noTitle,
  items = [],
  setSelection,
  showOptions,
  setShowOptions,
}) => {
  return (
    <div className=" w-full">
      <div className="">
        <div className=" relative w-full bg-bg3 rounded">
          <button
            type="button"
            onClick={() => setShowOptions(!showOptions)}
            className="flex justify-between items-center px-2 w-full h-[50px] text-content"
          >
            {title ? (
              <>
                <span className=" text-white">{title.name}</span>
                <i className="text-white fa-solid fa-angle-down"></i>
              </>
            ) : (
              <>
                <span className=" text-content">{noTitle}</span>
                <i className="text-content fa-solid fa-angle-down"></i>
              </>
            )}
          </button>
          <div
            className={`absolute top-[calc(50px+10px)] z-10 w-full bg-bg4 border-x border-b border-bg shadow-bg
                  ${showOptions ? "block" : "hidden"}
                `}
          >
            {items.length > 0 ? (
              items.map((item, i) => (
                <button
                  type="button"
                  onClick={() => {
                    setSelection(item);
                    setShowOptions(false);
                  }}
                  className="flex items-center px-2 w-full h-[50px] text-content hover:text-title hover:bg-bg3 duration-200 "
                  key={i}
                >
                  {item.name}
                </button>
              ))
            ) : (
              <div className=" flex items-center justify-center h-[32px] text-content ">
                <small>No hay opciones</small>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const StudentCard = ({ student, selectedClass }) => {
  const { setShowModal, setMenuModal } = useContext(DeptoContext);

  const { notify } = useNotifications();

  const [attendanceSettled, setAttendanceSettled] = useState(false);
  // get students attendance status - verify is this attendance class has been settled
  (async () => {
    if (selectedClass) {
      // const getStudentAttendanceStatus = async () => {
      const { data } = await axios.get(
        `/api/v1/attendance/${student.id}/${selectedClass.id}/`
      );
      setAttendanceSettled(data.length > 0 ? true : false);
    }
  })();

  // send request to set attendance
  const setAttendance = async (student, attended) => {
    const attendanceData = {
      id_student: student.id,
      id_classes: selectedClass.id,
      attendance: attended,
    };
    const { data } = await axios.post("/api/v1/attendance/", attendanceData);
    if (data.status_code === 200) {
      setAttendanceSettled(true);
      notify({
        title: "Información",
        message: data.message,
        status: "info",
      });
    } else {
      notify({
        title: "Información",
        message: data.message,
        status: "error",
      });
    }
  };

  return (
    <div
      className={` mms:flex-col mms:items-start mms:px-[10px] mms:py-2 mms:h-auto ls:flex-row ls:items-center flex justify-between items-center mb-[20px] px-[20px] w-full h-[64px] bg-bg3 text-title ${fonts.heading3} rounded-[10px]`}
      key={student.id}
    >
      <p>
        {student.name} {student.lastname}
      </p>
      <div
        className={` mms:mt-2 mms:w-full mms:h-auto ls:w-auto flex justify-between overflow-x-auto ${fonts.body1} `}
      >
        {selectedClass !== null &&
          (!attendanceSettled ? (
            <div className=" mms:w-[300px] ls:w-auto flex ">
              <button
                onClick={() => setAttendance(student, true)}
                className=" inline w-[90px] h-[34px] text-white bg-Green rounded "
              >
                Asistió
                <i className=" text-base ml-1 fa-solid fa-check-circle"></i>
              </button>
              <button
                onClick={() => setAttendance(student, false)}
                className=" inline ml-[10px] px-[10px] w-[110px] h-[34px] text-white bg-Red rounded "
              >
                No asistió
                <i className=" text-base ml-1 fa-solid fa-times-circle"></i>
              </button>
            </div>
          ) : (
            <p className=" flex items-center min-w-[160px] text-content">
              Asistencia establecida
            </p>
          ))}
        <button
          onClick={() => {
            setShowModal(true);
            setMenuModal("TRANSLATE-STUDENT");
            localStorage.setItem("student", JSON.stringify(student));
          }}
          className={` inline ${
            selectedClass !== null && "ml-[10px]"
          } px-[10px] min-w-[110px] h-[34px] text-white bg-options rounded `}
        >
          Trasladar
          <i className=" text-base ml-1 fa-solid fa-external-link-square-alt"></i>
        </button>
      </div>
    </div>
  );
};
