import Layout from "../components/Layout";
import UsersForm from "../components/admin/UsersForm";
import HeaderMenu from "../components/admin/HeaderMenu";

export default function Admin() {
  return (
    <Layout>
      <div
        className=" mms:pb-[40px]
        w-auto h-full bg-bg overflow-y-auto "
      >
        <HeaderMenu />
        <div className=" mms:px-[10px] mms:pt-[20px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px] w-full h-auto ">
          {/* title */}
          <div className=" mb-4 text-center w-full ">
            <h1 className="text-title mms:text-[24px] ts:text-[32px] uppercase ">Crear Usuarios</h1>
          </div>

          {/* content */}
          <div className=" mms:px-[10px] mms:py-[20px] mm:px-[20px] ml:px-[40px] w-full h-auto bg-bg2 rounded-[10px] ">
            <UsersForm />
          </div>
        </div>
      </div>
    </Layout>
  );
}
