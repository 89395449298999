import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNotifications } from "reapop";
import copy from "copy-to-clipboard";

import Layout from "../components/Layout";
import { fonts } from "../assets/styles";

export default function NewBelieverSelected() {
  const [newConvert, setNewConvert] = useState({});
  const [evangelist, setEvangelist] = useState({});
  const [tutor, setTutor] = useState({});
  const [affirmer, setAffirmer] = useState({});
  const [exists, setExists] = useState(false);
  // new convert "student" info
  const [student, setStudent] = useState({}); // student info
  const [process, setProcess] = useState({}); // process name

  const { newConvertCode } = useParams();

  const getNewConvertById = async () => {
    const {
      data: { new_convert, evangelist, tutor, affirmer, exists },
    } = await axios.get(`/api/v1/new_convert/code/${newConvertCode}`);
    setNewConvert(new_convert);
    setEvangelist(evangelist);
    setTutor(tutor);
    setAffirmer(affirmer);
    setExists(exists);
    // setNewConvert(data);
    // new convert is student
    if (new_convert.is_student) {
      const { data: student } = await axios.get(
        `/api/v1/students/${new_convert.code}/`
      );
      setStudent(student);
      setProcess(verifyUnitStudent(student.id_unit));
    }
  };

  const verifyUnitStudent = (unitId) => {
    switch (unitId) {
      case 1:
        return { process: "Nuevo Creyente", unit: "Creciento hacia Adentro" };
      case 2:
        return { process: "Nuevo Creyente", unit: "Creciento hacia Arriba" };
      case 3:
        return { process: "Nuevo Creyente", unit: "Creciento hacia Afuera" };
      case 4:
        return { process: "Discipulo", unit: "Creciento hacia Adentro" };
      case 5:
        return { process: "Discipulo", unit: "Creciento hacia Arriba" };
      case 6:
        return { process: "Discipulo", unit: "Creciento hacia Afuera" };
      case 7:
        return { process: "Líder", unit: "Creciento hacia Adentro" };
      case 8:
        return { process: "Líder", unit: "Creciento hacia Arriba" };
      case 9:
        return { process: "Líder", unit: "Creciento hacia Afuera" };
    }
  };

  useEffect(() => {
    getNewConvertById();
  }, []);

  return (
    <Layout>
      <div
        className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px]
      relative px-[80px] pb-[40px] h-full overflow-y-auto bg-bg "
      >
        <div className=" flex flex-col justify-between items-center h-auto ">
          {/* title */}
          <div className=" sticky top-0 flex items-center w-full text-center mb-[16px] h-[56px] bg-bg ">
            <h1
              className={` mms:text-[24px] ts:text-[32px] w-full text-center text-title uppercase `}
            >
              {/* {newConvert.name} {newConvert.lastname} */}
              Nuevo Creyente
            </h1>
          </div>

          {/* brearcrumbs */}
          <div className=" mms:flex-col ts:flex-row flex sticky top-[48px] mb-1 w-full bg-bg ">
            <Link to="/dashboard/nuevos-creyentes/">
              Todos los creyentes {" > "}{" "}
            </Link>
            <p className=" ts:ml-1 max-w-[270px] text-content overflow-hidden whitespace-nowrap text-ellipsis ">
              {newConvert.name} {newConvert.lastname}
            </p>
          </div>

          {/* info NB min-h-[500px] h-[1100px] */}
          <div className=" mms:h-auto min-h-[450px] w-full  bg-bg2 rounded-[10px] ">
            {exists ? (
              <div className=" bg-bg2 rounded-[10px] ">
                <div className=" mms:flex-col ll:flex-row flex justify-between min-h-[500px] h-full w-full bg-bg2 rounded-t-[10px] ">
                  {/* new believer info */}
                  <div className=" mms:w-full ll:w-[50%] h-full ll:border-r border-options ">
                    <header className=" mms:px-[10px] mm:px-[20px] ml:pl-[40px] mms:text-center flex items-center h-[75px] border-b border-options">
                      <h1 className={` ${fonts.heading3} text-third uppercase`}>
                        Informacion del nuevo creyente
                      </h1>
                    </header>
                    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] flex flex-col justify-between py-[20px] h-[calc(100%-75px)]">
                      <NewConvertItem
                        icon="user"
                        title="Nuevo Creyente"
                        content={`${newConvert.name} ${newConvert.lastname}`}
                      />
                      <NewConvertItem
                        icon="cube"
                        title="Código"
                        content={newConvert.code}
                      />
                      <NewConvertItem
                        icon="mobile"
                        title="Teléfono"
                        content={newConvert.phone}
                      />
                      <NewConvertItem
                        icon="location-pin"
                        title="Dirección"
                        content={newConvert.address}
                      />
                      <NewConvertItem
                        icon="church"
                        title="Conexión"
                        content={newConvert.conexion}
                      />
                      <NewConvertItem
                        icon="arrow-rotate-right"
                        title="Edad"
                        content={`${newConvert.age} años`}
                      />
                    </div>
                  </div>
                  {/* new believer tracing */}
                  <div className=" mms:w-full mms:border-t border-options ll:w-[50%] ll:border-none h-full ">
                    <header className=" mms:px-[10px] mm:px-[20px] ml:pl-[40px] mms:text-center flex items-center pl-[40px] h-[75px] border-b border-options">
                      <h1 className={` ${fonts.heading3} text-third uppercase`}>
                        Informacion de seguimiento
                      </h1>
                    </header>
                    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] flex flex-col justify-between pl-[40px] py-[20px] h-[calc(100%-75px)]">
                      <NewConvertItem
                        icon="user-plus"
                        title="Tutor"
                        content={`${tutor.name} ${tutor.lastname}`}
                      />
                      <NewConvertItem
                        icon="user-check"
                        title="Evangelista"
                        content={`${evangelist.name} ${evangelist.lastname}`}
                      />
                      <NewConvertItem
                        icon="user-clock"
                        title="Afirmador"
                        content={
                          Object.keys(affirmer).length > 0
                            ? `${affirmer?.name} ${affirmer?.lastname}`
                            : "Aún no asignado"
                        }
                      />
                    </div>
                  </div>
                </div>

                {/* only if it's in the school */}
                {newConvert.is_student && Object.keys(student).length > 0 && (
                  <div className=" mms:flex-col mms:mt-8 ll:mt-0 ll:flex-row flex justify-between h-auto w-full border-t border-options bg-bg2 rounded-b-[25px]">
                    {/* new believer info */}
                    <div className=" mms:w-full ll:w-[50%] h-full ">
                      <header className=" mms:px-[10px] mm:px-[20px] ml:pl-[40px] mms:text-center flex items-center h-[75px] border-b border-options">
                        <h1
                          className={` ${fonts.heading3} text-third uppercase`}
                        >
                          Informacion de proceso
                        </h1>
                      </header>

                      <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] flex flex-col justify-between pl-[40px] py-[20px] h-[calc(100%-75px)]">
                        <NewConvertItem
                          icon="person-running"
                          title="Proceso Actual"
                          content={`${process.process}`}
                        />
                        <NewConvertItem
                          icon="unity"
                          title="Unidad"
                          is_brand={true}
                          content={`${process.unit}`}
                        />
                      </div>
                    </div>
                    {/* new believer tracing */}
                    <div className=" mms:w-full mms:border-t ll:w-[50%] h-full ll:border-t-0 ll:border-l border-options ">
                      <header className=" mms:px-[10px] mm:px-[20px] ml:pl-[40px] mms:text-center flex items-center h-[75px] border-b border-options ">
                        <h1
                          className={` ${fonts.heading3} text-third uppercase`}
                        >
                          Informacion de retiros
                        </h1>
                      </header>
                      <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] flex flex-col justify-between pl-[40px] py-[20px] h-[calc(100%-75px)]">
                        <NewConvertItem
                          icon="star"
                          title="Retiro de Bienvenida"
                          content={
                            student.retreat_new_convert ? (
                              <span className=" text-Green ">Realizado</span>
                            ) : (
                              <span className=" text-content ">Pendiente</span>
                            )
                          }
                        />
                        <NewConvertItem
                          icon="star"
                          title="Retiro de Discipulo"
                          content={
                            student.retreat_disciples ? (
                              <span className=" text-Green ">Realizado</span>
                            ) : (
                              <span className=" text-content ">Pendiente</span>
                            )
                          }
                        />
                        <NewConvertItem
                          icon="star"
                          title="Retiro de Líder"
                          content={
                            student.retreat_leaders ? (
                              <span className=" text-Green ">Realizado</span>
                            ) : (
                              <span className=" text-content ">Pendiente</span>
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className=" flex justify-center items-center w-full rounded-[25px] text-content">
                No existe este Nuevo Convertido
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

const NewConvertItem = ({ icon, title, content, is_brand = false }) => {
  const { notify } = useNotifications();

  //  copy code to clipboard
  const copyCodeToClipboard = (textToCopy) => {
    if (copy(textToCopy)) {
      notify({
        title: "Copiado",
        message: "El código se ha copiado al portapapeles",
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: "El código no se ha copiado al portapapeles",
        status: "error",
      });
    }
  };

  return (
    <div className=" mms:mb-4 mms:h-auto h-[56px] flex justify-start items-center mb-3 w-full ">
      <div className=" mms:hidden flex items-center mr-[10px] w-[40px] h-full">
        <i
          className={` text-[30px] text-title fa-${
            is_brand ? "brands" : "solid"
          } fa-${icon}`}
        ></i>
      </div>
      <div className=" flex flex-col justify-center h-full">
        <p className=" text-primary">{title}</p>
        {title === "Código" ? (
          <div className=" mms:block flex">
            <p className=" text-title">{content}</p>
            <button
              onClick={() => copyCodeToClipboard(content)}
              className=" mms:ml-0 ml-2 px-[5px] h-[24px] text-sm bg-Green rounded"
            >
              Copiar
            </button>
          </div>
        ) : (
          <p className=" text-title">{content}</p>
        )}
      </div>
    </div>
  );
};
