import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

export default function ProtectedRoute({ children }) {
  const token = Cookies.get("access_token");
  if (token === undefined) {
    return <Navigate to="/" />;
  }
  return children;
}
