import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useNotifications } from "reapop";

import { fonts } from "../assets/styles";
import Layout from "../components/Layout";

import DeptoContext from "../context/Departments/Context";

export default function Departments() {
  const { user } = useContext(DeptoContext);
  const token = Cookies.get("access_token");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { notify } = useNotifications();

  if (token === undefined) {
    return <Navigate to="/" />;
  }

  const handleChangePassword = async () => {
    if (password === "") {
      notify({
        title: "Aviso",
        message: "Ingrese una contraseña por favor.",
        status: "warning",
      });
    }

    const { data } = await axios.put(
      `/api/v1/members/change-password/${user.code}`,
      { password }
    );
    if (data.status_code === 200) {
      setPassword("");
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
  };

  return (
    <Layout>
      <div
        className=" mms:pb-[0px]
        w-auto h-full bg-bg overflow-y-auto "
      >
        <div className=" mms:px-[10px] mms:pt-[20px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px] w-full h-auto ">
          {/* title */}
          <div className=" mb-2 text-center w-full">
            <h1
              className={` mms:text-[24px] ts:text-[32px] ${fonts.heading4} text-title uppercase`}
            >
              Cuenta Personal
            </h1>
          </div>

          {/* content */}
          <div className=" mms:py-[20px] w-full h-auto rounded-[10px] ">
            {/* container */}
            <div className=" mms:w-full ts:w-[500px] mx-auto p-[20px] h-full bg-bg2 rounded-[10px] ">
              {/* icons */}
              <div className=" mms:h-auto ts:h-[250px] flex justify-between ">
                <div className=" flex flex-col justify-between w-full ">
                  <IconCard
                    icon="user"
                    title="Usuario"
                    content={`${user.name} ${user.lastname}`}
                  />
                  <IconCard
                    icon="envelope"
                    title="Email"
                    content={`${user.email}`}
                  />
                  <IconCard
                    icon="mobile-screen"
                    title="Teléfono"
                    content={`${user.phone}`}
                  />
                </div>
              </div>

              {/* change password */}
              <div className=" flex flex-col justify-center items-center h-[250px] border-t border-options ">
                <div className=" p-[20px] w-[100%] bg-bg3 rounded-[10px] ">
                  <div>
                    <label className=" text-base text-subtitle block mb-2">
                      Ingrese nueva contraseña{" "}
                      <span className="text-Red">*</span>
                    </label>{" "}
                    <div className=" flex justify-between ">
                      <input
                        type={showPassword ? "text" : "password"}
                        className=" indent-2 w-[calc(100%-44px)] h-[44px] bg-bg4 text-title outline-none rounded-tl rounded-bl "
                        onChange={(e) => setPassword(e.target.value)}
                        value={password || ""}
                        maxLength={32}
                      />
                      <div
                        onClick={() => setShowPassword(!showPassword)}
                        className=" flex justify-center items-center w-[44px] bg-bg2 cursor-pointer rounded-tr rounded-br "
                      >
                       <i className={` fa-solid ${showPassword ? "fa-eye-slash" : "fa-eye"} `}></i>
                      </div>
                    </div>
                  </div>
                  <div className=" flex justify-start mt-8 w-full">
                    <button
                      onClick={handleChangePassword}
                      className="bg-primary text-white w-[100px] h-[44px] rounded "
                    >
                      Cambiar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const IconCard = ({ icon, title, content, is_brand = false }) => {
  return (
    <div className=" mms:h-auto ts:h-[56px] flex justify-start items-center mb-3 w-full ">
      <div className=" mms:hidden ts:flex items-center mr-[10px] w-[40px] h-full">
        <i
          className={` text-[30px] text-title fa-${
            is_brand ? "brands" : "solid"
          } fa-${icon}`}
        ></i>
      </div>
      <div className=" flex flex-col justify-center h-full">
        <p className=" text-primary">{title}</p>
        <p className=" mms:w-[250px] w-[90%] text-title overflow-hidden whitespace-nowrap text-ellipsis ">
          {content}
        </p>
      </div>
    </div>
  );
};
