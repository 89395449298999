import axios from "axios";
import { useEffect, useState } from "react";
import { useNotifications } from "reapop";
import { format } from "date-fns";

// import DeptoContext from "../../../../context/Departments/Context";

export default function MenuProgressPhases() {
  // const { educationMenu, setEducationMenu, user } = useContext(DeptoContext);
  const [helpRequests, setHelpRequests] = useState([]);

  const { notify } = useNotifications();

  const doneHelpRequest = async (id) => {
    const { data } = await axios.put(`/api/v1/help_request/${id}`);
    if (data.status_code === 200) {
      getHelpRequests();
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
  };

  const getHelpRequests = async () => {
    const { data } = await axios.get("/api/v1/help_request");
    setHelpRequests(data);
  };
  useEffect(() => {
    getHelpRequests();
  }, []);

  return (
    <div className=" mms:px-[10px] mms:pt-[20px] mms:pb-[40px] mms:h-auto mm:px-[20px] ml:px-[40px] tm:px-[60px] tm:h-full ll:px-[80px] overflow-y-auto bg-bg ">
      {/* title */}
      <div className=" mb-8 text-center bg-bg">
        <h1 className={` mms:text-[24px] ts:text-[32px] text-title uppercase`}>
          Solicitudes de ayuda
        </h1>
        <small className=" text-Red">
          Cada solicitud de apoyo se eliminará después de 7 días
        </small>
      </div>

      {/* content */}
      <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] py-[40px] w-full h-auto bg-bg2 rounded-[10px] overflow-y-auto ">
        {helpRequests.length > 0 ? (
          helpRequests.map((helpRequest) => (
            <HelpRequestCard
              helpRequest={helpRequest}
              doneHelpRequest={doneHelpRequest}
              key={helpRequest.id}
            />
          ))
        ) : (
          <div>
            <p className=" text-center text-content ">
              No hay solicitudes de apoyo
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

const HelpRequestCard = ({ helpRequest, doneHelpRequest }) => {
  return (
    <div
      className=" mms:px-[10px] mms:h-auto mm:px-[20px] ml:px-[40px] relative mb-[20px] py-[10px] bg-bg3 rounded-[10px] "
      key={helpRequest.id}
    >
      <span
        className={` absolute top-4 right-4 inline-block w-[10px] h-[10px] ${
          helpRequest.status ? "bg-content" : "bg-Green"
        }  rounded-full shadow-lg`}
      ></span>
      {/* from */}
      <div className=" mb-[10px] pb-[10px] border-b border-content">
        <p className=" text-third">Hecha por:</p>
        <div className=" mms:flex mms:flex-col lm:flex-row flex justify-between ">
          <div className=" w-[250px] ">
            <p className=" text-title">Nombres y apellidos:</p>
            <p className=" text-content">{helpRequest.made_by_fullname}</p>
          </div>
          <div className=" mms:my-2 mms:text-start lm:my-0 w-[250px] text-center ">
            <p className=" text-title">Departamento:</p>
            <p className=" text-content capitalize">{helpRequest.depto_from}</p>
          </div>
          <div className=" mms:text-start w-[250px] text-end ">
            <p className=" text-title">Teléfono:</p>
            <p className=" text-content">{helpRequest.made_by_phone}</p>
          </div>
        </div>
      </div>
      {/* to */}
      <div className=" mb-[10px] pb-[10px] border-b border-content">
        <p className=" text-third">Para:</p>
        <div className=" mms:flex-col lm:flex-row flex justify-between">
          <div className=" w-[250px] ">
            <p className=" text-title">Nombres y apellidos:</p>
            <p className=" text-content">{helpRequest.for_fullname}</p>
          </div>
          <div className=" mms:my-2 mms:text-start lm:my-0 w-[250px] text-center ">
            <p className=" text-title">Fecha:</p>
            <p className=" text-content">
              {format(new Date(helpRequest.date), "dd 'de' MMMM 'de' yyyy")}
            </p>
          </div>
          <div className=" mms:text-start w-[250px] text-end ">
            <p className=" text-title">Teléfono:</p>
            <p className=" text-content">{helpRequest.for_phone}</p>
          </div>
        </div>
      </div>
      <div className=" mb-[10px] pb-[10px] border-b border-content">
        <p className=" text-third">Detalles de la solicitud de apoyo:</p>
        <div className=" mms:h-auto max-h-[75px] text-content overflow-y-auto ">
          <p>{helpRequest.request}</p>
        </div>
      </div>
      <div>
        {!helpRequest.status ? (
          <button
            onClick={() => doneHelpRequest(helpRequest.id)}
            className=" px-[20px] h-[36px] bg-primary rounded"
          >
            Hecha
          </button>
        ) : (
          <div className=" flex justify-start w-full">
            <p className=" w-auto px-2 py-2 text-Green text-sm ">
              Solicitud atendida
              <i className=" ml-2 fa-solid fa-check"></i>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
