import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { fonts } from "../../assets/styles";
import { useNotifications } from "reapop";

const schema = yup.object({
  name: yup
    .string()
    .required("Ingrese su nombre por favor.")
    .max(32, "Máximo 32 carácteres."),
  lastname: yup
    .string()
    .required("Ingrese su apellido por favor.")
    .max(32, "Máximo 32 carácteres."),
  email: yup
    .string()
    .email("Ingrese un correo válido por favor")
    .required("Ingrese su correo por favor")
    .max(64, "Máximo 64 carácteres."),
  phone: yup
    .string()
    .required("Ingrese su teléfono por favor")
    .max(16, "Máximo 16 carácteres."),
});

export default function UsersForm() {
  const { notify } = useNotifications();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (formdata) => {
    try {
      formdata.password = process.env.REACT_APP_DEFAULT_PASSWORD;
      formdata.is_efesio = formdata.is_efesio === "true" ? true : false;
      formdata.is_admin = formdata.is_admin === "true" ? true : false;
      const { data } = await axios.post("/api/v1/members/", formdata);
      if (data.status_code === 201) {
        reset();
        notify({
          title: "¡Éxito!",
          message: data.message,
          status: "success",
        });
      } else {
        notify({
          title: "¡Advertencia!",
          message: data.message,
          status: "warning",
        });
      }
    } catch (error) {
      notify({
        title: "¡Error!",
        message:
          "Ha habido un error en el servidor. Intente nuevamente por favor.",
        status: "error",
      });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className=" pt-0 mms:w-full txl:w-[600px] txl:px-[20px] txl:py-[20px] txl:bg-bg3 txl:rounded mx-auto h-auto "
    >
      {/* flex justify-center items-center w-auto h-[calc(100%-75px)] border */}
      {/* h-[571px] w-[562px] bg-bg2 rounded-[25px] */}
      <div className=" mms:flex-col ts:flex-row flex justify-between mb-8">
        {/* name */}
        <div className=" mms:w-full ts:w-[45%] mt-6 ">
          <label className="text-subtitle block mb-2">
            Nombre <span className="text-Red">*</span>
          </label>{" "}
          <input
            className=" indent-2 w-full h-[44px] bg-bg4 text-content outline-none rounded"
            {...register("name")}
            maxLength={32}
          />
          {<small className="text-red-500">{errors.name?.message}</small>}
        </div>

        {/* lastname */}
        <div className=" mms:w-full ts:w-[45%] mt-6 ">
          <label className="text-subtitle block mb-2">
            Apellido <span className="text-Red">*</span>
          </label>{" "}
          <input
            className=" indent-2 w-full h-[44px] bg-bg4 text-content outline-none rounded"
            {...register("lastname")}
            maxLength={32}
          />
          {<small className="text-red-500">{errors.lastname?.message}</small>}
        </div>
      </div>

      <div className=" mms:flex-col ts:flex-row flex justify-between mb-8">
        {/* email */}
        <div className=" mms:w-full ts:w-[45%] mms:mb-6 ">
          <label className="text-subtitle mb-2 block">
            Correo Electrónico <span className="text-Red">*</span>
          </label>
          <input
            {...register("email")}
            className=" indent-2 w-full h-[44px] bg-bg4 text-content outline-none rounded"
            maxLength={64}
          />
          <small className="text-red-500">{errors.email?.message}</small>
        </div>
        {/* phone */}
        <div className=" mms:w-full ts:w-[45%] mms:mb-6 ">
          <label className="text-subtitle mb-2 block">
            Teléfono <span className="text-Red">*</span>
          </label>
          <input
            {...register("phone")}
            className=" indent-2 w-full h-[44px] bg-bg4 text-content outline-none rounded"
            maxLength={16}
          />
          <small className="text-red-500">{errors.phone?.message}</small>
        </div>
      </div>

      <div className=" mb-8">
        <p className="text-title">Contraseña Predefinida:</p>
        <p className="text-content">{process.env.REACT_APP_DEFAULT_PASSWORD}</p>
      </div>
      <div className=" mms:flex-col ts:flex-row flex justify-between">
        {/* is admin */}
        <div className="w-[45%]">
          <label className=" text-subtitle ">¿Administrador?</label>
          <div className="flex justify-start mt-6">
            <label htmlFor="" className="mr-[64px] flex items-center">
              <input
                {...register("is_admin")}
                type="radio"
                value={true}
                className="mr-2"
              />
              Si
            </label>
            <label htmlFor="">
              <input
                {...register("is_admin")}
                type="radio"
                value={false}
                className="mr-2"
                defaultChecked={true}
              />
              No
            </label>
          </div>
        </div>
        {/* is efesio */}
        <div className="w-[45%]">
          <label className=" text-subtitle ">¿Efesio?</label>
          <div className="flex justify-start mt-6">
            <label htmlFor="" className="mr-[64px] flex items-center">
              <input
                {...register("is_efesio")}
                type="radio"
                value={true}
                className="mr-2"
              />
              Si
            </label>
            <label htmlFor="">
              <input
                {...register("is_efesio")}
                type="radio"
                value={false}
                className="mr-2"
                defaultChecked={true}
              />
              No
            </label>
          </div>
        </div>
      </div>

      <div className=" flex justify-center mt-8 w-full">
        <button className="bg-primary text-white w-full h-[44px] rounded uppercase">
          Crear
        </button>
      </div>
    </form>
  );
}
