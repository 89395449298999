import axios from "axios";
import { useEffect, useState, useContext, useRef } from "react";
import { useNotifications } from "reapop";
import { confirmAlert } from "react-confirm-alert";

import DeptoContext from "../../context/Departments/Context";
import { fonts } from "../../assets/styles";

export default function AsignAffirmer() {
  const { selectedAffirmer } = useContext(DeptoContext);
  const [newConverts, setNewConverts] = useState([]);
  const [showMembers, setShowMembers] = useState(false);

  const inputCodeRef = useRef();

  const getNewConvertByName = async (name) => {
    if (name.length > 0) {
      const { data } = await axios.get(
        `/api/v1/new_convert/code/affirmer/${name}/`
      );
      data.sort((a, b) => a.name.localeCompare(b.name));
      setNewConverts(data);
    } else {
      getAllNewConvertsWithoutAffirmer();
    }
  };

  const getAllMembersWithoutAffirmer = async () => {
    const { data } = await axios.get("/api/v1/members/without-affirmer/");
    data.sort((a, b) => a.name.localeCompare(b.name));
    setNewConverts(data);
  };
  const getAllNewConvertsWithoutAffirmer = async () => {
    const { data } = await axios.get("/api/v1/new_converts/without/affirmer");
    data.sort((a, b) => a.name.localeCompare(b.name));
    setNewConverts(data);
  };
  useEffect(() => {
    if (showMembers) {
      getAllMembersWithoutAffirmer();
    } else {
      getAllNewConvertsWithoutAffirmer();
    }
  }, [showMembers]);

  return (
    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] w-full h-full ">
      <div className=" mb-[16px] ">
        <div className=" mb-2 text-center">
          <h1 className={`mb-1 ${fonts.heading3} uppercase text-title`}>
            Asignar Nuevo Creyente a Afirmador
          </h1>
        </div>

        {/* affirmer info */}
        <div className=" px-[20px] py-[10px] w-full h-auto bg-bg3 rounded-[10px]">
          <div>
            <p className=" mb-1 text-white">Afirmador información:</p>
            <div className=" ">
              <p className=" text-content">
                <i className=" mr-2 fa-solid fa-user"></i>
                {selectedAffirmer.name} {selectedAffirmer.lastname}
              </p>
              <p className=" my-1 text-content">
                <i className=" mr-2 fa-solid fa-envelope"></i>
                {selectedAffirmer.email}
              </p>
              <p className=" my-1 text-content">
                <i className=" mr-2 fa-solid fa-mobile "></i>
                {selectedAffirmer.phone}
              </p>
            </div>
          </div>
        </div>

        {/* new converts list */}
        <div className=" mt-[20px] px-[20px] pt-[10px] w-full min-h-[300px] bg-bg3 rounded-[10px]">
          <div className=" mms:mb-2 mms:px-[10px] mms:bg-bg2 ml:px-0 ml:bg-bg3 flex items-center h-[32px] overflow-x-auto rounded-[25px] ">
            <div className="  flex items-center px-[0px] w-[300px] h-[28px] bg-[#D9D9D9] text-content rounded-[25px] ">
              <button
                onClick={() => setShowMembers(true)}
                className={` w-[150px] h-full ${
                  showMembers
                    ? " bg-primary text-white "
                    : " bg-[#d9d9d9] text-content"
                } ${fonts.body1} rounded-[25px] outline-none`}
              >
                Usuarios
              </button>
              <button
                onClick={() => setShowMembers(false)}
                className={` w-[150px] h-full ${
                  !showMembers
                    ? " bg-primary text-white "
                    : " bg-[#d9d9d9] text-content"
                } ${fonts.body1} rounded-[25px] outline-none`}
              >
                Nuevos Creyentes
              </button>
            </div>
          </div>

          {/* filter */}
          {!showMembers && (
            <div className=" mb-[10px] h-[44px] ">
              <div className=" flex justify-between w-full h-[44px]">
                <input
                  type="text"
                  onChange={(e) => getNewConvertByName(e.target.value)}
                  ref={inputCodeRef}
                  placeholder="Buscar por nombre de Nuevo Creyente"
                  className=" indent-3 w-[calc(100%-44px)] h-full bg-bg outline-none "
                />
                <div
                  onClick={() => {
                    inputCodeRef.current.value = "";
                    getAllNewConvertsWithoutAffirmer();
                  }}
                  className=" flex justify-center items-center w-[44px] h-full bg-bg2 cursor-pointer"
                >
                  <i className=" text-[24px] fa-solid fa-times"></i>
                </div>
              </div>
            </div>
          )}

          {/* new converts */}
          <div className=" text-content">
            <small className={` ${fonts.body1}`}>
              Listado de personas sin afirmador
            </small>
          </div>

          {/* list people */}
          <div className=" p-[10px] h-[calc(300px-44px)] bg-bg4 rounded overflow-y-auto">
            {newConverts.length > 0 ? (
              newConverts.map((newConvert) => (
                <MemberNewConvertCard
                  newConvert={newConvert}
                  selectedAffirmer={selectedAffirmer}
                  showMembers={showMembers}
                  getAllMembersWithoutAffirmer={getAllMembersWithoutAffirmer}
                  getAllNewConvertsWithoutAffirmer={
                    getAllNewConvertsWithoutAffirmer
                  }
                  key={newConvert.id}
                />
              ))
            ) : (
              <div>
                <p className=" text-center text-content ">
                  No hay Nuevos Creyentes
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const MemberNewConvertCard = ({
  newConvert,
  selectedAffirmer,
  showMembers,
  getAllMembersWithoutAffirmer,
  getAllNewConvertsWithoutAffirmer,
}) => {
  const { notify } = useNotifications();

  // Confirm: Asign affirmer to a new convert
  const confirmationAsignAffirmer = (id_new_convert) => {
    confirmAlert({
      title: "Asignar Afirmador",
      message: `¿Está seguro que desea asignar este Nuevo Creyente a ${selectedAffirmer.name} ${selectedAffirmer.lastname}?.`,
      buttons: [
        {
          label: "Sí",
          onClick: () => createAffirmerAssignation(id_new_convert),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const createAffirmerAssignation = async (id_new_convert) => {
    // create affirmer and get it's id
    const affirmerData = {
      name: selectedAffirmer.name,
      lastname: selectedAffirmer.lastname,
      email: selectedAffirmer.email,
      code: selectedAffirmer.code,
    };
    // create the affirmer first
    const affirmerResp = await axios.post("/api/v1/affirmer", affirmerData);
    if (affirmerResp.data.status_code === 201) {
      const newConvertData = {
        id_new_convert,
        id_affirmer: affirmerResp.data.affirmer_id,
      };
      const { data } = await axios.put(
        "/api/v1/new_convert/affirmer/",
        newConvertData
      );
      if (data.status_code === 201) {
        getAllNewConvertsWithoutAffirmer();
        notify({
          title: "¡Éxito!",
          message: data.message,
          status: "success",
        });
      } else {
        notify({
          title: "Error",
          message: data.message,
          status: "error",
        });
      }
    } else {
      notify({
        title: "Error",
        message: affirmerResp.data.message,
        status: "error",
      });
    }
  };

  // Confirm: Asign affirmer to a member
  const confirmationMemberAsignAffirmer = (id_member) => {
    confirmAlert({
      title: "Asignar Afirmador",
      message: `¿Está seguro que desea asignar este usuario a ${selectedAffirmer.name} ${selectedAffirmer.lastname}?.`,
      buttons: [
        {
          label: "Sí",
          onClick: () => createMemberAffirmerAssignation(id_member),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const createMemberAffirmerAssignation = async (id_member) => {
    // create affirmer and get it's id
    const affirmerData = {
      name: selectedAffirmer.name,
      lastname: selectedAffirmer.lastname,
      email: selectedAffirmer.email,
    };
    // 1. verify if affirmer exists and get it's id
    const affirmerResp = await axios.post("/api/v1/affirmer", affirmerData);
    if (affirmerResp.data.status_code === 201) {
      const memberData = {
        id_member,
        id_affirmer: affirmerResp.data.affirmer_id,
      };
      const { data } = await axios.put("/api/v1/member/affirmer/", memberData);
      if (data.status_code === 201) {
        getAllMembersWithoutAffirmer();
        notify({
          title: "¡Éxito!",
          message: data.message,
          status: "success",
        });
      } else {
        notify({
          title: "Error",
          message: data.message,
          status: "error",
        });
      }
    } else {
      notify({
        title: "Error",
        message: affirmerResp.data.message,
        status: "error",
      });
    }
  };

  return (
    <div
      className=" mms:flex-col mms:items-start mms:h-auto ts:flex-row ts:items-center flex justify-between items-center
      mb-[10px] pb-[6px] h-[50px] border-b border-content "
    >
      <div className=" mms:mb-2 ">
        <p className=" text-title">
          {newConvert.name} {newConvert.lastname}
        </p>
        <small className=" text-content">Código: {newConvert.code}</small>
        {newConvert.had_affirmer && (
          <p className=" text-sm text-Green ">
            {" "}
            <i className=" fa-solid fa-check-circle "></i> Ya tuvo afirmador
          </p>
        )}
      </div>
      <button
        onClick={() =>
          showMembers
            ? confirmationMemberAsignAffirmer(newConvert.id)
            : confirmationAsignAffirmer(newConvert.id)
        }
        className={` px-[10px] h-[28px] bg-primary ${fonts.body1} rounded`}
      >
        Asignar
      </button>
    </div>
  );
};
