import axios from "axios";
import { useEffect, useState } from "react";
import { useNotifications } from "reapop";
import DatePicker from "react-datepicker";
import es from "date-fns/locale/es";

export default function NetworksDiscipleshipsAttendance() {
  const [currentDate, setCurrentDate] = useState(new Date());
  // networks
  const [networks, setNetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [showRedOptions, setShowRedOptions] = useState(false);
  // discipleships
  const [discipleships, setDiscipleships] = useState([]);
  const [selectedDiscipleship, setSelectedDiscipleship] = useState(null);
  const [showDiscipleshipOptions, setShowDiscipleshipOptions] = useState(false);
  // addtenance
  const [cdpsAttendances, setCdpsAttendances] = useState(null);
  const [attendanceMessage, setAttendanceMessage] = useState(
    "Seleccione un discipulado y un mes."
  );

  const { notify } = useNotifications();

  const handleChangeDate = async (date) => {
    if (selectedDiscipleship === null) {
      setCurrentDate(new Date());
      return notify({
        title: "Aviso",
        message: "Seleccione un discipulado",
        status: "warning",
      });
    }
    setCurrentDate(date);
    const fecha = new Date(date);
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;

    // send the year and montt, and the id_discipleship to get the attendance
    const { data } = await axios.get(
      `/api/v1/discipleship_meets/${year}/${month}/${selectedDiscipleship.id}`
    );
    if (data.status_code === 200) {
      setCdpsAttendances(data.attendances);
    } else {
      setAttendanceMessage(data.message);
      setCdpsAttendances(null);
    }
  };

  const getAllDiscipleshipsByNetworkId = async (networkId) => {
    const { data } = await axios.get(
      `/api/v1/discipleships/network/${networkId}`
    );
    data.sort((a, b) =>
      a.leader_one_fullname.localeCompare(b.leader_one_fullname)
    );
    setDiscipleships(data);
    setShowDiscipleshipOptions(false); // hide discipleship options
    setSelectedDiscipleship(null); // reset selected discipleship
  };

  const getAllNetworks = async () => {
    const { data } = await axios.get("/api/v1/networks");
    data.sort((a, b) => a.name.localeCompare(b.name));
    setNetworks(data);
  };
  useEffect(() => {
    getAllNetworks();
  }, []);

  return (
    <div className=" mms:px-[10px] mms:py-[20px] mm:px-[20px] ml:px-[40px] tl:h-full ll:px-[80px] w-full overflow-y-auto ">
      {/* title */}
      <div className=" mb-8 h-[50px] text-center bg-bg ">
        <h1 className={` mms:text-[24px] tm:text-[32px] text-title uppercase `}>
          Asistencias en Discipulados
        </h1>
      </div>

      {/* filters */}
      <div className=" mms:flex-col mms:px-[10px] mms:py-[10px] mms:h-auto mm:px-[20px] lxl:flex-row flex justify-between items-center mb-2 w-full h-[65px] bg-bg2 rounded-[10px] ">
        <div className=" mms:flex-col mms:w-full txl:flex-row flex items-center w-[45%] ">
          <div className=" mms:w-full mms:mb-2 txl:mr-2 ">
            <SelectOptions
              title={selectedNetwork}
              noTitle="Seleccione una red"
              items={networks}
              setSelection={setSelectedNetwork}
              getAllDiscipleships={getAllDiscipleshipsByNetworkId}
              showOptions={showRedOptions}
              setShowOptions={setShowRedOptions}
            />
          </div>
          <div className=" mms:mb-2 mms:w-full lxl:mr-2 ">
            <SelectOptionsDiscipleship
              title={selectedDiscipleship}
              noTitle="Seleccione un discipulado"
              items={discipleships}
              setSelection={setSelectedDiscipleship}
              showOptions={showDiscipleshipOptions}
              setShowOptions={setShowDiscipleshipOptions}
              setCurrentDate={setCurrentDate} // reset the calendar
            />
          </div>
        </div>
        <div className=" mms:flex-col mms:w-full txl:flex-row flex items-center w-[45%] ">
          <div className=" mms:mb-2 mms:w-full w-[200px] ">
            <DatePicker
              selected={currentDate}
              onChange={(date) => handleChangeDate(date)}
              dateFormat="MM/yyyy"
              locale={es}
              showMonthYearPicker
              className=" px-[10px] w-full h-[50px] bg-bg3 rounded outline-none "
              inputMode="none"
            />
          </div>
          <div className=" mms:w-full flex items-center justify-center w-[200px] h-full ">
            {cdpsAttendances?.length > 0 && (
              <>
                <p className=" text-title ">Registros:</p>
                <p className=" ml-2 text-Green font-bold ">
                  {cdpsAttendances.length}
                </p>
              </>
            )}
          </div>
        </div>
      </div>

      {/* content */}
      <div className=" w-full h-[calc(100%-100px)] bg-bg2 rounded-[10px] overflow-y-auto ">
        {cdpsAttendances === null ? (
          <div className=" flex justify-center items-center w-full min-h-[400px] text-content ">
            <p>{attendanceMessage}</p>
          </div>
        ) : cdpsAttendances.length > 0 ? (
          <div className=" flex justify-start flex-wrap gap-3 px-[40px] py-[20px] w-full min-h-[450px] h-auto ">
            {cdpsAttendances.map((cdp, index) => (
              <CdpAttendanceCard cdp={cdp} key={index} />
            ))}
          </div>
        ) : (
          <div className="  flex justify-center items-center min-h-[400px] text-content ">
            <p className=" text-Red ">
              No hay asistencias en esta reunión de discipulado.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

const SelectOptions = ({
  title,
  noTitle,
  items = [],
  setSelection,
  getAllDiscipleships,
  showOptions,
  setShowOptions,
}) => {
  // const [showCategoryOptions, setShowCategoryOptions] = useState(false);
  return (
    <div className=" w-full ">
      <div className=" ">
        <div className=" relative w-full bg-bg3 rounded">
          <button
            type="button"
            onClick={() => setShowOptions(!showOptions)}
            className="flex justify-between items-center px-4 w-full h-[50px] text-content"
          >
            {title ? (
              <>
                <span className=" text-white">{title.name}</span>
                <i className="text-white fa-solid fa-angle-down"></i>
              </>
            ) : (
              <>
                <span className=" text-content">{noTitle}</span>
                <i className="text-content fa-solid fa-angle-down"></i>
              </>
            )}
          </button>
          <div
            className={`absolute z-50 top-[calc(50px+10px)] w-full bg-bg3 border-b border-x border-bg4 shadow-lg
                  ${showOptions ? "block" : "hidden"}
                `}
          >
            {items.length > 0 ? (
              items.map((item, i) => (
                <button
                  type="button"
                  onClick={() => {
                    setSelection(item);
                    getAllDiscipleships && getAllDiscipleships(item.id);
                    setShowOptions(false);
                  }}
                  className="flex items-center px-2 w-full h-[50px] text-content hover:text-title hover:bg-bg4 "
                  key={i}
                >
                  {item.name}
                </button>
              ))
            ) : (
              <div className=" flex items-center justify-center h-[32px] text-content ">
                <small>No hay opciones</small>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const SelectOptionsDiscipleship = ({
  title,
  noTitle,
  items = [],
  setSelection,
  showOptions,
  setShowOptions,
  setCurrentDate,
}) => {
  // const [showCategoryOptions, setShowCategoryOptions] = useState(false);
  return (
    <div className=" w-full ">
      <div className="">
        <div className=" relative w-full bg-bg3 rounded">
          <button
            type="button"
            onClick={() => setShowOptions(!showOptions)}
            className="flex justify-between items-center px-4 w-full h-[50px] text-content"
          >
            {title ? (
              <>
                <span className=" text-white overflow-hidden whitespace-nowrap text-ellipsis ">
                  {title.leader_one_fullname}{" "}
                  {title.leader_two_fullname &&
                    " - " + title.leader_two_fullname}
                </span>
                <i className="text-white fa-solid fa-angle-down"></i>
              </>
            ) : (
              <>
                <span className=" text-content">{noTitle}</span>
                <i className="text-content fa-solid fa-angle-down"></i>
              </>
            )}
          </button>
          <div
            className={`absolute z-10 top-[calc(50px+10px)] w-full bg-bg3 border-b border-x border-bg4 shadow-lg
                  ${showOptions ? "block" : "hidden"}
                `}
          >
            {items.length > 0 ? (
              items.map((item, i) => (
                <button
                  type="button"
                  onClick={() => {
                    setSelection(item);
                    setShowOptions(false);
                    setCurrentDate(new Date());
                  }}
                  className=" text-start px-2 w-full h-[50px] text-content hover:text-title hover:bg-bg4 "
                  key={i}
                >
                  {item.leader_one_fullname}{" "}
                  {item.leader_two_fullname && " - " + item.leader_two_fullname}
                </button>
              ))
            ) : (
              <div className=" flex items-center justify-center h-[32px] text-content ">
                <small>No hay opciones</small>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CdpAttendanceCard = ({ cdp }) => {
  return (
    <div className=" mb-[20px] p-[10px] w-[230px] h-[130px] bg-bg3 rounded-[10px]">
      <p className=" text-title ">Casa de Paz de:</p>
      <p className=" text-content">{cdp.leader_one_fullname}</p>
      {cdp.leader_two_fullname && (
        <p className=" text-content "> {cdp.leader_two_fullname}</p>
      )}
      {cdp.attended ? (
        <p className=" text-Green ">
          Asistio <i className=" fa-solid fa-check-circle"></i>
        </p>
      ) : (
        <p className=" text-Red ">
          No asistio <i className=" fa-solid fa-times-circle"></i>
        </p>
      )}
    </div>
  );
};
