import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNotifications } from "reapop";

import DeptoContext from "../context/Departments/Context";
import Layout from "../components/Layout";
import { fonts } from "../assets/styles";

const schema = yup.object({
  name: yup
    .string()
    .required("Ingrese su nombre por favor.")
    .max(32, "Máximo 32 carácteres"),
  lastname: yup
    .string()
    .required("Ingrese su apellido por favor.")
    .max(32, "Máximo 32 carácteres"),
  // age: yup.string().required("Ingrese su edad por favor."),
  address: yup
    .string()
    .required("Ingrese su dirección por favor.")
    .max(64, "Máximo 64 carácteres"),
  phone: yup
    .string()
    .required("Ingrese su teléfono por favor")
    .max(16, "Máximo 16 carácteres"),
  email: yup
    .string()
    .email("Ingrese un correo válido por favor")
    .required("Ingrese su correo por favor"),
  petition: yup.string().required("Ingrese su petición por favor"),
});

export default function NewBelieverCreate() {
  const [showCdps, setShowCdps] = useState(false);
  const { setMenuModal, setShowModal, tutor, setTutor, user } =
    useContext(DeptoContext);

  const { notify } = useNotifications();

  // to age
  const options = Array.from({ length: 80 }, (_, index) => index + 1);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (formdata) => {
    try {
      // to verify that the new_convert doesn't already exists
      const { data: new_convert } = await axios.get(
        `/api/v1/new_convert/${formdata.phone}`
      );
      if (!new_convert.exists) {
        if (!tutor) {
          notify({
            title: "Aviso",
            message: "Ingrese o seleccione un tutor por favor",
            status: "warning",
          });
          return;
        }

        formdata.is_man = formdata.is_man === "true" ? true : false;
        formdata.is_single = formdata.is_single === "true" ? true : false;
        formdata.to_visit = formdata.to_visit === "true" ? true : false;

        // 1. ADD TUTOR
        tutor.has_cdp = showCdps;
        const { data: tutorResp } = await axios.post("/api/v1/tutor/", tutor);
        formdata.id_tutor = tutorResp.tutor_id;
        // 2. ADD EVANGELIST
        const { data: evangelistResp } = await axios.post(
          "/api/v1/evangelist",
          {
            name: user.name,
            lastname: user.lastname,
            email: user.email,
          }
        );
        formdata.id_evangelist = evangelistResp.evangelist_id;
        // 3. ADD NEW CONVERT
        // 3.1 Remove and save petition
        const petition = formdata.petition;
        delete formdata.petition;
        const { data: newConvertResp } = await axios.post(
          "/api/v1/new_convert/",
          formdata
        );
        // 4. ADD PRAY PETITION
        if (newConvertResp.status_code === 201) {
          const prayPetitionObj = {
            for_fullname: formdata.name + " " + formdata.lastname,
            for_phone: formdata.phone,
            for_id_new_convert: newConvertResp.new_convert_id,
            made_by_fullname: user.name + " " + user.lastname,
            made_by_phone: user.phone,
            depto_from: "Evangelismo",
            petition: petition,
          };
          const { data: prayPetitionResp } = await axios.post(
            "/api/v1/pray_petitions/",
            prayPetitionObj
          );
          if (prayPetitionResp.status_code === 201) {
            localStorage.removeItem("tutor_info");
            reset();
            setShowCdps(false);
            setTutor();
            notify({
              title: "Exito!",
              message: prayPetitionResp.message,
              status: "success",
            });
          } else {
            notify({
              title: "¡Error!",
              message: prayPetitionResp.message,
              status: "error",
            });
          }
        }
      } else {
        notify({
          title: "Aviso",
          message: "Este nuevo creyente ya ha sido agregado.",
          status: "warning",
        });
      }
    } catch (error) {
      notify({
        title: "¡Error!",
        message:
          "Ha habido un error en el servidor. Intente nuevamente por favor.",
        status: "error",
      });
    }
  };

  useEffect(() => {
    setTutor();

    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px] py-[40px] h-full overflow-y-auto bg-bg ">
        <div className=" mms:mb-[20px] flex flex-col justify-between items-center h-auto ">
          {/* title */}
          <div className="w-full text-center mb-[32px]">
            <h1 className={` mms:text-[24px] ts:text-[28px] uppercase `}>
              Nuevo Creyente
            </h1>
          </div>

          {/* form */}
          <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] ll:px-[20px] lxl:px-[40px] py-[20px] h-auto w-full bg-bg2 rounded-[10px] ">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" mms:flex-col  mms:px-[10px] mms:pb-[40px] mm:px-[20px] ml:px-[40px] ll:flex-row ll:px-[10px] pt-0 flex justify-between items-start w-full h-auto "
            >
              {/* left side */}
              <div className=" mms:mb-6 mms:w-full ll:w-[45%] ">
                <div className=" mms:flex-col ls:flex-row flex justify-between mt-6">
                  {/* name */}
                  <div className=" mms:w-full ls:w-[45%] ">
                    <label className=" mms:text-[18px] ts:text-base text-subtitle block mb-2">
                      Nombre <span className="text-Red">*</span>
                    </label>
                    <input
                      className="  mms:h-[48px] mms:text-[18px] ts:h-[44px] ts:text-base indent-2 w-full bg-bg4 text-content outline-none rounded"
                      {...register("name")}
                      maxLength={32}
                    />
                    {
                      <small className="text-red-500">
                        {errors.name?.message}
                      </small>
                    }
                  </div>
                  {/* lastname */}
                  <div className=" mms:mt-6 mms:w-full ls:mt-0 ls:w-[45%] ">
                    <label className="mms:text-[18px] ts:text-base text-subtitle block mb-2">
                      Apellido <span className="text-Red">*</span>
                    </label>{" "}
                    <input
                      className="  mms:h-[48px] mms:text-[18px] ts:h-[44px] ts:text-base indent-2 w-full bg-bg4 text-content outline-none rounded"
                      {...register("lastname")}
                      maxLength={32}
                    />
                    {
                      <small className="text-red-500">
                        {errors.lastname?.message}
                      </small>
                    }
                  </div>
                </div>

                {/* age & single */}
                <div className=" mms:flex-col ls:flex-row flex justify-between mt-6">
                  <div className=" mms:mb-6 mms:w-full ls:w-[45%]">
                    <label className="mms:text-[18px] ts:text-base text-subtitle mb-2 block">
                      Edad<span className="text-Red">*</span>
                    </label>

                    <select
                      className=" px-2 w-full h-[44px] bg-bg4 text-content outline-none rounded"
                      // id="selectmethod"
                      defaultValue=""
                      // name="exampleRequired"
                      {...register("age", { required: true })}
                    >
                      {options.map((optionValue) => (
                        <option key={optionValue} value={optionValue}>
                          {optionValue}
                        </option>
                      ))}
                    </select>

                    <small className="text-red-500">
                      {errors.age?.message}
                    </small>
                  </div>
                  {/* is single? */}
                  <div className=" mms:w-full ls:w-[45%]">
                    <label className=" mms:text-[18px] ts:text-base text-subtitle ">
                      Soltero (a)
                    </label>
                    <div className="flex justify-start mt-6">
                      <label className={`mr-[64px] ${fonts.body1}`}>
                        <input
                          {...register("is_single")}
                          type="radio"
                          value={true}
                          className="mr-2"
                        />
                        Si
                      </label>
                      <label className={` ${fonts.body1}`}>
                        <input
                          {...register("is_single")}
                          type="radio"
                          value={false}
                          className="mr-2"
                          defaultChecked={true}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
                {/* address */}
                <div className=" mt-6 w-full">
                  <label className=" mms:text-[18px] ts:text-base text-subtitle block mb-2">
                    Dirección <span className="text-Red">*</span>
                  </label>{" "}
                  <input
                    className="  mms:h-[48px] mms:text-[18px] ts:h-[44px] ts:text-base indent-2 w-full bg-bg4 text-content outline-none rounded"
                    {...register("address")}
                    maxLength={64}
                  />
                  {
                    <small className="text-red-500">
                      {errors.address?.message}
                    </small>
                  }
                </div>

                {/* genre & phone */}
                <div className=" mms:flex-col ls:flex-row flex justify-between mt-6">
                  <div className=" mms:mb-6 mms:w-full ls:w-[45%] ">
                    <label className=" mms:text-[18px] ts:text-base text-subtitle mb-2 block">
                      Teléfono <span className="text-Red">*</span>
                    </label>
                    <input
                      className=" indent-2 w-full h-[44px] bg-bg4 text-content outline-none rounded"
                      {...register("phone")}
                      maxLength={16}
                    />
                    <small className="text-red-500">
                      {errors.phone?.message}
                    </small>
                  </div>

                  {/* genre */}
                  <div className=" mms:w-full ls:w-[45%] ">
                    <label className=" mms:text-[18px] ts:text-base text-subtitle ">
                      Género
                    </label>
                    <div className="flex justify-start items-end h-[44px]">
                      <label className={` mr-[32px] ${fonts.body1}`}>
                        <input
                          {...register("is_man")}
                          type="radio"
                          value={true}
                          className="mr-2"
                        />
                        Masculino
                      </label>
                      <label className={`${fonts.body1}`}>
                        <input
                          {...register("is_man")}
                          type="radio"
                          value={false}
                          className="mr-2"
                          defaultChecked={true}
                        />
                        Femenino
                      </label>
                    </div>
                  </div>
                </div>

                {/* email */}
                <div className=" mt-6 w-full">
                  <label className=" mms:text-[18px] ts:text-base text-subtitle block mb-2">
                    Email <span className="text-Red">*</span>
                  </label>{" "}
                  <input
                    className="  mms:h-[48px] mms:text-[18px] ts:h-[44px] ts:text-base indent-2 w-full bg-bg4 text-content outline-none rounded"
                    {...register("email")}
                    maxLength={64}
                  />
                  <small className="text-red-500">
                    {errors.email?.message}
                  </small>
                </div>
              </div>

              {/* Right Side */}
              <div className=" mms:w-full ll:w-[45%] ">
                {/* cdp & tutor selection */}
                <div className="flex justify-start ">
                  <div className=" w-[50%] ">
                    <label className=" mms:text-[18px] ts:text-base text-subtitle">
                      ¿Asiste a una CDP?
                    </label>
                    <div className="flex justify-start mt-2">
                      <button
                        onClick={() => {
                          setMenuModal("ADD-MEMBER-TUTOR-BY-CDP");
                          setShowModal(true);
                          setShowCdps(true);
                        }}
                        type="button"
                        className={` mms:mr-[20px] mr-[64px] min-w-[44px] h-[36px] rounded ${
                          showCdps
                            ? "bg-primary text-white"
                            : "bg-borders text-content"
                        }`}
                      >
                        Si
                      </button>
                      <button
                        onClick={() => {
                          setShowCdps(false);
                        }}
                        type="button"
                        className={` mms:mr-[20px] mr-[64px] min-w-[44px] h-[36px] rounded ${
                          !showCdps
                            ? "bg-primary text-white"
                            : "bg-borders text-content"
                        }`}
                      >
                        No
                      </button>
                    </div>
                  </div>
                  {!showCdps && (
                    <div className=" flex justify-start items-center w-[50%] ">
                      <div className=" mr-4 w-auto">
                        <label className=" mms:text-[18px] ts:text-base text-subtitle block ">
                          Tutor<span className="text-Red">*</span>
                        </label>
                        <button
                          onClick={() => {
                            setMenuModal("ADD-MEMBER-TUTOR");
                            setShowModal(true);
                          }}
                          className=" mt-2 px-[10px] min-w-[95px] h-[36px] bg-Green rounded "
                          type="button"
                        >
                          Agregar tutor
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                {/* petition */}
                <div className=" mt-6 w-full">
                  <label className=" mms:text-[18px] ts:text-base text-subtitle block mb-2">
                    Petición
                  </label>{" "}
                  <textarea
                    className=" indent-2 w-full min-h-[100px] max-h-[100px] bg-bg4 text-content outline-none rounded"
                    {...register("petition")}
                    maxLength={1024}
                  />
                  {
                    <small className="text-red-500">
                      {errors.petition?.message}
                    </small>
                  }
                </div>

                {/* conexión & wanted be visited */}
                <div className=" mms:flex-col ls:flex-row flex justify-between">
                  {/* conexion */}
                  <div className=" mms:my-6 mms:w-full ls:w-[45%]">
                    <label className=" mms:text-[18px] ts:text-base text-subtitle mb-2 block">
                      Conexión <span className="text-Red">*</span>
                    </label>
                    <select
                      className=" px-2 w-full h-[44px] bg-bg4 text-content outline-none rounded"
                      id="selectmethod"
                      defaultValue=""
                      // name="exampleRequired"
                      {...register("conexion", { required: true })}
                    >
                      <option className="h-[44px]" value="Iglesia">
                        Iglesia
                      </option>
                      <option className="h-[44px" value="Actividad externa">
                        Actividad Externa
                      </option>
                      <option className="h-[44px" value="Casa de Paz">
                        Casa de Paz
                      </option>
                    </select>
                    {/* <small className="text-red-500">
                      {errors.phone?.message}
                    </small> */}
                  </div>
                  {/* visited */}
                  <div className=" mms:w-full ls:mt-6 ls:w-[45%] ">
                    <label className=" mms:text-[18px] ts:text-base text-subtitle ">
                      ¿Desea ser visitado?
                    </label>
                    <div className="flex justify-start mt-6">
                      <label className="mr-[64px] flex items-center">
                        <input
                          {...register("to_visit")}
                          type="radio"
                          value={true}
                          className="mr-2"
                          defaultChecked={true}
                        />
                        Si
                      </label>
                      <label>
                        <input
                          {...register("to_visit")}
                          type="radio"
                          value={false}
                          className="mr-2"
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>

                {tutor !== null && tutor !== undefined && (
                  <div className=" flex flex-col justify-end mt-6 h-[64px] ">
                    <p className="text-title">Tutor</p>
                    <p className="text-content">
                      Nombre: {tutor?.name} {tutor?.lastname}
                    </p>
                    <p className="text-content">Correo: {tutor?.email}</p>
                  </div>
                )}

                <div className=" flex justify-center mt-8 w-full">
                  <button className="bg-primary text-white w-full h-[44px] rounded uppercase">
                    Enviar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
