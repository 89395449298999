import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNotifications } from "reapop";
import { confirmAlert } from "react-confirm-alert";

import DeptoContext from "../../../../context/Departments/Context";
import { fonts } from "../../../../assets/styles";

const schema = yup.object({
  address: yup
    .string()
    .required("Ingrese una dirección por favor.")
    .max(64, "Máximo 64 carácteres"),
});

export default function AfirmationCDPDetails() {
  const { selectedCdp, setAfirmationMenu } = useContext(DeptoContext);

  const [allMembers, setAllMembers] = useState([]);
  const [showGetMembers, setShowGetMembers] = useState(false); // to filter people to be disciples
  const [cdpDisciples, setCdpDisciples] = useState([]);

  const { notify } = useNotifications();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  // remove disciple from a the selected cdp
  const confirmRemoveDisciple = (disciple) => {
    confirmAlert({
      title: "Remover discípulo",
      message: "¿Esta seguro de remover al discípulo?",
      buttons: [
        {
          label: "Confirmar",
          onClick: () => removeDisciple(disciple),
        },
        {
          label: "Cancelar",
        },
      ],
    });
  };

  const removeDisciple = async (disciple) => {
    const { data } = await axios.delete(
      `/api/v1/disciples/remove/${disciple.id}`
    );
    if (data.status_code === 200) {
      getAllCdpDisciples(); // reload disciples list
      notify({
        title: "Discipulo removido",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
  };

  // remove leader
  const confirmRemoveLeader = () => {
    confirmAlert({
      title: "Remover líder",
      message: "¿Esta seguro de remover al líder?",
      buttons: [
        {
          label: "Confirmar",
          onClick: () => removeLeader(),
        },
        {
          label: "Cancelar",
        },
      ],
    });
  };
  const removeLeader = async () => {
    const { data } = await axios.put(
      `/api/v1/cdps/remove_leader_two/${selectedCdp.id}/`
    );
    if (data.status_code === 200) {
      localStorage.setItem("afirmation_menu", JSON.stringify({ menu: "CDPS" }));
      setAfirmationMenu();
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
  };

  // useform data
  const onSubmit = async (formdata) => {
    try {
      formdata.cdp_id = selectedCdp.id;
      const { data } = await axios.put("/api/v1/cdps/", formdata);
      if (data.status_code === 201) {
        // reset();
        notify({
          title: "Éxito",
          message: data.message,
          status: "success",
        });
      } else {
        notify({
          title: "Error",
          message: data.message,
          status: "error",
        });
      }
    } catch (error) {
      notify({
        title: "¡Error!",
        message:
          "Ha habido un error en el servidor. Intente nuevamente por favor.",
        status: "error",
      });
    }
  };

  const getAllMembers = async () => {
    if (showGetMembers) {
      const { data } = await axios.get("/api/v1/members/cdp_leaders"); // all members (including leaders but not disciples)
      data.sort((a, b) => a.name.localeCompare(b.name));
      setAllMembers(data);
    } else {
      const { data } = await axios.get("/api/v1/new_converts/cdp_leaders");
      data.sort((a, b) => a.name.localeCompare(b.name));
      setAllMembers(data);
    }
  };
  useEffect(() => {
    getAllMembers();
  }, [showGetMembers]);

  const getAllCdpDisciples = async () => {
    const { data } = await axios.get(`/api/v1/disciples/cdp/${selectedCdp.id}`);
    data.sort((a, b) => a.name.localeCompare(b.name));
    setCdpDisciples(data);
  };
  useEffect(() => {
    if (!selectedCdp) {
      localStorage.setItem("afirmation_menu", JSON.stringify({ menu: "CDPS" }));
      setAfirmationMenu();
    }
    selectedCdp && getAllCdpDisciples();
  }, []);

  return (
    // container
    <div className=" mms:px-[10px] mms:py-[20px] mms:h-auto mm:px-[20px] ml:px-[40px] tl:h-full ll:px-[80px] w-auto overflow-y-auto ">
      {/* content */}
      <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] ll:px-[10px] py-[20px] min-h-[500px]  max-h-auto w-full bg-bg2 rounded-[25px] overflow-y-auto ">
        {/* title */}
        <div className=" pb-4 text-center w-full">
          <h1 className={`text-title ${fonts.heading3} uppercase`}>
            {/* casa de paz */}
            Detalles
          </h1>
        </div>

        {/* leaders cdp */}
        <div className=" mms:flex-col-reverse ll:flex-row flex justify-between p-[10px]">
          {/* disciples */}
          <div className=" mms:px-0 mms:w-full px-[10px] ll:w-[30%] h-auto ">
            <div>
              <p className=" text-title">Discípulos</p>
            </div>
            <div className=" p-[10px] max-h-[450px] overflow-y-auto bg-bg3 rounded">
              {cdpDisciples.length > 0 ? (
                cdpDisciples.map((disciple, index) => (
                  <div
                    className=" flex justify-between items-center mb-[20px] px-[10px] h-[36px] bg-bg4 rounded"
                    key={disciple.id}
                  >
                    <div className=" text-title ">
                      <p>
                        {index + 1 + "."} {disciple.name} {disciple.lastname}
                      </p>
                    </div>
                    <div>
                      <button
                        className={` px-[10px] w-auto bg-Red ${fonts.body1} rounded `}
                        onClick={() => confirmRemoveDisciple(disciple)}
                      >
                        Quitar
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p className=" text-content ">No hay Discípulos</p>
              )}
            </div>
          </div>

          {/* form */}
          <div className=" mms:my-[40px] mms:py-[40px] mms:w-full mms:border-y border-options ll:my-0 ll:py-0 ll:w-[30%] ll:border-none ">
            <div className="mb-4">
              <p className="mb-1 text-title">Líder (es)</p>
              <div className=" flex flex-col justify-around px-[20px] py-[10px] w-full h-[100px] bg-bg3 rounded-[10px] overflow-y-auto">
                <div className=" flex justify-start mb-2 pr-0 ">
                  <span className="w-[60px] text-subtitle">Líder 1</span>
                  <p>{selectedCdp?.leader_one_fullname}</p>
                </div>
                {selectedCdp?.leader_two_fullname && (
                  <div className=" flex justify-start ">
                    <span className=" text-subtitle w-[70px]">Líder 2</span>
                    <div className=" flex justify-between w-full">
                      <span className=" ml-2 text-white">
                        {selectedCdp.leader_two_fullname}
                      </span>
                      <button
                        onClick={confirmRemoveLeader}
                        className=" px-[10px] h-[28px] bg-Red rounded"
                      >
                        Quitar
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* form */}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" pt-0 w-full h-auto"
            >
              {/* Address */}
              <div className=" mb-4 w-full">
                <label className="text-subtitle block mb-2">
                  Dirección <span className="text-Red">*</span>
                </label>{" "}
                <input
                  className=" indent-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
                  {...register("address")}
                  defaultValue={selectedCdp?.address}
                  maxLength={64}
                />
                {
                  <small className="text-red-500">
                    {errors.address?.message}
                  </small>
                }
              </div>

              {/* CDP Day/Hour */}
              <div className=" mb-4 w-full">
                <label className="text-subtitle mb-2 block">
                  Día de realización <span className="text-Red">*</span>
                </label>
                <select
                  className=" px-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
                  defaultValue={selectedCdp?.day}
                  {...register("day", { required: true })}
                >
                  <option value="Lunes">Lunes</option>
                  <option value="Martes">Martes</option>
                  <option value="Miércoles">Miércoles</option>
                  <option value="Jueves">Jueves</option>
                  <option value="Viernes">Viernes</option>
                  <option value="Sábado">Sábado</option>
                  <option value="Domingo">Domingo</option>
                </select>
                <small className="text-red-500">{errors.email?.message}</small>
              </div>

              {/* hour */}
              <div className=" mb-4 w-full">
                <label className="text-subtitle mb-2 block">
                  Hora de realización <span className="text-Red">*</span>
                </label>
                <select
                  className=" px-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
                  {...register("hour", { required: true })}
                  defaultValue={selectedCdp?.hour}
                >
                  <option value="08:00 AM">08:00 AM</option>
                  <option value="09:00 AM">09:00 AM</option>
                  <option value="10:00 AM">10:00 AM</option>
                  <option value="11:00 AM">11:00 AM</option>
                  <option value="12:00 PM">12:00 PM</option>
                  <option value="13:00 PM">01:00 PM</option>
                  <option value="14:00 PM">02:00 PM</option>
                  <option value="15:00 PM">03:00 PM</option>
                  <option value="16:00 PM">04:00 PM</option>
                  <option value="17:00 PM">05:00 PM</option>
                  <option value="18:00 PM">06:00 PM</option>
                  <option value="19:00 PM">07:00 PM</option>
                  <option value="20:00 PM">08:00 PM</option>
                  <option value="21:00 PM">09:00 PM</option>
                  <option value="22:00 PM">10:00 PM</option>
                  <option value="23:00 PM">11:00 PM</option>
                </select>
                <small className="text-red-500">{errors.phone?.message}</small>
              </div>

              <div className=" flex justify-start mt-8 w-full">
                <button className="bg-primary text-white w-[150px] h-[44px] rounded uppercase">
                  Modificar
                </button>
              </div>
            </form>
          </div>

          {/* user to be leaders */}
          <div className=" mms:w-full ll:w-[35%] h-full ">
            <div className="mb-8">
              {/* show people to be student */}
              <div className="  mms:mb-2 mms:bg-bg3 ml:px-0 mm:bg-bg2 flex justify-start items-center h-auto overflow-x-auto rounded-[25px] ml:max-w-[350px] ">
                <div className=" flex items-center px-[2px] mms:w-full w-[300px] h-[32px] bg-[#D9D9D9] text-content rounded-[25px] ">
                  <button
                    onClick={() => setShowGetMembers(false)}
                    className={` mms:w-[50%] h-[30px] ${
                      !showGetMembers
                        ? " bg-primary text-white "
                        : " bg-[#d9d9d9] text-content"
                    } rounded-[25px] outline-none`}
                  >
                    Creyentes
                  </button>
                  <button
                    onClick={() => setShowGetMembers(true)}
                    className={` mms:w-[50%] h-[30px] ${
                      showGetMembers
                        ? " bg-primary text-white "
                        : " bg-[#d9d9d9] text-content"
                    } rounded-[25px] outline-none`}
                  >
                    Usuarios
                  </button>
                </div>
              </div>
              <div>
                <small className=" text-content">
                  Seleccione para cambiar Líder 1 y Líder 2
                </small>
              </div>
              {/* list people */}
              <div className="p-[20px] w-full h-[400px] max-h-[400px] bg-bg3 rounded-[10px] overflow-y-auto">
                {allMembers.length > 0 ? (
                  allMembers.map((member) => (
                    <UserCardList
                      {...{ member, selectedCdp }}
                      key={member.id}
                    />
                  ))
                ) : (
                  <p>No hay usuarios disponibles</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// components userList
const UserCardList = ({ member, selectedCdp }) => {
  const { setAfirmationMenu } = useContext(DeptoContext);
  const { notify } = useNotifications();

  const confirmChangeLeader1 = (leader) => {
    confirmAlert({
      title: `Cambiar Líder`,
      message: `¿Esta seguro de hacer a ${leader.name} ${leader.lastname} Líder 1?`,
      buttons: [
        {
          label: "Confirmar",
          onClick: () => changeLeaderOne(leader),
        },
        {
          label: "Cancelar",
        },
      ],
    });
  };

  const changeLeaderOne = async (leader) => {
    const leaderOneData = {
      leader_one_code: leader.code,
      leader_one_fullname: leader.name + " " + leader.lastname,
      leader_one_phone: leader.phone,
    };
    const { data } = await axios.put(
      `/api/v1/cdps/change_leader_one/${selectedCdp.id}`,
      leaderOneData
    );
    if (data.status_code === 200) {
      localStorage.setItem("afirmation_menu", JSON.stringify({ menu: "CDPS" }));
      setAfirmationMenu();
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
  };

  const confirmChangeLeader2 = (leader) => {
    confirmAlert({
      title: `Cambiar Líder`,
      message: `¿Esta seguro de hacer a ${leader.name} ${leader.lastname} Líder 2?`,
      buttons: [
        {
          label: "Confirmar",
          onClick: () => changeLeaderTwo(leader),
        },
        {
          label: "Cancelar",
        },
      ],
    });
  };

  const changeLeaderTwo = async (leader) => {
    const leaderTwoData = {
      leader_two_code: leader.code,
      leader_two_fullname: leader.name + " " + leader.lastname,
      leader_two_phone: leader.phone,
    };
    const { data } = await axios.put(
      `/api/v1/cdps/change_leader_two/${selectedCdp.id}`,
      leaderTwoData
    );
    if (data.status_code === 200) {
      localStorage.setItem("afirmation_menu", JSON.stringify({ menu: "CDPS" }));
      setAfirmationMenu();
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
  };

  return (
    <div className=" mms:flex-col mms:items-start mms:mb-4 mms:pb-2 mms:h-auto ts:flex-row ll:flex-col lxl:flex-row flex justify-between items-center h-[56px] border-b border-options">
      <div className=" mms:mb-2 ">
        <p
          className={` max-w-[150px] text-subtitle overflow-hidden whitespace-nowrap text-ellipsis `}
        >
          {member.name} {member.lastname}
        </p>
        <div className={` flex ${fonts.body2}`}>
          {member.is_cdp_leader && (
            <li className="mr-2 text-Green">Es líder</li>
          )}
          {member.is_cdp_disciple && (
            <li className=" text-third">Es Discípulo</li>
          )}
        </div>
      </div>
      <div>
        <button
          onClick={() => {
            confirmChangeLeader1(member);
          }}
          className=" mr-[10px] px-2 w-auto h-[28px] text-sm bg-primary rounded "
        >
          Líder 1
        </button>

        <button
          onClick={() => {
            confirmChangeLeader2(member);
          }}
          className=" px-2 w-auto h-[28px] text-sm bg-primary rounded "
        >
          Líder 2
        </button>
      </div>
    </div>
  );
};
