import axios from "axios";
import { useEffect, useState } from "react";
import { useNotifications } from "reapop";
import { format } from "date-fns";
import { confirmAlert } from "react-confirm-alert";

import Layout from "../components/Layout";
import HeaderMenu from "../components/admin/HeaderMenu";
import { fonts } from "../assets/styles";

export default function Admin() {
  const [activities, setActivities] = useState([]);

  const getAllActivities = async () => {
    const { data } = await axios.get("/api/v1/church_activities/");
    setActivities(data);
  };
  useEffect(() => {
    getAllActivities();
  }, []);

  return (
    <Layout>
      <div
        className=" mms:pb-[0px]
        w-auto h-full bg-bg overflow-y-auto "
      >
        <HeaderMenu />
        <div className=" mms:px-[10px] mms:pt-[20px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px] w-full h-auto ">
          {/* title */}
          <div className=" my-4 ">
            <h1
              className={` text-center text-title ${fonts.heading4} uppercase `}
            >
              Actividades
            </h1>
          </div>

          {/* content */}
          <div className=" mms:py-[20px] w-full h-auto rounded-[10px] ">
            <div className=" mms:justify-center ts:justify-between flex flex-wrap p-[20px] min-h-[500px] max-h-auto w-full bg-bg2 rounded-[10px] ">
              {activities.length > 0 ? (
                activities.map((activity) => (
                  <ActivityCard
                    key={activity.id}
                    activity={activity}
                    getAllActivities={getAllActivities}
                  />
                ))
              ) : (
                <div className=" w-full text-center text-content ">
                  <p>No hay actividades</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const ActivityCard = ({ activity, getAllActivities }) => {
  const { notify } = useNotifications();

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Confirmar",
      message: "¿Esta seguro de borrar esta actividad?",
      buttons: [
        {
          label: "Si",
          onClick: () => handleDelete(id),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleDelete = async (id) => {
    const { data } = await axios.delete(`/api/v1/church_activities/${id}`);
    if (data.status_code === 200) {
      getAllActivities();
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "warning",
      });
    }
  };

  return (
    <div className=" mb-[20px] p-[10px] w-[300px] h-[220px] bg-bg3 rounded-[10px]">
      <div className=" mb-3 pb-2 border-b border-options">
        <p className=" text-third">Nombre:</p>
        <p className=" text-content">{activity.name}</p>
      </div>
      <div className=" mb-3  pb-2 border-b border-options">
        <p className=" text-third">Fecha y Hora:</p>
        <p className=" text-content">
          {format(new Date(activity.date), "dd 'de' MMM 'de' yyyy")}
          {" | "}
          {activity.hour}
        </p>
      </div>
      <div className=" mb-3 pb-2 ">
        <button
          onClick={() => confirmDelete(activity.id)}
          className=" w-[100px] h-[44px] bg-Red rounded "
        >
          Borrar
        </button>
      </div>
    </div>
  );
};
