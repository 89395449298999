import { useContext } from "react";

import DeptoContext from "../../../../context/Departments/Context";

export default function EducationHeaderAdmin() {
  const { setShowModal, setMenuModal, setEducationMenu, educationMenu } =
    useContext(DeptoContext);

  return (
    <div
      className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px]
    flex items-center px-[80px] h-[75px] w-full border-b border-borders overflow-x-auto "
    >
      <div className="flex items-center">
        <button
          onClick={() => {
            localStorage.setItem(
              "education_menu",
              JSON.stringify({ menu: "ADMIN-INTEGRANTS", processId: null })
            );
            setEducationMenu();
          }}
          className={` flex justify-center items-center h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            educationMenu?.menu === "ADMIN-INTEGRANTS"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          Integrantes
        </button>
        {educationMenu?.menu === "ADMIN-INTEGRANTS" && (
          <button
            onClick={() => {
              setMenuModal("ADD-MEMBER");
              setShowModal(true);
            }}
            className={` flex justify-center items-center ml-2 h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
              true ? "bg-primary text-white" : "bg-bg2 text-content"
            }`}
          >
            Agregar
          </button>
        )}
      </div>
      <div className=" mx-2 w-[1px] h-[36px] border-r border-options "></div>
      <button
        onClick={() => {
          localStorage.setItem(
            "education_menu",
            JSON.stringify({
              menu: "ADMIN-UNSUBSCRIBE-STUDENTS",
              processId: null,
            })
          );
          setEducationMenu();
        }}
        className={` flex justify-center items-center h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
          educationMenu?.menu === "ADMIN-UNSUBSCRIBE-STUDENTS"
            ? "bg-primary text-white"
            : "bg-bg2 text-content"
        }`}
      >
        Estudiantes
      </button>
    </div>
  );
}
