import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { format } from "date-fns";
import { useNotifications } from "reapop";
import { confirmAlert } from "react-confirm-alert";

import DeptoContext from "../../../../context/Departments/Context";
import "./styles.css";

export default function EvangelismActivities({ member = false }) {
  const { setEvangelismMenu } = useContext(DeptoContext);
  const [activities, setActivities] = useState([]);

  const getAllEvangelismActivities = async () => {
    const { data } = await axios.get("/api/v1/evangelism_activity");
    setActivities(data);
  };
  useEffect(() => {
    getAllEvangelismActivities();
  }, []);

  return (
    <div className=" mms:px-[10px] mms:pt-[20px] mms:pb-[40px] mms:h-auto mm:px-[20px] ml:px-[40px] tm:px-[60px] tm:h-full ll:px-[80px] overflow-y-auto bg-bg ">
      {/* title */}
      <div className=" w-full text-center text-title mb-[16px] ">
        <h1 className={` mms:text-[24px] ts:text-[32px] uppercase `}>
          Proximas actividades
        </h1>
      </div>

      {/* MEMBER: breadcrumbs */}
      {member && (
        <div className=" w-full bg-bg">
          <p className=" text-content">
            <button
              onClick={() => {
                localStorage.setItem(
                  "evangelism_menu",
                  JSON.stringify({
                    menu: "OPTIONS",
                  })
                );
                setEvangelismMenu();
              }}
              className=" text-white"
            >
              Menu
            </button>
            {" > "}Proximas actividades
          </p>
        </div>
      )}

      {/* content flex justify-between flex-wrap */}
      <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] py-[40px] flex justify-between flex-wrap w-full min-h-[450px] h-auto bg-bg2 rounded-[10px] ">
        {activities.length > 0 ? (
          activities.map((activity) => (
            <ActivityCard
              activity={activity}
              getAllEvangelismActivities={getAllEvangelismActivities}
              key={activity.id}
            />
          ))
        ) : (
          <div className=" w-full text-center text-content ">
            <span className="text-content">No hay actividades programadas</span>
          </div>
        )}
      </div>
    </div>
  );
}

const ActivityCard = ({ activity, getAllEvangelismActivities }) => {
  const { notify } = useNotifications();

  const confirmDeleteActivity = () => {
    confirmAlert({
      title: "Confirmar",
      message: "¿Está seguro de borrar esta actividad?",
      buttons: [
        {
          label: "Si",
          onClick: () => deleteActivity(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteActivity = async () => {
    const { data } = await axios.delete(
      `/api/v1/evangelism_activity/${activity.id}/`
    );
    if (data.status_code === 200) {
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
    getAllEvangelismActivities();
  };

  return (
    <div className=" mx-auto mb-[20px] p-[10px] w-[300px] h-auto bg-bg3 rounded-[10px]">
      <div className=" mb-3 pb-2 border-b border-options">
        <p className=" text-third">Actividad:</p>
        <p className=" text-content">{activity.name}</p>
      </div>
      <div className=" mb-3  pb-2 border-b border-options">
        <p className=" text-third">Fecha y Hora:</p>
        <p className=" text-content">
          {format(new Date(activity.date), "dd 'de' MMM 'de' yyyy")}
          {" | "}
          {activity.hour}
        </p>
      </div>
      <div className=" mb-3 pb-2 border-b border-options">
        <p className=" text-third">Detalles:</p>
        <p className=" max-h-[150px] text-content overflow-y-auto ">
          {activity.details}
        </p>
      </div>
      <div className=" mb-3 pb-2 border-b border-options">
        <p className=" text-third">Encargados:</p>
        <ul className=" max-h-[70px] overflow-y-auto">
          {activity.managers.split("\n").map((manager, index) => (
            <li className=" text-content" key={index}>
              {" "}
              - {manager}
            </li>
          ))}
        </ul>
      </div>

      {/* location - address button */}
      {activity.location && (
        <div className=" mb-3 pb-2 border-b border-options ">
          <p className=" text-third">
            {activity.location.startsWith("https://")
              ? "Ubicación"
              : "Dirección"}
          </p>
          <div>
            {activity.location.startsWith("https://") ? (
              <a
                href={activity.location}
                className=" flex justify-center items-center mt-1 w-[80px] bg-Green rounded "
                target="_blank"
                rel="noreferrer"
              >
                Abrir <i className=" ml-2 fa-solid fa-location-arrow "></i>
              </a>
            ) : (
              <p className=" text-content">{activity.location}</p>
            )}
          </div>
        </div>
      )}
      <div className=" mt-[20px] ">
        <button
          onClick={() => confirmDeleteActivity()}
          className=" w-[100px] h-[40px] bg-Red rounded "
        >
          Borrar
        </button>
      </div>
    </div>
  );
};
