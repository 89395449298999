import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";

import DeptoContext from "../../../../context/Departments/Context";

export default function AfirmationMenuAsignations() {
  const { setShowModal, setMenuModal, setSelectedStudentToPetition } =
    useContext(DeptoContext);
  //  setSelectedStudentToPetition => selectedStudentToPetition: { student: {}, depto: "AFIRMATION" },

  const [newConvertAsignated, setNewConvertAsignated] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const depto = searchParams.get("depto");

  const getNewConvertsAsignated = async (email) => {
    const { data } = await axios.post(
      "/api/v1/new_convert/affirmer/asignations/",
      { email_affirmer: email }
    );
    setNewConvertAsignated(data.new_converts);
  };
  useEffect(() => {
    const email = JSON.parse(localStorage.getItem("user_data")).email;
    getNewConvertsAsignated(email);
  }, []);

  return (
    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] ll:px-[80px] py-[20px] h-full overflow-y-auto ">
      <div className="mb-8 text-center">
        <h1 className={` mms:text-[24px] ts:text-[32px] text-title uppercase `}>
          Asignaciones
        </h1>
        <p className=" text-subtitle ">Afirmador</p>
      </div>
      <div className=" flex flex-col justify-center items-center w-full h-auto">
        {newConvertAsignated.length > 0 ? (
          newConvertAsignated.map((student) => (
            <button
              onClick={() => {
                setSelectedStudentToPetition({
                  student: student,
                  depto: depto,
                });
                setMenuModal("NEW-CONVERT-HELP-REQUEST-DETAILS");
                setShowModal(true);
              }}
              className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] flex justify-between items-center mx-auto mb-8 w-full h-[64px] text-title bg-bg2 rounded cursor-pointer hover:bg-bg3 hover:duration-300"
              key={student.id}
            >
              <p>
                {student.name} {student.lastname}
              </p>
              <div>
                <i className="fa-solid fa-up-right-from-square"></i>
              </div>
            </button>
          ))
        ) : (
          <div className="">
            <p className=" text-content">No hay asignaciones</p>
          </div>
        )}
      </div>
    </div>
  );
}
