import axios from "axios";
import { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import DeptoContext from "../../../../context/Departments/Context";
import { fonts } from "../../../../assets/styles";
import { useNotifications } from "reapop";

export default function AfirmationIntegrants() {
  const { membersByDepartment, getMembersByDepartment } =
    useContext(DeptoContext);

  const { notify } = useNotifications();

  const { deptoId } = useParams();

  // remove member from the selected department
  const removeMemberFromDepartment = async (memberId) => {
    try {
      const { data } = await axios.delete(
        `/api/departments/${deptoId}/remove/member/${memberId}/`
      );
      getMembersByDepartment(deptoId); // update directors list
      notify({
        title: "¡Éxito!",
        message: data.message,
        status: "success",
      });
    } catch (error) {}
  };

  useEffect(() => {
    getMembersByDepartment(deptoId);
  }, []);

  return (
    <div className=" mms:px-[10px] mms:pt-[20px] mms:pb-[40px] mms:h-auto mm:px-[20px] ml:px-[40px] tm:px-[60px] tm:h-full ll:px-[80px] overflow-y-auto bg-bg ">
      <div className="w-full text-center mb-[32px]">
        <h1 className={` mms:text-[24px] ts:text-[32px] text-title uppercase`}>
          Liberacion
        </h1>
      </div>
      <div className=" min-h-[450px] h-auto w-full p-[20px] bg-bg2 rounded-[10px] ">
        {membersByDepartment.length > 0 ? (
          membersByDepartment.map((member) => (
            <div
              className=" mms:flex-col mms:items-start mms:h-auto mms:py-[10px] ts:flex-row ts:items-center flex justify-between items-center h-[64px] px-[20px] bg-bg3 rounded-[10px] mb-[20px]"
              key={member.id}
            >
              <div>
                <div>
                  <p className={`${fonts.heading3} text-title`}>
                    {member.name} {member.lastname}
                  </p>
                </div>
                <div className={`${fonts.body1} text-content`}>
                  <span>Email: {member.email}</span>
                  <span className=" mms:block tl:inline tl:mx-4">
                    Teléfono: {member.phone}
                  </span>
                </div>
              </div>
              <div className=" mms:mt-2 ts:mt-0 ">
                <button
                  onClick={() => removeMemberFromDepartment(member.id)}
                  className=" px-[10px] h-[28px] bg-Red rounded-[5px]"
                >
                  Remover
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className=" w-full text-center ">
            <p className="text-content">
              No hay integrantes en este departamento
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
