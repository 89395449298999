import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import Layout from "../components/Layout";

import { fonts } from "../assets/styles";

export default function NewBelieverList() {
  const [newConverts, setNewConverts] = useState([]);

  const inputCodeRef = useRef();

  // get new convert by code
  const getNewConvertByCode = async (code) => {
    if (code.length > 0) {
      const { data } = await axios.get(`/api/v1/new_convert/code/${code}/`);
      setNewConverts(
        Object.keys(data.new_convert).length > 0 ? [data.new_convert] : []
      );
    } else {
      getNewConverts();
    }
  };

  const getNewConverts = async () => {
    const { data } = await axios.get("/api/v1/new_convert");
    data.sort((a, b) => a.name.localeCompare(b.name));
    setNewConverts(data);
  };
  useEffect(() => {
    getNewConverts();
  }, []);

  return (
    <Layout>
      <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px] h-full overflow-y-auto bg-bg ">
        {/* title */}
        <div className=" sticky top-0 my-8 text-center bg-bg">
          <h1 className={` ${fonts.heading4} text-title uppercase`}>
            Todos los creyentes
          </h1>
        </div>

        {/* filter */}
        <div className=" sticky top-[40px] mb-2 h-[48px] bg-bg2 ">
          <div className=" mb-[20px] h-[48px] ">
            <div className=" flex justify-between w-full h-[48px] border-b border-bg ">
              <input
                type="text"
                onChange={(e) => getNewConvertByCode(e.target.value)}
                ref={inputCodeRef}
                placeholder="Ingrese código de Nuevo Creyente"
                className=" indent-3 w-[calc(100%-44px)] h-full bg-bg2 "
              />
              <div
                onClick={() => {
                  inputCodeRef.current.value = "";
                  getNewConverts();
                }}
                className=" flex justify-center items-center w-[44px] h-full bg-bg border border-bg2 cursor-pointer"
              >
                <i className=" text-[24px] fa-solid fa-times"></i>
              </div>
            </div>
          </div>
        </div>

        {/* list people */}
        <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] mb-8 p-[40px] w-full min-h-[450px] h-auto bg-bg2 rounded-[10px]">
          {newConverts.length > 0 ? (
            newConverts.map((newConvert) => (
              <CardNewBeliever newConvert={newConvert} key={newConvert.id} />
            ))
          ) : (
            <div className=" w-full text-center text-content ">
              <span>No hay nuevos creyentes.</span>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

const CardNewBeliever = ({ newConvert }) => {
  return (
    <div
      className=" mms:flex-col mms:py-[20px] mms:h-auto h-[80px] mms:pl-[10px] mm:pl-[20px] ll:flex-row ll:justify-between
      ll:py-0 flex items-center mb-[32px]  bg-bg3 rounded "
    >
      <div className=" mms:pb-3 mms:w-full ll:w-[calc(100%-200px)] flex flex-col justify-center h-full">
        <div className=" mms:flex-col mms:items-start lm:flex-row lm:items-center flex items-center ">
          <h1 className={` flex items-center text-[24px] text-title `}>
            {newConvert.name} {newConvert.lastname}
          </h1>
          <p
            className={` mms:ml-0 mms:my-1 lm:ml-2 px-[10px] py-[0px] ${fonts.body2} text-primary bg-bg2 rounded-[5px]`}
          >
            {newConvert.conexion}
          </p>
        </div>
        <div className=" text-content ">
          <small>Código: </small>
          <small className=" mms:block ">{newConvert.code}</small>
        </div>
      </div>
      <div className=" mms:justify-start mms:w-full ll:justify-center ll:w-[200px] ll:border-l justify-center flex items-center h-full border-bg4 ">
        <Link
          to={`/dashboard/nuevos-creyentes/${newConvert.code}`}
          className=" text-base flex justify-center items-center w-[110px] h-[36px] bg-primary rounded "
        >
          Detalles{" "}
          <i className=" ml-2 fa-solid fa-arrow-up-right-from-square"></i>{" "}
        </Link>
      </div>
    </div>
  );
};
