import axios from "axios";
import { useEffect, useState, useContext } from "react";

import { SELECTED_NETWORK } from "../../../../context/types";
import DeptoContext from "../../../../context/Departments/Context";
import { fonts } from "../../../../assets/styles";

export default function CommonNetworksList({ is_member = false }) {
  const [networks, setNetworks] = useState([]);

  const getAllNetworks = async () => {
    const { data } = await axios.get("/api/v1/networks");
    data.sort((a, b) => a.name.localeCompare(b.name));
    setNetworks(data);
  };
  useEffect(() => {
    getAllNetworks();
  }, []);

  return (
    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px] pb-[40px] w-full h-full bg-bg overflow-y-auto ">
      {/* title */}
      <div className="mb-8 pt-3 text-center bg-bg ">
        <h1 className={` mms:text-[24px] ts:text-[32px] text-title uppercase `}>
          Redes
        </h1>
      </div>

      {/* content */}
      <div className=" mms:px-[10px] mm:px-[20px] ts:px-[40px] py-[40px] w-full min-h-[450px] h-auto bg-bg2 rounded-[10px] ">
        {networks.length > 0 ? (
          networks.map((network) => (
            <NetworkCard
              network={network}
              is_member={is_member}
              key={network.id}
            />
          ))
        ) : (
          <div className=" w-full ">
            <p className=" text-center text-content">
              No hay redes registradas.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

const NetworkCard = ({ network, is_member }) => {
  const {
    setSelectedNetwork,
    setShowModal,
    setMenuModal,
    setNetworksMemberMenu,
  } = useContext(DeptoContext);

  return (
    <div className=" mms:flex-col mms:items-start mms:h-auto tm:flex-row tm:items-center flex justify-between items-center mb-[20px] p-[10px] w-full bg-bg3 rounded-[10px]">
      <div className=" mms:mb-2 border-options">
        <p className={` mr-2 text-title ${fonts.heading3} `}>
          Red: {network.name}{" "}
        </p>
        <div className=" mms:flex-col ts:flex-row flex ">
          <span className=" mr-2 text-subtitle ">Líder (es): </span>
          <p className=" text-content">{network.leader_one_fullname}</p>
          {network.leader_two_fullname && (
            <p className=" mms:ml-0 ts:ml-1 text-content ">
              {" "}
              & {network.leader_two_fullname}
            </p>
          )}
        </div>
      </div>
      {/* MEMBER: Selecciona una red para ver todos los discipulados que tiene y seleccionar uno para marcar asistencias. */}
      {is_member && (
        <div>
          <button
            onClick={() => {
              setSelectedNetwork(network);
              setNetworksMemberMenu({
                menu: "NETWORKS-DISCIPLESHIPS",
              });
            }}
            className=" px-[10px] h-[32px] bg-[#202020] text-title rounded "
          >
            Seleccionar
            <i className=" ml-2 fa-solid fa-up-right-from-square"></i>
          </button>
        </div>
      )}
      {/* ADMIN: Selecciona una red para ver los detalles de la red, su información y los discipulados que esta tiene. */}
      {!is_member && (
        <div className="">
          <button
            onClick={() => {
              setMenuModal(SELECTED_NETWORK);
              setSelectedNetwork(network);
              setShowModal(true);
            }}
            className=" px-[10px] h-[32px] bg-[#202020] text-title rounded "
          >
            Detalles
            <i className=" ml-2 fa-solid fa-up-right-from-square"></i>
          </button>
        </div>
      )}
    </div>
  );
};
