import axios from "axios";
import React, { useReducer } from "react";

import DeptoContext from "./Context";
import DeptoReducer from "./Reducer";

import {
  // members
  GET_AVAILABLE_MEMBERS,
  GET_MEMBERS_TO_BE_STUDENTS,
  // departments
  GET_DIRECTORS_BY_DEPARMENT,
  GET_MEMBERS_BY_DEPARTMENT,
  GET_ALL_DEPARTMENTS,
  // departments menu
  VISITS_MENU,
  EDUCATION_MENU,
  AFIRMATION_MENU,
  EVANGELISM_MENU,
  INTERSETION_MENU,
  LIBERATION_MENU,
  NETWORKS_MENU,
  NETWORKS_MEMBER_MENU,
  // get user
  GET_USER,
  // modal
  SHOW_MODAL,
  MENU_MODAL,
  // students
  GET_STUDENTS_BY_UNIT,
  SELECTED_STUDENT_TO_PETITION,
  // cdp
  SET_SELECTED_CDP,
  // Selected affirmer
  SELECTED_AFFIRMER,
  // networks
  SELECTED_DISCIPLESHIP,
  SELECTED_NETWORK,
  ADMIN_USER_DETAILS,
} from "../types";

const DepartmentState = ({ children }) => {
  const initialState = {
    departments: [],
    availableMembers: [],
    membersToBeStudents: [],
    directorsByApartment: [],
    membersByDepartment: [],
    // departments menu
    educationMenu: { menu: "PROCESS", processId: null },
    afirmationMenu: { menu: "INTEGRANTS" },
    visitsMenu: { menu: "NEW-CONVERTS" },
    evangelismMenu: { menu: "OPTIONS" },
    intersetionMenu: { menu: "INTEGRANTS" },
    liberationMenu: { menu: "INTEGRANTS" },
    networksMenu: { menu: "INTEGRANTS" },
    networksMemberMenu: { menu: "NETWORKS" },
    // get user
    user: {},
    // modal
    show_modal: false,
    menuModal: "ADD-MEMBER",
    // tutor
    tutor: null,
    // students
    studentsByUnit: null,
    selectedStudentToPetition: { student: {}, depto: "AFIRMATION" },
    // selected cdp (cdp details)
    selectedCdp: null,
    // selected affirmer: asign affirmer to new believer
    selectedAffirmer: null,
    // networks
    selectedDiscipleship: null,
    selectedNetwork: null,
    // admin
    adminUserDetails: null,
  };

  const [state, dispatch] = useReducer(DeptoReducer, initialState);

  // ----- ADMIN -----
  const setAdminUserDetails = (user) => {
    dispatch({
      type: ADMIN_USER_DETAILS,
      payload: user,
    });
  };

  // ---- NETWORKS ----
  const setSelectedNetwork = (network) => {
    dispatch({
      type: SELECTED_NETWORK,
      payload: network,
    });
  };
  const setSelectedDiscipleship = (discipleship) => {
    dispatch({
      type: SELECTED_DISCIPLESHIP,
      payload: discipleship,
    });
  };

  // ---- SELECTED AFFIRMER ----
  const setSelectedAffirmer = (affirmer) => {
    dispatch({
      type: SELECTED_AFFIRMER,
      payload: affirmer,
    });
  };

  // ---- CDP ----
  const setSelectedCdp = (cdp) => {
    dispatch({
      type: SET_SELECTED_CDP,
      payload: cdp,
    });
  };

  // ---- STUDENTS ----
  const setSelectedStudentToPetition = async (studentData) => {
    dispatch({
      type: SELECTED_STUDENT_TO_PETITION,
      payload: studentData,
    });
  };

  // get all students by unit withouth leader_retreat = false
  const getStudentsByUnit = async (unitId) => {
    const { data } = await axios.get(`/api/v1/students/unit/${unitId}/`);
    data.sort((a, b) => a.name.localeCompare(b.name));
    dispatch({
      type: GET_STUDENTS_BY_UNIT,
      payload: data,
    });
  };

  // ---- TUTOR ----
  const setTutor = (tutor) => {
    dispatch({
      type: "SET_TUTOR",
      payload: tutor,
      // payload: JSON.parse(localStorage.getItem("tutor_info")),
    });
  };
  // ---- MODAL ----
  const setMenuModal = (menu) => {
    dispatch({
      type: MENU_MODAL,
      payload: menu,
    });
  };
  const setShowModal = (show) => {
    dispatch({
      type: SHOW_MODAL,
      payload: show,
    });
  };

  // ---- GET USER ----
  const getUser = async () => {
    dispatch({
      type: GET_USER,
      payload: JSON.parse(localStorage.getItem("user_data")),
    });
  };

  // ---- DEPARTMENT MENU ----
  // networks (this resets when the refresh the page)
  const setNetworksMemberMenu = (menu) => {
    dispatch({
      type: NETWORKS_MEMBER_MENU,
      payload: menu,
    });
  };
  // admin menu
  const setNetworksMenu = () => {
    dispatch({
      type: NETWORKS_MENU,
      payload: JSON.parse(localStorage.getItem("networks_menu")),
    });
  };
  // liberation
  const setLiberationMenu = () => {
    dispatch({
      type: LIBERATION_MENU,
      payload: JSON.parse(localStorage.getItem("liberation_menu")),
    });
  };
  // intersetion
  const setIntersetionMenu = (menu) => {
    dispatch({
      type: INTERSETION_MENU,
      payload: menu,
    });
  };
  // evangelism
  const setEvangelismMenu = () => {
    dispatch({
      type: EVANGELISM_MENU,
      payload: JSON.parse(localStorage.getItem("evangelism_menu")),
    });
  };
  // visits (THERE IS NO VISITS MENU)
  const setVisitsMenu = (menu) => {
    dispatch({
      type: VISITS_MENU,
      payload: menu,
    });
  };
  // afirmation
  const setAfirmationMenu = () => {
    dispatch({
      type: AFIRMATION_MENU,
      payload: JSON.parse(localStorage.getItem("afirmation_menu")),
    });
  };
  // education
  const setEducationMenu = () => {
    dispatch({
      type: EDUCATION_MENU,
      // payload: menu,
      payload: JSON.parse(localStorage.getItem("education_menu")),
    });
  };

  // get all departments
  const getDepartments = async () => {
    try {
      const { data } = await axios.get("/api/v1/departments/");
      dispatch({
        type: GET_ALL_DEPARTMENTS,
        payload: data,
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  // get all members by department
  const getMembersByDepartment = async (deptoId) => {
    try {
      const { data } = await axios.get(
        `/api/v1/members/departments/${deptoId}/`
      );
      data.sort((a, b) => a.name.localeCompare(b.name));
      dispatch({
        type: GET_MEMBERS_BY_DEPARTMENT,
        payload: data,
      });
    } catch (error) {}
  };

  // get all directors by apartments
  const getDirectorsByDepartment = async (deptoId) => {
    try {
      const { data } = await axios.get(
        `/api/v1/directors/departments/${deptoId}/`
      );
      dispatch({
        type: GET_DIRECTORS_BY_DEPARMENT,
        payload: data,
      });
    } catch (error) {}
  };

  // get all members to be students (is_student = false)
  const getMembersToBeStudents = async (getMembersStudents) => {
    try {
      let datos = {};
      if (getMembersStudents) {
        // get members
        const { data } = await axios.get("/api/v1/members/students");
        datos = data;
      } else {
        // get new converts
        const { data } = await axios.get("/api/v1/new_converts/students");
        datos = data;
      }
      datos.sort((a, b) => a.name.localeCompare(b.name));
      dispatch({
        type: GET_MEMBERS_TO_BE_STUDENTS,
        payload: datos,
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  // get all members with is_director = false and asignations < 2
  const getAvailableMembers = async () => {
    try {
      const { data } = await axios.get("/api/v1/members/available/");
      data.sort((a, b) => a.name.localeCompare(b.name));
      dispatch({
        type: GET_AVAILABLE_MEMBERS,
        payload: data,
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <DeptoContext.Provider
      value={{
        // admin
        adminUserDetails: state.adminUserDetails,
        setAdminUserDetails,
        // networks
        selectedNetwork: state.selectedNetwork,
        setSelectedNetwork,
        selectedDiscipleship: state.selectedDiscipleship,
        setSelectedDiscipleship,
        // selected affirmer
        selectedAffirmer: state.selectedAffirmer,
        setSelectedAffirmer,
        // cdp
        selectedCdp: state.selectedCdp,
        setSelectedCdp,
        // students
        selectedStudentToPetition: state.selectedStudentToPetition,
        setSelectedStudentToPetition,
        studentsByUnit: state.studentsByUnit,
        getStudentsByUnit,
        // tutor
        tutor: state.tutor,
        setTutor,
        // modal
        menuModal: state.menuModal,
        setMenuModal,
        show_modal: state.show_modal,
        setShowModal,
        // get user
        user: state.user,
        getUser,
        // departments menu
        networksMemberMenu: state.networksMemberMenu,
        setNetworksMemberMenu,
        networksMenu: state.networksMenu,
        setNetworksMenu,
        liberationMenu: state.liberationMenu,
        setLiberationMenu,
        intersetionMenu: state.intersetionMenu,
        setIntersetionMenu,
        evangelismMenu: state.evangelismMenu,
        setEvangelismMenu,
        visitsMenu: state.visitsMenu,
        setVisitsMenu,
        afirmationMenu: state.afirmationMenu,
        setAfirmationMenu,
        educationMenu: state.educationMenu,
        setEducationMenu,
        //
        departments: state.departments,
        getDepartments,
        membersByDepartment: state.membersByDepartment,
        getMembersByDepartment,
        directorsByApartment: state.directorsByApartment,
        getDirectorsByDepartment,
        // members
        membersToBeStudents: state.membersToBeStudents,
        getMembersToBeStudents,
        availableMembers: state.availableMembers,
        getAvailableMembers,
      }}
    >
      {children}
    </DeptoContext.Provider>
  );
};
export default DepartmentState;
