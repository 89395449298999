/*
  Página para listar todos los usuarios registrados en la plataforma (members)
*/
import axios from "axios";
import { useEffect, useState, useContext } from "react";

import DeptoContext from "../context/Departments/Context";
import Layout from "../components/Layout";
import { fonts } from "../assets/styles";
import HeaderMenu from "../components/admin/HeaderMenu";

export default function AdminUsers() {
  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    try {
      const { data } = await axios.get("/api/v1/members/");
      data.sort((a, b) => a.name.localeCompare(b.name));
      setUsers(data);
    } catch (error) {}
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Layout>
      <div
        className=" mms:pb-[40px]
        w-auto h-full bg-bg overflow-y-auto "
      >
        <HeaderMenu />
        <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px] w-full h-auto ">
          {/* title */}
          <div className=" mb-4 text-center w-full ">
            <h1 className="text-title text-32px">Usuarios</h1>
          </div>

          {/* content */}
          <div className=" mms:px-[10px] mms:py-[20px] mm:px-[20px] ml:px-[40px] w-full min-h-[450px] h-auto bg-bg2 rounded-[10px] ">
            {users.length > 0 ? (
              users.map((user) => <CardUserList user={user} key={user.id} />)
            ) : (
              <div className=" w-full text-content text-center ">
                <p>No hay usuarios</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

const CardUserList = ({ user }) => {
  const { setShowModal, setMenuModal, setAdminUserDetails } =
    useContext(DeptoContext);
  return (
    <div
      className=" mms:flex-col mms:items-start mms:py-[10px] mms:h-auto mms:px-[10px] mm:px-[20px] ml:px-[40px]
      ll:flex-row ll:items-center ll:h-[65px] flex justify-between items-center mx-auto mb-6  w-full bg-bg3 rounded-[10px]"
    >
      <div className=" mms:mb-2 ll:mb-0 ">
        <p className={` text-title ${fonts.heading3}`}>
          {user.name} {user.lastname}
        </p>
      </div>
      <div>
        <button
          onClick={() => {
            setAdminUserDetails(user);
            setMenuModal("ADMIN-USER-DETAILS");
            setShowModal(true);
          }}
          className="h-[36px] px-[10px] text-base bg-primary text-white rounded"
        >
          Detalles
          <i className=" ml-2 fa-solid fa-arrow-up-right-from-square"></i>
        </button>
      </div>
    </div>
  );
};
