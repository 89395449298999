import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useNotifications } from "reapop";

import DeptoContext from "../../../../context/Departments/Context";
import { fonts } from "../../../../assets/styles";

export default function NetworksMenuDiscipleshipsAttendance() {
  const { selectedNetwork, setNetworksMemberMenu, selectedDiscipleship } =
    useContext(DeptoContext);
  const [cdps, setCdps] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [confirmedDate, setConfirmedDate] = useState(false);
  const [idDiscipleshipMeet, setIdDiscipleshipMeet] = useState(null);

  const { notify } = useNotifications();

  // confirm discipleship date
  const handleConfirmDiscipleshipDate = async () => {
    if (selectedDate === "") {
      return notify({
        title: "Aviso",
        message: "Seleccione una fecha",
        status: "warning",
      });
    }
    const { data } = await axios.post(`/api/v1/discipleship_meets`, {
      date: selectedDate,
      id_discipleship: selectedDiscipleship.id,
    });
    if (data.status_code === 201) {
      setConfirmedDate(true);
      sessionStorage.setItem(
        "id_discipleship_meet",
        JSON.stringify(data.id_discipleship_meet)
      ); // save id_discipleship_meet in localStorage to use it to mark attendance
      setIdDiscipleshipMeet(data.id_discipleship_meet);
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Advertencia",
        message: data.message,
        status: "warning",
      });
    }
  };

  const getDiscipleshipsMeetById = async (iddiscipleshipmeet) => {
    const { data } = await axios.get(
      `/api/v1/discipleship_meet/${iddiscipleshipmeet}`
    );
    const fechaHora = new Date(data.date);
    const fechaSolo = fechaHora.toISOString().split("T")[0];
    setSelectedDate(fechaSolo);
  };

  const getCdpsByDiscipleshipsId = async () => {
    const { data } = await axios.get(
      `/api/v1/cdps/discipleship/${selectedDiscipleship.id}`
    );
    setCdps(data);
  };
  // load networks once
  useEffect(() => {
    if (sessionStorage.getItem("id_discipleship_meet")) {
      const iddiscipleshipmeet = JSON.parse(
        sessionStorage.getItem("id_discipleship_meet")
      );
      setIdDiscipleshipMeet(iddiscipleshipmeet);
      getDiscipleshipsMeetById(iddiscipleshipmeet);
      setConfirmedDate(true);
    }

    getCdpsByDiscipleshipsId();
  }, []);

  return (
    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px] px-[80px] py-[20px] w-full h-full overflow-y-auto">
      <div className=" my-4 text-center bg-bg ">
        <h1 className={` ${fonts.heading4} uppercase `}>Asistencias</h1>
        {/* <p className=" text-content ">Próximos retiros</p> */}
      </div>

      {/* brearcrumbs */}
      <div className=" flex mb-2 w-full bg-bg ">
        <button
          onClick={() => {
            setNetworksMemberMenu({
              menu: "NETWORKS",
            });
            sessionStorage.removeItem("id_discipleship_meet");
          }}
          className=" text-title "
        >
          {selectedNetwork?.name}
        </button>
        <p className=" mx-1 text-content ">{" > "}</p>
        <button
          onClick={() => {
            setNetworksMemberMenu({
              menu: "NETWORKS-DISCIPLESHIPS",
            });
            sessionStorage.removeItem("id_discipleship_meet");
          }}
          className=" text-title "
        >
          {selectedDiscipleship?.leader_one_fullname}{" "}
          {selectedDiscipleship?.leader_two_fullname &&
            " & " + selectedDiscipleship?.leader_two_fullname}
        </button>
      </div>

      <div className=" mms:flex-col ll:flex-row flex justify-between w-full h-[550px] bg-bg2 rounded-[10px] overflow-y-auto ">
        {/* discipleship meet */}
        <div className=" mms:w-full p-[20px] w-[45%] ">
          <div className=" ">
            <div className=" mb-4 w-full text-Red border-b border-Red ">
              <strong>Nota:</strong>
              <small className="  ml-1 text-Red">
                Solo podrá marcar asistencias de la fecha que seleccione y cree
                antes de cambiar o cerrar esta página. Si desea marcar
                asistencias nuevamente, tendrá que crear una nueva fecha de
                reunión de discipulado.
              </small>
            </div>
            <p className=" mb-2 text-content ">
              Seleccionar fecha de la reunión{" "}
              <span className=" text-Red ">*</span>
            </p>
            <input
              type="date"
              className=" px-[10px] w-[200px] h-[44px] bg-bg3 rounded outline-none "
              onChange={(e) => setSelectedDate(e.target.value)}
              value={selectedDate}
            />
          </div>
          <div className=" mt-6 ">
            {selectedDate ? (
              <>
                <p className=" text-title ">
                  ¿Marcar asistencia del discipulado con fecha?:
                </p>
                <p className=" text-content ">
                  {selectedDate}
                  {confirmedDate && (
                    <i className=" ml-2 text-Green fa-solid fa-check-circle"></i>
                  )}
                </p>
              </>
            ) : (
              <p className=" text-content ">
                Seleccione un fecha para marcar asistencia de discipulado.
              </p>
            )}
            {confirmedDate && (
              <p className=" mt-6 text-primary ">
                Marque la asistencia de sus casas de paz{" "}
                <i className=" fa-solid fa-long-arrow-right"></i>
              </p>
            )}
          </div>
          {!confirmedDate && selectedDate && (
            <div className=" mt-6 ">
              <button
                onClick={handleConfirmDiscipleshipDate}
                className=" px-[10px] h-[44px] bg-primary rounded "
              >
                Confirmar
              </button>
            </div>
          )}
          {confirmedDate && (
            <div className=" mt-6 ">
              <button
                onClick={() => {
                  setSelectedDate("");
                  setConfirmedDate(false);
                }}
                className=" px-[10px] h-[44px] bg-options rounded "
              >
                Nueva fecha
              </button>
            </div>
          )}
        </div>

        {/* cdps list */}
        <div className=" mms:w-full pt-0 w-[55%] ll:border-l border-options ">
          <div className=" flex justify-center items-center px-[20px] h-[40px] text-center text-content bg-bg3 ">
            <small>Casas de Paz de este discipulado</small>
          </div>
          <div className=" p-[20px] h-[calc(100%-50px)] overflow-y-auto ">
            {cdps.length > 0 ? (
              cdps.map((cdp) => (
                <CdpCard
                  cdp={cdp}
                  id_discipleship_meet={idDiscipleshipMeet}
                  key={cdp.id}
                />
              ))
            ) : (
              <div className=" text-center text-content ">
                <p>Sin Casas de Paz</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const CdpCard = ({ cdp, id_discipleship_meet }) => {
  const { notify } = useNotifications();

  const handleCdpAttendance = async (id_cdp, attended) => {
    const { data } = await axios.post(`/api/v1/discipleship_meet_attendance`, {
      id_discipleship_meet,
      id_cdp,
      attended,
    });
    if (data.status_code === 201) {
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
  };

  return (
    <div
      className=" mms:flex-col mms:items-start tm:flex-row tm:items-center tl:flex-col tl:items-start txl:flex-row txl:items-start
     ll:flex-col lxl:flex-row flex justify-between mb-[20px] p-[10px] w-full h-auto bg-bg3 rounded-[10px]"
    >
      <div className=" mms:flex-col mms:items-start mms:mb-2 tm:mb-0 tl:mb-2 txl:mb-0 ll:mb-2 lxl:mb-0 flex ">
        <p className=" text-content "> - {cdp.leader_one_fullname}</p>
        {cdp.leader_two_fullname && (
          <p className=" mms:ml-0 ml-2 text-content ">
            {" "}
            - {cdp.leader_two_fullname}
          </p>
        )}
      </div>
      {id_discipleship_meet && (
        <div className=" mms:flex mms:w-full mm:flex-row ts:items-center ts:w-[220px] flex-col ">
          <button
            onClick={() => {
              handleCdpAttendance(cdp.id, true);
            }}
            className={` mms:w-full mm:w-auto px-[10px] h-[32px] bg-Green text-white ${fonts.base} rounded `}
          >
            Asistio
            <i className=" ml-2 fa-solid fa-check-circle"></i>
          </button>
          <button
            onClick={() => {
              handleCdpAttendance(cdp.id, false);
            }}
            className={` mms:ml-0 mms:mt-2 mms:w-full mm:w-auto mm:mt-0 mm:ml-2 px-[10px] h-[32px] bg-Red text-black ${fonts.base} rounded `}
          >
            No asistio
            <i className=" ml-2 fa-solid fa-times-circle"></i>
          </button>
        </div>
      )}
    </div>
  );
};
