import axios from "axios";
import { useState, useContext } from "react";
import { useNotifications } from "reapop";

import DeptoContext from "../../context/Departments/Context";
import { fonts } from "../../assets/styles";

export default function NewConvertHelpRequestDetailsModal() {
  const [showPetitionPrayForm, setShowPetitionPrayForm] = useState(false);
  const [petition, setPetition] = useState(""); // if showPetitionPrayForm is false

  const {
    setShowModal,
    selectedStudentToPetition: { student, depto },
  } = useContext(DeptoContext);

  // notifications
  const { notify } = useNotifications();

  const onSubmit = async () => {
    if (petition.length === 0) {
      notify({
        title: "Aviso",
        message: "Ingrese toda la información por favor",
        status: "warning",
      });
      return;
    }
    if (!showPetitionPrayForm) {
      makeHelpRequest();
    } else {
      makePrayPetition();
    }
  };

  const makeHelpRequest = async () => {
    const { name, lastname } = JSON.parse(localStorage.getItem("user_data"));
    const helpRequest = {
      for_fullname: student.name + " " + student.lastname,
      for_phone: student.phone,
      made_by_fullname: name + " " + lastname,
      made_by_phone: JSON.parse(localStorage.getItem("user_data")).phone,
      depto_from: depto,
      request: petition,
    };
    const { data } = await axios.post("/api/v1/help_request", helpRequest);
    if (data.status_code === 201) {
      setPetition("");
      setShowModal(false);
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
  };

  // send pray petition
  const makePrayPetition = async () => {
    const { name, lastname, phone } = JSON.parse(
      localStorage.getItem("user_data")
    );
    const prayPetition = {
      for_fullname: student.name + " " + student.lastname,
      for_phone: student.phone,
      for_id_new_convert: student.id,
      made_by_fullname: name + " " + lastname,
      made_by_phone: phone,
      depto_from: depto,
      petition: petition,
    };
    const { data } = await axios.post(
      "/api/v1/only/pray_petitions/",
      prayPetition
    );
    if (data.status_code === 201) {
      setPetition("");
      setShowModal(false);
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
  };

  return (
    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] w-full h-full overflow-y-auto ">
      <div className="mb-8">
        {/* title */}
        <div className=" mb-2 text-center">
          <h1
            className={` mms:text-[24px] ts:text-[32px  ] mb-1 ${fonts.heading4} text-title`}
          >
            Detalles del estudiante
          </h1>
        </div>

        {/* student info */}
        <div className=" mms:flex-col flex justify-between items-center mb-6 px-[20px] py-[10px] w-full h-[200px] bg-bg3 rounded-[10px] overflow-y-auto ">
          <div className=" mms:w-full w-1/2">
            <div className=" w-full">
              <p className=" text-title">Nombre completo:</p>
              <p className=" text-content">
                {student.name} {student.lastname}
              </p>
            </div>
            <div className=" my-4 w-full">
              <p className=" text-title">Teléfono:</p>
              <p className=" text-content">{student.phone}</p>
            </div>
            <div className=" w-full">
              <p className=" text-title">Dirección:</p>
              <p
                className=" text-content overflow-hidden whitespace-nowrap text-ellipsis"
                title={student.address}
              >
                {student.address}
              </p>
            </div>
          </div>

          <div className=" mms:mt-2 mms:w-full w-1/2 ">
            <div className=" w-full">
              <p className=" text-title">Conexión:</p>
              <p className=" text-content">{student.conexion}</p>
            </div>
            <div className=" my-4 w-full">
              <p className=" text-title">Genero:</p>
              <p className=" text-content">
                {student.is_man ? "Masculino" : "Femenino"}
              </p>
            </div>
            <div className=" w-full">
              <p className=" text-title">Estado civil:</p>
              <p className=" text-content">
                {student.is_single ? "Soltero (a)" : "Casado (a)"}
              </p>
            </div>
          </div>
        </div>

        {/* petition form */}
        <div className="p-[20px] w-full h-[280px] bg-bg3 rounded-[10px] overflow-y-auto">
          {/* show people to be student */}
          {/* <div className=" w-full mb-[10px] pb-[0px] h-auto overflow-x-auto "> */}
          <div className=" mb-2 text-lg text-subtitle ">
            <p>Solicitar:</p>
          </div>
          <div className=" mms:mb-2 mms:bg-bg3 ml:px-0 mm:bg-bg2 flex justify-start items-center h-auto overflow-x-auto rounded-[25px] ml:max-w-[350px] ">
            <div className=" flex items-center mms:w-full h-[32px] bg-[#D9D9D9] text-content rounded-[25px] ">
              <button
                onClick={() => setShowPetitionPrayForm(false)}
                className={` mms:w-[50%] h-full ${
                  !showPetitionPrayForm
                    ? " bg-primary text-white "
                    : " bg-[#d9d9d9] text-content"
                } ${fonts.body1} rounded-[25px] outline-none`}
              >
                Apoyo
              </button>
              <button
                onClick={() => setShowPetitionPrayForm(true)}
                className={` mms:w-[50%] h-full ${
                  showPetitionPrayForm
                    ? " bg-primary text-white "
                    : " bg-[#d9d9d9] text-content"
                } ${fonts.body1} rounded-[25px] outline-none`}
              >
                Oración
              </button>
            </div>
          </div>
          {/* form */}
          <div
            // onSubmit={handleSubmit(onSubmit)}
            className=" p-[0px] py-0 w-full h-auto"
          >
            <div className=" mt-0 w-full">
              <label className="text-subtitle block mb-2">
                {showPetitionPrayForm
                  ? "Petición de oración"
                  : "Solicitud de apoyo"}{" "}
                <span className="text-Red">*</span>
              </label>
              <textarea
                className=" indent-2 w-full h-[100px] bg-bg4 text-content outline-none rounded "
                onChange={(e) => setPetition(e.target.value)}
                maxLength="1024"
              ></textarea>
            </div>

            <div className={` mt-2 text-start w-full`}>
              <button
                onClick={onSubmit}
                className="bg-primary text-white px-[20px] w-auto h-[44px] rounded "
              >
                Enviar
                <i className=" ml-3 fa-solid fa-paper-plane"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
