import axios from "axios";
import { useEffect, useState, useContext } from "react";

import { SELECTED_DISCIPLESHIP } from "../../../../context/types";

import DeptoContext from "../../../../context/Departments/Context";
// import "./styles.css";

export default function CommonDiscipleshipsList({ member = false }) {
  const [networks, setNetworks] = useState([]);
  const [discipleships, setDiscipleships] = useState(null);
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [showRedOptions, setShowRedOptions] = useState(false);

  const getAllDiscipleships = async (networkId) => {
    const { data } = await axios.get(
      `/api/v1/discipleships/network/${networkId}`
    );
    data.sort((a, b) =>
      a.leader_one_fullname.localeCompare(b.leader_one_fullname)
    );
    setDiscipleships(data);
  };

  const getAllNetworks = async () => {
    const { data } = await axios.get("/api/v1/networks");
    setNetworks(data);
  };
  // load networks once
  useEffect(() => {
    getAllNetworks();
  }, []);

  return (
    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] ll:px-[80px] pb-[20px] w-full h-full bg-bg overflow-y-auto ">
      {/* title */}
      <div className=" my-4 text-center bg-bg ">
        <h1 className={` mms:text-[24px] ts:text-[32px] text-title uppercase `}>
          Discipulados de Red
        </h1>
      </div>

      {/* filter */}
      <div className=" mb-2 w-full ">
        <div className=" w-[200px] ">
          <p className=" mb-1 text-content ">Mostrar por Red:</p>
          <SelectOptions
            title={selectedNetwork}
            noTitle="Seleccione una red"
            items={networks}
            setSelection={setSelectedNetwork}
            getAllDiscipleships={getAllDiscipleships}
            showOptions={showRedOptions}
            setShowOptions={setShowRedOptions}
          />
        </div>
      </div>

      {/* content */}
      <div className=" mms:px-[10px] mm:px-[20px] py-[20px] w-full min-h-[450px] h-auto bg-bg2 rounded-[10px] ">
        {discipleships === null ? (
          <div className=" text-center text-content ">
            <p>Seleccione un red</p>
          </div>
        ) : discipleships.length > 0 ? (
          discipleships.map((discipleship) => (
            <DiscipleshipCard
              discipleship={discipleship}
              key={discipleship.id}
            />
          ))
        ) : (
          <div className=" text-center text-content ">
            <p>No hay discipulados en esta red</p>
          </div>
        )}
      </div>
    </div>
  );
}

const DiscipleshipCard = ({ discipleship }) => {
  const { setSelectedDiscipleship, setMenuModal, setShowModal } =
    useContext(DeptoContext);

  return (
    <div className=" mms:flex-col mms:items-start tm:flex-row tm:items-center flex justify-between mb-[20px] p-[10px] w-full h-auto bg-bg3 rounded ">
      <div className=" mms:flex-col mms:mb-2 ts:flex-row flex border-options">
        <span className=" mr-2 text-content ">Líder (es): </span>
        <p className=" text-content">{discipleship.leader_one_fullname}</p>
        {discipleship.leader_two_fullname && (
          <p className=" mms:ml-0 ml-2 text-content ">
            <span className=" mms:hidden ts:inline mx-1">&</span>
            {discipleship.leader_two_fullname}
          </p>
        )}
      </div>
      <div className=" border-options">
        <button
          onClick={() => {
            setSelectedDiscipleship(discipleship);
            setMenuModal(SELECTED_DISCIPLESHIP);
            setShowModal(true);
          }}
          className=" px-[10px] h-[32px] bg-[#202020] text-title rounded "
        >
          Detalles
          <i className=" ml-2 fa-solid fa-up-right-from-square"></i>
        </button>
      </div>
    </div>
  );
};

const SelectOptions = ({
  title,
  noTitle,
  items = [],
  setSelection,
  getAllDiscipleships,
  showOptions,
  setShowOptions,
}) => {
  return (
    <div className=" w-full ">
      <div className="">
        <div className=" relative w-full bg-bg3 rounded">
          <button
            type="button"
            onClick={() => setShowOptions(!showOptions)}
            className="flex justify-between items-center px-4 w-full h-[44px] text-content"
          >
            {title ? (
              <>
                <span className=" text-white">{title.name}</span>
                <i className="text-white fa-solid fa-angle-down"></i>
              </>
            ) : (
              <>
                <span className=" text-content">{noTitle}</span>
                <i className="text-content fa-solid fa-angle-down"></i>
              </>
            )}
          </button>
          <div
            className={`absolute top-[calc(44px+10px)] w-full bg-bg2 border-b border-x border-bg3 shadow-lg
                  ${showOptions ? "block" : "hidden"}
                `}
          >
            {items.length > 0 ? (
              items.map((item, i) => (
                <button
                  type="button"
                  onClick={() => {
                    setSelection(item);
                    getAllDiscipleships(item.id);
                    setShowOptions(false);
                  }}
                  className="flex items-center px-2 w-full h-[50px] text-content hover:text-title hover:bg-bg3 "
                  key={i}
                >
                  {item.name}
                </button>
              ))
            ) : (
              <div className=" flex items-center justify-center h-[32px] text-content ">
                <small>No hay opciones</small>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
