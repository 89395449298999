import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNotifications } from "reapop";
import { fonts } from "../../../../assets/styles";

const schema = yup.object({
  address: yup
    .string()
    .required("Ingrese una dirección por favor.")
    .max(64, "Máximo 64 carácteres"),
});

export default function AfirmationCreateCDP() {
  const [allMembers, setAllMembers] = useState([]);
  const [cdpLeaderOne, setCdpLeaderOne] = useState(null);
  const [cdpLeaderTwo, setCdpLeaderTwo] = useState(null);
  const [showGetMembers, setShowGetMembers] = useState(false); // to filter people to be disciples

  const [peopleList, setPeopleList] = useState([]); // to save the people to be students

  const { notify } = useNotifications();

  const inputCodeRef = useRef();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  useEffect(() => {
    localStorage.getItem("cdpLeaderOne") &&
      setCdpLeaderOne(JSON.parse(localStorage.getItem("cdpLeaderOne")));
    localStorage.getItem("cdpLeaderTwo") &&
      setCdpLeaderTwo(JSON.parse(localStorage.getItem("cdpLeaderTwo")));
  }, [setCdpLeaderOne, setCdpLeaderTwo]);

  const onSubmit = async (formdata) => {
    if (!cdpLeaderOne) {
      notify({
        title: "Aviso",
        message: "Por favor seleccione mínimo un líder 1 para la casa de paz.",
        status: "warning",
      });
      return;
    }

    const cdp_data = {
      leader_one_code: cdpLeaderOne.code,
      leader_one_fullname: `${cdpLeaderOne.name} ${cdpLeaderOne.lastname}`,
      leader_one_phone: cdpLeaderOne.phone,
      address: formdata.address,
      day: formdata.day,
      hour: formdata.hour,
    };
    if (cdpLeaderTwo) {
      cdp_data.leader_two_code = cdpLeaderTwo.code;
      cdp_data.leader_two_fullname = `${cdpLeaderTwo.name} ${cdpLeaderTwo.lastname}`;
      cdp_data.leader_two_phone = cdpLeaderTwo.phone;
    }

    try {
      const { data } = await axios.post("/api/v1/cdps/", cdp_data);
      if (data.status_code === 201) {
        reset();
        getAllMembers();
        // clear leader 1 and 2 localstorage
        localStorage.removeItem("cdpLeaderOne");
        localStorage.removeItem("cdpLeaderTwo");
        setCdpLeaderOne(null);
        setCdpLeaderTwo(null);

        notify({
          title: "Éxito",
          message: data.message,
          status: "success",
        });
      } else {
        notify({
          title: "Aviso",
          message: data.message,
          status: "warning",
        });
      }
    } catch (error) {
      notify({
        title: "¡Error!",
        message:
          "Ha habido un error en el servidor. Intente nuevamente por favor.",
        status: "error",
      });
    }
  };

  // get new convert by code
  const getNewConvertByCode = async (code) => {
    // code is the input value "name"
    if (code.length > 0) {
      let datos = [];
      if (showGetMembers) {
        // get members
        const { data } = await axios.get(`/api/v1/members/search/${code}`);
        datos = data;
      } else {
        // get new converts
        const { data } = await axios.get(
          `/api/v1/new_convert/filter/name/${code}`
        );
        datos = data;
      }
      setPeopleList(datos);
      // setPeopleList(Object.keys(datos).length > 0 ? [datos] : []);
    } else {
      setPeopleList([]);
      getAllMembers();
    }
  };

  const getAllMembers = async () => {
    if (showGetMembers) {
      const { data } = await axios.get("/api/v1/members/cdp_leaders"); // all members (including leaders but not disciples)
      data.sort((a, b) => a.name.localeCompare(b.name));
      setAllMembers(data);
    } else {
      const { data } = await axios.get("/api/v1/new_converts/cdp_leaders");
      data.sort((a, b) => a.name.localeCompare(b.name));
      setAllMembers(data);
    }
  };
  useEffect(() => {
    if (inputCodeRef.current.value.length === 0) {
      getAllMembers();
    } else {
      getNewConvertByCode(inputCodeRef.current.value);
    }
  }, [showGetMembers]);

  return (
    // container
    <div
      className=" mms:px-[10px] mms:py-[20px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px] 
     px-[80px] w-auto h-full overflow-y-auto "
    >
      {/* content */}
      {/* title */}
      <div className=" mb-4 text-center w-full ">
        <h1 className={` mms:text-[24px] ts:text-[32px] text-title uppercase`}>
          Crear casa de paz
        </h1>
      </div>

      <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] py-[20px] min-h-[500px] max-h-auto w-full bg-bg2 rounded-[10px] overflow-y-auto">
        {/* leaders cdp */}
        <div className=" mms:flex-col-reverse ll:flex-row flex justify-between">
          {/* cdp info */}
          <div className=" mms:w-full ll:w-[40%]">
            <div className="mb-8">
              <p className="mb-1 text-title">Líder (es)</p>
              <div className=" flex flex-col justify-around px-[20px] py-[10px] w-full h-[100px] bg-bg3 rounded overflow-y-auto">
                <div className=" flex justify-between mb-2 ">
                  <div className=" text-title">
                    <span className="text-subtitle">Líder 1</span>
                    {cdpLeaderOne ? (
                      <span className=" ml-2 text-white">
                        {cdpLeaderOne.name} {cdpLeaderOne.lastname}
                      </span>
                    ) : (
                      <span className=" ml-2 text-content">
                        No seleccionado
                      </span>
                    )}
                  </div>
                  {cdpLeaderOne && (
                    <button
                      onClick={() => {
                        localStorage.removeItem("cdpLeaderOne");
                        setCdpLeaderOne(null);
                      }}
                      className=" px-[10px] w-auto bg-Red rounded "
                    >
                      Quitar
                    </button>
                  )}
                </div>
                <div className=" flex justify-between ">
                  <div className=" text-title">
                    <span className=" text-subtitle">Líder 2</span>
                    {cdpLeaderTwo ? (
                      <span className=" ml-2 text-white">
                        {cdpLeaderTwo.name} {cdpLeaderTwo.lastname}
                      </span>
                    ) : (
                      <span className=" ml-2 text-content">
                        No seleccionado
                      </span>
                    )}
                  </div>
                  {cdpLeaderTwo && (
                    <button
                      onClick={() => {
                        localStorage.removeItem("cdpLeaderTwo");
                        setCdpLeaderTwo(null);
                      }}
                      className=" px-[10px] w-auto bg-Red rounded"
                    >
                      Quitar
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* form */}
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" pt-0 w-full h-auto"
            >
              {/* Address */}
              <div className=" mb-6 w-full">
                <label className="text-subtitle block mb-2">
                  Dirección <span className="text-Red">*</span>
                </label>{" "}
                <input
                  className=" indent-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
                  {...register("address")}
                  maxLength={64}
                />
                {
                  <small className="text-red-500">
                    {errors.address?.message}
                  </small>
                }
              </div>

              {/* CDP Day/Hour */}
              <div className=" mms:flex-col mms:items-start flex justify-between mb-8">
                <div className=" mms:w-full mms:mb-6 w-[45%]">
                  <label className="text-subtitle mb-2 block">
                    Día de realización <span className="text-Red">*</span>
                  </label>
                  <select
                    className=" px-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
                    defaultValue=""
                    {...register("day", { required: true })}
                  >
                    <option value="Lunes">Lunes</option>
                    <option value="Martes">Martes</option>
                    <option value="Miércoles">Miércoles</option>
                    <option value="Jueves">Jueves</option>
                    <option value="Viernes">Viernes</option>
                    <option value="Sábado">Sábado</option>
                    <option value="Domingo">Domingo</option>
                  </select>
                  <small className="text-red-500">{errors.day?.message}</small>
                </div>

                <div className=" mms:w-full mms:mb-6 w-[45%]">
                  <label className="text-subtitle mb-2 block">
                    Hora de realización <span className="text-Red">*</span>
                  </label>
                  <select
                    className=" px-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
                    {...register("hour", { required: true })}
                  >
                    <option value="08:00 AM">08:00 AM</option>
                    <option value="09:00 AM">09:00 AM</option>
                    <option value="10:00 AM">10:00 AM</option>
                    <option value="11:00 AM">11:00 AM</option>
                    <option value="12:00 PM">12:00 PM</option>
                    <option value="13:00 PM">01:00 PM</option>
                    <option value="14:00 PM">02:00 PM</option>
                    <option value="15:00 PM">03:00 PM</option>
                    <option value="16:00 PM">04:00 PM</option>
                    <option value="17:00 PM">05:00 PM</option>
                    <option value="18:00 PM">06:00 PM</option>
                    <option value="19:00 PM">07:00 PM</option>
                    <option value="20:00 PM">08:00 PM</option>
                    <option value="21:00 PM">09:00 PM</option>
                    <option value="22:00 PM">10:00 PM</option>
                    <option value="23:00 PM">11:00 PM</option>
                  </select>
                  <small className="text-red-500">{errors.hour?.message}</small>
                </div>
              </div>

              <div className=" flex justify-center mt-8 w-full">
                <button className="bg-primary text-white w-full h-[44px] rounded uppercase">
                  Crear
                </button>
              </div>
            </form>
          </div>

          {/* members-new-converts */}
          <div className=" mms:w-full ll:w-[55%] h-full">
            <div className="mb-8">
              {/* show people to be student */}
              <div className=" mms:mb-2 mms:bg-bg3 ml:px-0 mm:bg-bg2 flex justify-start items-center h-auto overflow-x-auto rounded-[25px] ml:max-w-[350px] ">
                <div className=" flex items-center px-[2px] mms:w-full w-[300px] h-[32px] bg-[#D9D9D9] text-content rounded-[25px] ">
                  <button
                    onClick={() => setShowGetMembers(false)}
                    className={` mms:w-[50%] h-[30px] ${
                      !showGetMembers
                        ? " bg-primary text-white "
                        : " bg-[#d9d9d9] text-content"
                    } rounded-[25px] outline-none`}
                  >
                    Creyentes
                  </button>
                  <button
                    onClick={() => setShowGetMembers(true)}
                    className={` mms:w-[50%] h-[30px] ${
                      showGetMembers
                        ? " bg-primary text-white "
                        : " bg-[#d9d9d9] text-content"
                    } rounded-[25px] outline-none`}
                  >
                    Usuarios
                  </button>
                </div>
              </div>

              {/* filter */}
              <div className=" mb-2 h-[44px] bg-bg2 ">
                <div className=" mb-[20px] h-[44px] ">
                  <div className=" flex justify-between w-full h-[44px] border-b border-bg ">
                    <input
                      type="text"
                      onChange={(e) => getNewConvertByCode(e.target.value)}
                      ref={inputCodeRef}
                      placeholder={`Ingrese nombre de ${
                        showGetMembers ? "Usuario" : "Nuevo Creyente"
                      }`}
                      className=" indent-3 w-[calc(100%-44px)] h-full bg-bg3 outline-none "
                    />
                    <div
                      onClick={() => {
                        inputCodeRef.current.value = "";
                        getAllMembers();
                      }}
                      className=" flex justify-center items-center w-[44px] h-full bg-bg cursor-pointer"
                    >
                      <i className=" text-[24px] fa-solid fa-times"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" mms:px-[10px] mm:px-[20px] py-[20px] w-full h-[400px] max-h-[400px] bg-bg3 rounded-[10px] overflow-y-auto">
                {inputCodeRef.current?.value.length === 0 && (
                  <>
                    {allMembers.length > 0 ? (
                      allMembers.map((member) => (
                        <UserCardList
                          {...{
                            member,
                            setCdpLeaderOne,
                            setCdpLeaderTwo,
                            showGetMembers,
                          }}
                          key={member.id}
                        />
                      ))
                    ) : (
                      <div className=" text-center text-content ">
                        <p>No hay usuarios disponibles</p>
                      </div>
                    )}
                  </>
                )}

                {/* si hay contenido en el input del filtro  */}
                {inputCodeRef.current?.value?.length > 0 && (
                  <>
                    {peopleList.length > 0 ? (
                      peopleList.map((user) => (
                        <UserCardList
                          member={user}
                          {...{
                            setCdpLeaderOne,
                            setCdpLeaderTwo,
                            showGetMembers,
                          }}
                          key={user.id}
                        />
                      ))
                    ) : (
                      <div className=" text-center text-content ">
                        <p className="text-content">Sin coincidencia.</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// components userList
const UserCardList = ({ member, setCdpLeaderOne, setCdpLeaderTwo }) => {
  if (
    member?.phone === JSON.parse(localStorage.getItem("cdpLeaderOne"))?.phone ||
    member?.phone === JSON.parse(localStorage.getItem("cdpLeaderTwo"))?.phone
  ) {
    return;
  }

  return (
    <div
      className=" mms:flex-col mms:items-start mms:py-[10px] mms:h-auto tm:flex-row tm:items-center ll:flex-col ll:items-start lxl:flex-row lxl:items-center
    flex justify-between items-center h-[56px] border-b border-options"
    >
      <div>
        <p className={`text-subtitle`}>
          {member.name} {member.lastname}
        </p>
        <div className={` mms:mb-2 flex ${fonts.body2} `}>
          {member.is_cdp_leader && (
            <p className="mr-2 text-Green ">- Es líder</p>
          )}
          {member.is_cdp_disciple && (
            <p className=" text-third ">- Es Discipulo</p>
          )}
        </div>
      </div>

      <div>
        {!localStorage.getItem("cdpLeaderOne") && (
          <button
            onClick={() => {
              localStorage.setItem("cdpLeaderOne", JSON.stringify(member));
              setCdpLeaderOne(member);
            }}
            className=" mr-[10px] w-[80px] h-[28px] bg-primary rounded-[5px]"
          >
            Líder 1
          </button>
        )}

        {!localStorage.getItem("cdpLeaderTwo") && (
          <button
            onClick={() => {
              localStorage.setItem("cdpLeaderTwo", JSON.stringify(member));
              setCdpLeaderTwo(member);
            }}
            className="w-[95px] h-[28px] bg-primary rounded-[5px]"
          >
            Líder 2
          </button>
        )}
      </div>
    </div>
  );
};
