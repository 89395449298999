import axios from "axios";
import { useEffect, useState, useContext, useRef } from "react";
import { useSearchParams } from "react-router-dom";

import DeptoContext from "../../../context/Departments/Context";
import { fonts } from "../../../assets/styles";

export default function ListPeopleToHelpRequest({ breadCrumbs = null }) {
  const { setEvangelismMenu } = useContext(DeptoContext);
  const [newConverts, setNewConverts] = useState([]);

  const inputCodeRef = useRef();

  const getNewConvertByCode = async (code) => {
    if (code.length > 0) {
      const { data } = await axios.get(
        `/api/v1/new_convert/filter/name/${code}`
      );
      data.sort((a, b) => a.name.localeCompare(b.name));
      // const { data } = await axios.get(
      //   `/api/v1/new_convert/filter/code/${code}/`
      // );
      // setNewConverts(
      //   Object.keys(data.new_convert).length > 0 ? [data.new_convert] : null
      // );
      setNewConverts(data);
    } else {
      getNewConverts();
    }
  };

  const getNewConverts = async () => {
    const { data } = await axios.get("/api/v1/new_convert");
    data.sort((a, b) => a.name.localeCompare(b.name));
    setNewConverts(data);
  };
  useEffect(() => {
    getNewConverts();
  }, []);

  return (
    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] ll:px-[80px] h-full overflow-y-auto bg-bg ">
      {/* title */}
      <div className=" sticky top-0 my-4 text-center bg-bg">
        <h1 className={` mms:text-[24px] ts:text-[32px] text-title uppercase`}>
          Todos los creyentes
        </h1>
      </div>

      {/* filter */}
      <div className=" sticky top-[42px] bg-bg ">
        <div className=" flex justify-between mb-[20px] w-full h-[44px] bg-bg border-b border-bg ">
          <input
            type="text"
            onChange={(e) => getNewConvertByCode(e.target.value)}
            ref={inputCodeRef}
            placeholder="Buscar Nuevo Creyente por nombre"
            className=" indent-3 w-[calc(100%-44px)] h-full bg-bg3 placeholder-gray-500 outline-none "
          />
          <div
            onClick={() => {
              inputCodeRef.current.value = "";
              getNewConverts();
            }}
            className=" flex justify-center items-center w-[44px] h-full bg-bg2 cursor-pointer"
          >
            <i className=" text-[24px] fa-solid fa-times"></i>
          </div>
        </div>

        {/* brearcrumbs */}
        {breadCrumbs === "EVANGELISM" && (
          <div className=" w-full bg-bg">
            <p className=" text-content">
              <button
                onClick={() => {
                  localStorage.setItem(
                    "evangelism_menu",
                    JSON.stringify({
                      menu: "OPTIONS",
                    })
                  );
                  setEvangelismMenu();
                }}
                className=" text-white"
              >
                Menu
              </button>
              {" > "} Solicitud de apoyo
            </p>
          </div>
        )}
      </div>

      {/* new converst */}
      <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] py-[20px] mb-8 w-full h-auto bg-bg2 rounded-[10px] ">
        {newConverts !== null ? (
          newConverts.length > 0 ? (
            newConverts.map((newConvert) => (
              <CardNewBeliever newConvert={newConvert} key={newConvert.id} />
            ))
          ) : (
            <div className=" text-center text-content ">
              <span className="">No hay nuevos convertidos</span>
            </div>
          )
        ) : (
          <div className=" text-center text-content ">
            <span className=" ">No existe el código</span>
          </div>
        )}
      </div>
    </div>
  );
}

const CardNewBeliever = ({ newConvert }) => {
  const { setShowModal, setMenuModal, setSelectedStudentToPetition } =
    useContext(DeptoContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const depto = searchParams.get("depto");

  return (
    <div className=" mms:flex-col mms:items-start mms:py-[10px] mms:h-auto ts:flex-row ts:items-center flex justify-between mb-[32px] px-[20px] py-[10px] h-auto  bg-bg3 rounded-[10px]">
      <div className=" mms:mb-2 mms:w-full h-full ">
        <p className={`${fonts.heading3} text-primary`}>
          {newConvert.name} {newConvert.lastname}
        </p>
        <div
          className={` mms:flex-col ml:flex-row flex text-content ${fonts.body1}`}
        >
          <p className=" mr-1 text-title">Código: </p>
          <p>{newConvert.code}</p>
        </div>
      </div>

      <div className=" mms:justify-start flex justify-end items-center h-full ">
        <button
          onClick={() => {
            setSelectedStudentToPetition({
              student: newConvert,
              depto: depto,
            });
            setMenuModal("NEW-CONVERT-HELP-REQUEST-DETAILS");
            setShowModal(true);
          }}
          className=" flex justify-center items-center px-[10px] w-auto h-[40px] bg-primary rounded "
        >
          Detalle{" "}
          <i className=" ml-2 fa-solid fa-arrow-up-right-from-square"></i>{" "}
        </button>
      </div>
    </div>
  );
};
