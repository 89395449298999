/*
 * Esta pagina es para mostra un menu de opciones para el departamento de Redes y Discipulados
 */
import axios from "axios";
import { useEffect, useState, useContext } from "react";

import DeptoContext from "../../../context/Departments/Context";
import ProtectedDepartment from "../ProtectedDepartment";

// members
import NetworksMenuNetworksList from "./members/NetworksMenuNetworksList";
import NetworksMenuDiscipleshipByNetworkList from "./members/NetworksMenuDiscipleshipByNetworkList";
import NetworksMenuDiscipleshipsAttendance from "./members/NetworksMenuDiscipleshipsAttendance";
// admin
import NetworksHeaderAdmin from "./director/NetworksHeaderAdmin";
import NetworksIntegrants from "./director/NetworksIntegrants";
import NetworksCreateNetworks from "./director/NetworksCreateNetworks";
import NetworksListNetworks from "./director/NetworksListNetworks";
import NetworksCreateDiscipleship from "./director/NetworksCreateDiscipleship";
import NetworksDiscipleshipsList from "./director/NetworksDiscipleshipsList";
import NetworksDiscipleshipsAttendance from "./director/NetworksDiscipleshipsAttendance";

export default function NetworksContainer() {
  const { networksMemberMenu, setNetworksMenu, user } =
    useContext(DeptoContext);

  const [isNetworkDirector, setIsNetworkDirector] = useState(false);

  const changeMemberMenu = () => {
    switch (networksMemberMenu?.menu) {
      case "NETWORKS":
        return <NetworksMenuNetworksList />;
      case "NETWORKS-DISCIPLESHIPS":
        return <NetworksMenuDiscipleshipByNetworkList />;
      case "DISCIPLESHIPS-ATTENDANCE":
        return <NetworksMenuDiscipleshipsAttendance />;
      default:
        return <NetworksMenuNetworksList />;
    }
  };

  const changeAdminMenu = () => {
    switch (JSON.parse(localStorage.getItem("networks_menu"))?.menu) {
      case "INTEGRANTS":
        return <NetworksIntegrants />;
      case "CREATE-NETWORKS":
        return <NetworksCreateNetworks />;
      case "NETWORKS-ADMIN":
        return <NetworksListNetworks />;
      case "DISCIPLESHIPS":
        return <NetworksDiscipleshipsList />;
      case "CREATE-DISCIPLESHIP":
        return <NetworksCreateDiscipleship />;
      case "ATTENDANCES":
        return <NetworksDiscipleshipsAttendance />;
      default:
        return <NetworksIntegrants />;
    }
  };

  useEffect(() => {
    const currentMenu = JSON.parse(localStorage.getItem("networks_menu"))?.menu;

    // esto es para que cuando se cambie el menu en los miembros, se reinicie en el admin a "INTEGRANTS"
    if (user?.is_director) {
      if (
        (localStorage.getItem("networks_menu") && currentMenu === "NETWORKS") || // member option
        currentMenu === "NETWORKS-DISCIPLESHIPS" || // member option
        currentMenu === "DISCIPLESHIPS-ATTENDANCES" // member option
      ) {
        localStorage.setItem(
          "networks_menu",
          JSON.stringify({ menu: "INTEGRANTS" }) // restart admin menu to "INTEGRANTS"
        );
      }
      // si no hay nada en el localstorage
      if (!localStorage.getItem("networks_menu")) {
        localStorage.setItem(
          "networks_menu",
          JSON.stringify({ menu: "INTEGRANTS" }) // restart admin menu to "INTEGRANTS"
        );
      }
    }
    setNetworksMenu();
  }, [user]);

  // verificamos que el usuario sea director de redes
  const verifyNetworkDirector = async () => {
    const userId = JSON.parse(localStorage.getItem("user_data")).id;
    const { data } = await axios.get(
      `/api/v1/director-member/${userId}/department/${7}/` // 7 es el id del departamento de redes
    );
    if (data.status_code === 200) {
      setIsNetworkDirector(true);
    } else {
      setIsNetworkDirector(false);
    }
  };
  useEffect(() => {
    verifyNetworkDirector();
  }, []);

  return (
    <ProtectedDepartment>
      <div className=" w-full h-full">
        {user?.is_director && isNetworkDirector && <NetworksHeaderAdmin />}
        <div
          className={` ${user?.is_director ? "h-[calc(100%-75px)]" : "h-full"}`}
        >
          {Object.keys(user).length > 0 ? (
            user?.is_director && isNetworkDirector ? (
              changeAdminMenu()
            ) : (
              changeMemberMenu()
            )
          ) : (
            <div>
              <h1>Cargando...</h1>
            </div>
          )}
        </div>
      </div>
    </ProtectedDepartment>
  );
}
