/*
  Página para listar todos los departamentos registrados en la plataforma
*/
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/Layout";
import HeaderMenu from "../components/admin/HeaderMenu";
import DeptoContext from "../context/Departments/Context";

export default function AdminDepts() {
  const { departments, getDepartments } = useContext(DeptoContext);

  useEffect(() => {
    getDepartments();
  }, []);

  return (
    <Layout>
      <div
        className=" mms:pb-[0px]
        w-auto h-full bg-bg overflow-y-auto "
      >
        <HeaderMenu />
        <div className=" mms:px-[10px] mms:pt-[20px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px] w-full h-auto ">
          {/* title */}
          <div className=" mb-4 text-center w-full">
            <h1 className=" mms:text-[24px] ts:text-32px text-title uppercase">Departamentos</h1>
          </div>

          {/* content */}
          <div className=" mms:py-[20px] w-full h-auto rounded-[10px] ">
            <div className=" pb-[40px] min-h-[500px] max-h-auto w-full">
              {departments.map((dept) => (
                <CardDept dept={dept} key={dept.id} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const CardDept = ({ dept }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() =>
        navigate({
          pathname: `/dashboard/admin/departamentos/${dept.id}`,
          search: `?depto=${encodeURIComponent(dept.name)}`,
        })
      }
      className=" flex justify-between items-center mx-auto mb-6 px-[40px] w-full h-[48px] text-content bg-bg2 rounded
      hover:text-title  hover:bg-bg3 hover:duration-300"
    >
      <p>{dept.name}</p>
      <div>
        <i className="fa-solid fa-caret-right"></i>
      </div>
    </button>
  );
};
