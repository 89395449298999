import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { format } from "date-fns";

import DeptoContext from "../../../../context/Departments/Context";
import { fonts } from "../../../../assets/styles";

export default function MenuProgressPhases() {
  const { setLiberationMenu } = useContext(DeptoContext);

  const [prayPetitions, setPrayPetitions] = useState([]);

  const getAllPrayPetitions = async () => {
    const { data } = await axios.get("/api/v1/only/pray_petitions/");
    setPrayPetitions(data);
  };
  useEffect(() => {
    getAllPrayPetitions();
  }, []);

  return (
    <div className=" mms:px-[10px] mms:pt-[20px] mms:pb-[40px] mms:h-auto mm:px-[20px] ml:px-[40px] tm:px-[60px] tm:h-full ll:px-[80px] overflow-y-auto bg-bg ">
      {/* {document && <PDF document={document} />} */}
      <div className=" flex flex-col justify-center items-center w-full h-full">
        <div className="mb-8 text-center">
          <h1 className={` ${fonts.heading1} uppercase`}>Intersesores</h1>
          <p className="text-Red">
            El listado de peticiones de oración se borra cada domingo a las
            12:00 am.
          </p>
        </div>
        <div className=" w-full mb-2 ">
          <button
            onClick={() => {
              localStorage.setItem(
                "liberation_menu",
                JSON.stringify({
                  menu: "OPTIONS",
                })
              );
              setLiberationMenu();
            }}
          >
            Opciones
          </button>{" "}
          {" > "} <span className=" text-content ">Peticiones de oración</span>
        </div>
        <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] py-[40px] w-full h-auto bg-bg2 rounded-[10px] overflow-y-auto ">
          {prayPetitions.length > 0 ? (
            prayPetitions.map((prayPetition) => (
              <PrayPetitionCard
                prayPetition={prayPetition}
                key={prayPetition.id}
              />
            ))
          ) : (
            <div>
              <p>No hay peticiones de oración</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const PrayPetitionCard = ({ prayPetition }) => {
  return (
    <div className=" mms:px-[10px] mms:h-auto mm:px-[20px] ts:px-[40px] mb-[20px] py-[10px] bg-bg3 rounded-[10px] ">
      <div className=" mms:flex-col mms:items-start ll:flex-row ll:items-center flex justify-between mb-[10px] pb-2 border-b border-options">
        <div className=" mms:flex-col flex ">
          <p className=" mms:text-start text-title">Nombre de persona:</p>
          <p className=" mms:ml-0 ml-2 text-content">
            {prayPetition.for_fullname}
          </p>
        </div>
        <div className=" mms:ml-0 ml-8 flex ">
          <p className=" text-title">Teléfono de persona:</p>
          <p className=" ml-2 text-content">{prayPetition.for_phone}</p>
        </div>
        <div className=" mms:ml-0 ml-8 flex">
          <p className=" text-title">Fecha:</p>
          <p className=" ml-2 text-content">
            {format(new Date(prayPetition.date), "dd 'de' MMM 'de' yyyy")}
          </p>
        </div>
      </div>
      <div className=" mms:flex-col ll:flex-row ll:items-center flex justify-between mb-[10px] pb-2 border-b border-options">
        <div className=" flex">
          <p className=" text-title">Hecha por:</p>
          <p className=" ml-2 text-content">{prayPetition.made_by_fullname}</p>
        </div>
        <div className=" mms:ml-0 ml-8 flex ">
          <p className=" text-title">Departamento:</p>
          <p className=" ml-2 text-content">{prayPetition.depto_from}</p>
        </div>
        <div className=" mms:ml-0 ml-8 flex ">
          <p className=" text-title">Teléfono de persona:</p>
          <p className=" ml-2 text-content">{prayPetition.made_by_phone}</p>
        </div>
      </div>
      <div>
        <p className=" text-title">Petición:</p>
        <p className=" max-h-[100px] text-content overflow-y-auto">
          {prayPetition.petition}
        </p>
      </div>
    </div>
  );
};
