import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import DeptoContext from "../../context/Departments/Context";
import { useNotifications } from "reapop";

import { fonts } from "../../assets/styles";

export default function AddMember() {
  const { availableMembers, getAvailableMembers, getMembersByDepartment } =
    useContext(DeptoContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const { deptoId } = useParams();
  const { notify } = useNotifications();

  const deptoName = searchParams.get("depto");

  // add member
  const addMember = async (userId) => {
    try {
      // verify if the member isn't depto member already
      const response = await axios.get(
        `/api/v1/director-member/${userId}/department/${deptoId}/`
      );
      if (response.data.status_code === 200) {
        notify({
          title: "¡Aviso!",
          message: "Este integrante ya es parte de este departamento.",
          status: "warning",
        });
      } else {
        // save the depto member
        const { data } = await axios.post("/api/v1/depto-member/", {
          id_department: deptoId,
          id_member: userId,
        });
        notify({
          title: "¡Éxito!",
          message: data.message,
          status: "success",
        });
        getAvailableMembers();
        getMembersByDepartment(deptoId); // update members list
      }
    } catch (error) {
      notify({
        title: "¡Error!",
        message:
          "Ha habido un error en el servidor. Intente nuevamente por favor.",
        status: "error",
      });
    }
  };

  useEffect(() => {
    getAvailableMembers();
  }, []);

  return (
    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] w-full h-full ">
      <div className="mb-8">
        <div className=" mb-6 text-center">
          <h1 className={`mb-1 ${fonts.heading4} text-title`}>{deptoName}</h1>
          <p className={` ${fonts.body1} text-content`}>
            Agregar integrantes disponibles al departamento de {deptoName}
          </p>
        </div>
        <div className="p-[20px] w-full h-[400px] max-h-[400px] bg-bg3 rounded-[10px] overflow-y-auto">
          {availableMembers.length > 0 ? (
            availableMembers.map((user) => (
              <MemberCard user={user} addMember={addMember} key={user.id} />
            ))
          ) : (
            <span className="text-content">No hay usuarios disponibles.</span>
          )}
        </div>
      </div>
    </div>
  );
}

const MemberCard = ({ user, addMember }) => {
  const { deptoId } = useParams();
  const [userExists, setUserExists] = useState(false);

  const verifyIfUserExists = async (userId) => {
    // vefiricar que el director o miembro y el departamento existan
    const { data } = await axios.get(
      `/api/v1/director-member/${userId}/department/${deptoId}/`
    );
    if (data.status_code === 200) {
      setUserExists(true);
    } else {
      setUserExists(false);
    }
  };

  useEffect(() => {
    verifyIfUserExists(user.id);
  }, []);

  return (
    <div className=" mms:flex-col mms:items-start mms:mb-2 mms:pb-2 mms:h-auto ts:flex-row ts:items-center ts:h-[56px] flex justify-between items-center border-b border-options">
      <div>
        <p className="text-subtitle">
          {user.name} {user.lastname}{" "}
        </p>
        <p className={` mms:my-1 text-content ${fonts.body2}`}>
          Asignaciones {user.asignations}
        </p>
      </div>
      <div>
        {!userExists ? (
          <button
            onClick={() => {
              addMember(user.id);
              verifyIfUserExists(user.id);
            }}
            className=" w-[80px] h-[28px] bg-primary rounded-[5px]"
          >
            Agregar
          </button>
        ) : (
          <div>
            <small className=" flex justify-center items-center w-[80px] h-[28px] bg-options text-content rounded ">
              Ya asignado
            </small>
          </div>
        )}
      </div>
    </div>
  );
};
