import { useContext } from "react";

import { SELECTED_DISCIPLESHIP, SELECTED_NETWORK } from "../context/types";

import DeptoContext from "../context/Departments/Context";
import AddMemberModal from "./modal-content/AddMemberModal";
import AddMemberTutorModal from "./modal-content/AddMemberTutorModal";
import AddMemberTutorByCdpModal from "./modal-content/AddMemberTutorByCdpModal";
import AddStudentesModal from "./modal-content/AddStudentsModal";
import TranslateStudentModal from "./modal-content/TranslateStudentsModal";
import AsignCDPModal from "./modal-content/AsignCDPModal";
import AsignAffirmerModal from "./modal-content/AsignAffirmerModal";
import AffirmerDetailsModal from "./modal-content/AffirmerDetailsModal";
import NewConvertHelpRequestDetailsModal from "./modal-content/NewConvertHelpRequestDetailsModal";
// networks
import NetworksDiscipleshipDetailsAsignModal from "./modal-content/NetworksDiscipleshipDetailsAsignModal";
import NetworksNetworkDetailsModal from "./modal-content/NetworksNetworkDetailsModal";
// admin
import AdminUserDetailsModal from "./modal-content/AdminUserDetailsModal";

export default function Modal() {
  const { setShowModal, menuModal } = useContext(DeptoContext);

  const showSelectedModalMenu = () => {
    switch (menuModal) {
      case "ADD-MEMBER":
        return <AddMemberModal />; // responsive done
      case "ADD-MEMBER-TUTOR":
        return <AddMemberTutorModal />; // responsive done
      case "ADD-MEMBER-TUTOR-BY-CDP":
        return <AddMemberTutorByCdpModal />; // responsive done
      case "ADD-STUDENT-TO-UNIT":
        return <AddStudentesModal />; // responsive done
      case "TRANSLATE-STUDENT":
        return <TranslateStudentModal />; // responsive done
      case "ASIGN-CDP":
        return <AsignCDPModal />; // responsive done
      case "ASIGN-AFFIRMER":
        return <AsignAffirmerModal />; // responsive done
      case "AFFIRMER-DETAILS":
        return <AffirmerDetailsModal />; // responsive done
      case "NEW-CONVERT-HELP-REQUEST-DETAILS":
        return <NewConvertHelpRequestDetailsModal />; // responsive done
      // networks
      case SELECTED_DISCIPLESHIP:
        return <NetworksDiscipleshipDetailsAsignModal />; // responsive done
      case SELECTED_NETWORK:
        return <NetworksNetworkDetailsModal />; // responsive done
      case "ADMIN-USER-DETAILS":
        return <AdminUserDetailsModal />; // responsive done
      default:
        return <AddMemberModal />; // responsive done
    }
  };

  // Escucha el evento keyup
  document.addEventListener("keyup", handleKeyUp);

  // Maneja el evento keyup
  function handleKeyUp(event) {
    if (event.keyCode === 27) {
      // La tecla ESC tiene el código 27
      setShowModal(false);
    }
  }

  return (
    <div
      onClick={() => setShowModal(false)}
      className=" fixed z-50 top-0 right-0 bottom-0 left-0 flex justify-center items-center w-screen min-h-[700px] h-screen bg-black/80 "
    >
      <div
        onClick={() => setShowModal(false)}
        className=" absolute top-[10px] right-[14px] flex justify-center items-center w-[30px] h-[30px] rounded
            text-red-500 cursor-pointer hover:bg-red-500 hover:text-white hover:duration-300"
      >
        <i className=" text-[32px] fa-solid fa-times"></i>
      </div>
      {/* <div className=" px-[40px] py-[10px] w-[600px] h-auto  bg-bg2 rounded-[25px] border border-red-500"> */}
      <div
        onClick={(e) => e.stopPropagation()}
        className=" mms:w-[90%] ts:w-[80%] tm:w-[550px] py-[10px] max-w-[800px] h-auto  bg-bg2 rounded-[10px] "
      >
        {showSelectedModalMenu()}
      </div>
    </div>
  );
}
