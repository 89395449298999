import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNotifications } from "reapop";

import Layout from "../components/Layout";
import HeaderMenu from "../components/admin/HeaderMenu";
import { fonts } from "../assets/styles";

const schema = yup.object({
  name: yup
    .string()
    .required("Ingrese su nombre por favor.")
    .max(32, "Máximo 32 carácteres."),
  date: yup.string().required("Seleccione una fecha por favor."),
  hour: yup.string().required("Seleccione un hora por favor"),
});

export default function Admin() {
  const { notify } = useNotifications();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (formdata) => {
    const { data } = await axios.post("/api/v1/church_activities/", formdata);
    if (data.status_code === 201) {
      reset();
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "warning",
      });
    }
  };

  return (
    <Layout>
      <div
        className=" mms:pb-[0px]
        w-auto h-full bg-bg overflow-y-auto "
      >
        <HeaderMenu />
        <div className=" mms:px-[10px] mms:pt-[20px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px] w-full h-auto ">
          {/* title */}
          <div className=" mb-4 text-center w-full">
            <h1
              className={` mt-2 text-center text-title ${fonts.heading4} uppercase `}
            >
              Crear Actividad
            </h1>
          </div>

          {/* content */}
          <div className=" mms:py-[20px] w-full h-auto rounded-[10px] ">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] ts:w-[500px] py-[40px] mx-auto h-auto bg-bg2 rounded-[10px] "
            >
              {/* name */}
              <div className=" mt-4 w-full ">
                <label className="text-subtitle block mb-2">
                  Nombre <span className="text-Red">*</span>
                </label>{" "}
                <input
                  className=" indent-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
                  {...register("name")}
                  maxLength={32}
                />
                {<small className="text-red-500">{errors.name?.message}</small>}
              </div>

              {/* day */}
              <div className=" mt-4 w-full ">
                <label className="text-subtitle block mb-2">
                  Fecha <span className="text-Red">*</span>
                </label>{" "}
                <input
                  className="w-full p-2 bg-bg4 rounded shadow-sm text-content focus:outline-none focus:shadow-outline-blue appearance-none"
                  type="date"
                  {...register("date")}
                />
                {<small className="text-red-500">{errors.date?.message}</small>}
              </div>

              {/* hour */}
              <div className=" mt-4 w-full ">
                <label className="text-subtitle mb-2 block">
                  Hora <span className="text-Red">*</span>
                </label>
                <select
                  className=" px-2 w-full h-[44px] bg-bg4 text-content outline-none rounded"
                  {...register("hour", { required: true })}
                >
                  <option value="01:00 AM" defaulselected="true">
                    01:00 AM
                  </option>
                  <option value="02:00 AM">02:00 AM</option>
                  <option value="03:00 AM">03:00 AM</option>
                  <option value="04:00 AM">04:00 AM</option>
                  <option value="05:00 AM">05:00 AM</option>
                  <option value="06:00 AM">06:00 AM</option>
                  <option value="07:00 AM">07:00 AM</option>
                  <option value="08:00 AM">08:00 AM</option>
                  <option value="09:00 AM">09:00 AM</option>
                  <option value="10:00 AM">10:00 AM</option>
                  <option value="11:00 AM">11:00 AM</option>
                  <option value="12:00 PM">12:00 PM</option>
                  <option value="13:00 PM">01:00 PM</option>
                  <option value="14:00 PM">02:00 PM</option>
                  <option value="15:00 PM">03:00 PM</option>
                  <option value="16:00 PM">04:00 PM</option>
                  <option value="17:00 PM">05:00 PM</option>
                  <option value="18:00 PM">06:00 PM</option>
                  <option value="19:00 PM">07:00 PM</option>
                  <option value="20:00 PM">08:00 PM</option>
                  <option value="21:00 PM">09:00 PM</option>
                  <option value="22:00 PM">10:00 PM</option>
                  <option value="23:00 PM">11:00 PM</option>
                  <option value="00:00 AM">00:00 AM</option>
                </select>
                <small className="text-red-500">{errors.hour?.message}</small>
              </div>

              <div className=" flex justify-center mt-6 w-full">
                <button className="bg-primary text-white w-full h-[44px] rounded uppercase">
                  Crear
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}
