import axios from "axios";
import { useEffect, useState, useContext, useRef } from "react";
import { useNotifications } from "reapop";

import DeptoContext from "../../context/Departments/Context";

import { fonts } from "../../assets/styles";

export default function AddStudentModal() {
  const {
    getMembersToBeStudents,
    membersToBeStudents,
    getStudentsByUnit,
    // setShowModal,
  } = useContext(DeptoContext);

  const [peopleList, setPeopleList] = useState([]); // to save the people to be students

  const inputCodeRef = useRef();
  const { notify } = useNotifications();

  const [showMembersStudents, setShowMembersStudents] = useState(false); // by default show new converts
  // process
  const [selectedProcess, setSelectedProcess] = useState(() => {
    const process = JSON.parse(localStorage.getItem("process"));
    return process !== null ? process : null;
  });
  // units
  const [selectedUnit, setSelectedUnit] = useState(() => {
    const unit = JSON.parse(localStorage.getItem("unit"));
    return unit !== null ? unit : null;
  });

  // get new convert by filter name
  const getNewConvertByCode = async (name) => {
    if (name.length > 0) {
      let datos = [];
      if (showMembersStudents) {
        // get members
        const { data } = await axios.get(
          `/api/v1/members/add-student/code/${name}`
        );
        datos = data;
      } else {
        // get new converts
        const { data } = await axios.get(
          `/api/v1/new_convert/add-student/code/${name}/`
        );
        datos = data.new_convert;
      }
      // setPeopleList(Object.keys(datos).length > 0 ? [datos] : []);
      setPeopleList(datos);
    } else {
      setPeopleList([]);
      getMembersToBeStudents(showMembersStudents);
    }
  };

  /*
   * Enviar la peticion para crear un nuevo estudiante
   */
  const createStudent = async (codeReference) => {
    const dataStudent = {
      is_member: showMembersStudents,
      code_reference: codeReference,
      id_unit: selectedUnit.id,
    };
    const { data } = await axios.post("/api/v1/students/create/", dataStudent);
    getStudentsByUnit(selectedUnit.id); // get all students by unit
    getMembersToBeStudents(showMembersStudents);
    if (data.status_code === 200) {
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
  };

  /*
   * Mostrar a los Miembros o Nuevos Creyentes que aún no sean estudiantes.
   */
  useEffect(() => {
    if (inputCodeRef.current.value.length === 0) {
      getMembersToBeStudents(showMembersStudents);
    } else {
      getNewConvertByCode(inputCodeRef.current.value);
    }
  }, [showMembersStudents]);

  return (
    <div className=" mms:px-[10px] mm:px-[20px] w-full h-[80%] overflow-y-auto ">
      <div className="mb-8">
        {/* title */}
        <div className=" mb-2 text-center">
          <h1 className={` mms:text-[22px] mb-1 ${fonts.heading4} text-title`}>
            Agregar estudiantes
          </h1>
        </div>

        {/* filter */}
        <div className=" sticky top-[40px] mb-2 h-[40px] bg-bg2 ">
          <div className=" mb-[20px] h-[40px] ">
            <div className=" flex justify-between w-full h-[40px] border-b border-bg ">
              <input
                type="text"
                onChange={(e) => getNewConvertByCode(e.target.value)}
                ref={inputCodeRef}
                placeholder={
                  showMembersStudents
                    ? "Buscar por nombre de usuario"
                    : "Buscar por nombre de Nuevo Creyente"
                }
                className=" indent-3 w-[calc(100%-44px)] h-full bg-bg3 outline-none "
              />
              <div
                onClick={() => {
                  inputCodeRef.current.value = "";
                  getMembersToBeStudents(showMembersStudents);
                }}
                className=" flex justify-center items-center w-[44px] h-full bg-bg cursor-pointer"
              >
                <i className=" text-[24px] fa-solid fa-times"></i>
              </div>
            </div>
          </div>
        </div>

        {/* switch people to be student */}
        <div className=" mb-1 pb-[10px] h-auto border-b border-bg3 overflow-x-auto ">
          <div className=" flex items-center px-[2px] w-[300px] h-[34px] bg-[#D9D9D9] text-content rounded-[25px]">
            <button
              onClick={() => setShowMembersStudents(false)}
              className={` w-[150px] h-[32px] ${
                !showMembersStudents
                  ? " bg-primary text-white "
                  : " bg-[#d9d9d9] text-content"
              } rounded-[25px] outline-none`}
            >
              Creyentes
            </button>
            <button
              onClick={() => setShowMembersStudents(true)}
              className={` w-[150px] h-[32px] ${
                showMembersStudents
                  ? " bg-primary text-white "
                  : " bg-[#d9d9d9] text-content"
              } rounded-[25px] outline-none`}
            >
              Usuarios
            </button>
          </div>
        </div>

        <div className=" mms:flex-col mms:items-start tm:flex-row ts:items-end flex my-1 ">
          <p className=" text-title">Agregar Estudiante a: </p>
          <p className={`ml-1 text-content ${fonts.body1}`}>
            {selectedProcess?.name}
          </p>
          <p className={`ml-1 text-content ${fonts.body1}`}>
            {selectedUnit && ">"} {selectedUnit?.name}
          </p>
        </div>

        {/* people list */}
        <div className="px-[10px] w-full h-[350px] max-h-[400px] bg-bg3 rounded-[10px] overflow-y-auto">
          {/* si no hay nada en el input de filtro */}
          {inputCodeRef.current?.value?.length === 0 && (
            <>
              {membersToBeStudents.length > 0 ? (
                membersToBeStudents.map((user) => (
                  <div
                    className=" mms:flex-col mms:items-start mms:py-[10px] mms:h-auto ts:flex-row flex justify-between items-center h-[56px] border-b border-options"
                    key={user.id}
                  >
                    <div>
                      <p className=" text-title ">
                        {user.name} {user.lastname}{" "}
                      </p>
                      <small className=" text-content ">{user.code}</small>
                    </div>
                    <div>
                      <button
                        onClick={() => createStudent(user.code)}
                        className=" mr-[10px] px-[10px] w-auto h-[32px] bg-primary rounded-[5px]"
                      >
                        Agregar
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className=" mt-2 text-center ">
                  <span className="text-content">Listado vacío.</span>
                </div>
              )}{" "}
            </>
          )}
          {/* si hay contenido en el input del filtro  */}
          {inputCodeRef.current?.value?.length > 0 && (
            <>
              {peopleList.length > 0 ? (
                peopleList.map((user) => (
                  <div
                    className=" mms:flex-col mms:items-start mms:py-[10px] mms:h-auto ts:flex-row flex justify-between items-center h-[56px] border-b border-options"
                    key={user.id}
                  >
                    <div>
                      <p className="text-title">
                        {user.name} {user.lastname}{" "}
                      </p>
                      <small className=" text-content ">{user.code}</small>
                    </div>
                    <div>
                      <button
                        onClick={() => createStudent(user.code)}
                        className=" mr-[10px] px-[10px] w-auto h-[32px] bg-primary rounded-[5px]"
                      >
                        Agregar
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className=" mt-2 text-center ">
                  <span className="text-content">Sin resultados.</span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
