import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import NotificationsSystem, { atalhoTheme, useNotifications } from "reapop";
import "./layout.css";

import { setAxiosSettings } from "../utils/helpers";
import DeptoContext from "../context/Departments/Context";
import Modal from "./Modal";
import { fonts } from "../assets/styles";
// import logo from "/public/logo-dlg.png";
import logo from "../assets/images/logo-dlg.png";

export default function Layout({ children }) {
  const { notifications, dismissNotification } = useNotifications();
  const { user, getUser, show_modal } = useContext(DeptoContext);

  const navigate = useNavigate();

  const logout = async () => {
    Cookies.remove("access_token");
    localStorage.removeItem("user_data");
    getUser();
    setAxiosSettings();
    return navigate("/");
  };

  useEffect(() => {
    getUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className=" layout-container mms:h-screen relative bg-bg text-white">
      {/* menus - normal & responsive */}
      <Menu user={user} logout={logout} />
      <ResponsiveMenu user={user} logout={logout} />

      {/* header h-[75px] */}
      <HeaderMenu user={user} />

      {/* content */}
      <div className=" content w-full h-full">{children}</div>

      {/* modal */}
      {show_modal && <Modal />}

      <NotificationsSystem
        notifications={notifications}
        dismissNotification={(id) => dismissNotification(id)}
        theme={atalhoTheme}
      />
    </div>
  );
}

const HeaderMenu = ({ user }) => {
  const [activity, setActivity] = useState({}); // {id, name, date, hour, details}
  const [diaDeLaSemana, setDiaDeLaSemana] = useState("");
  const [diaDelMes, setDiaDelMes] = useState("");

  const getNextActivity = async () => {
    const { data } = await axios.get("/api/v1/church_activities/next-one/");
    setActivity(data);
    const fecha = new Date(data.date);
    const diasSemana = ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"];
    setDiaDeLaSemana(diasSemana[fecha.getDay()]);
    setDiaDelMes(fecha.getDate());
  };

  useEffect(() => {
    getNextActivity();
  }, []);

  return (
    <div className=" header flex justify-between mms:px-[10px] ms:px-[20px] mm:px-[30px] ml:px-[40px] border-b border-borders">
      {/* ads */}
      {activity.name && (
        <div
          className=" mms:hidden ts:flex items-center  w-[200px] h-full "
          title="Próxima actividad"
        >
          <div className=" relative w-[50px] h-[80%] text-[#202020] ">
            <div className=" absolute top-3 rounded-[10px] left-1 z-10 w-[40px] h-[40px] bg-[#D9D9D9] opacity-50 "></div>
            <div className=" absolute top-0 rounded-[10px] z-20 text-center w-[48px] h-[48px] bg-[#D9D9D9] ">
              <span className=" text-[12px] ">{diaDeLaSemana}</span>
              <h1 className=" text-[20px] font-bold leading-none ">
                {diaDelMes}
              </h1>
            </div>
          </div>
          <div className=" ml-[50px] pl-2 ">
            <h1 className=" mb-2 leading-none ">{activity.name}</h1>
            <p className=" w-[300px] text-sm text-content overflow-hidden whitespace-nowrap text-ellipsis ">
              {activity.details}
            </p>
            <div className=" flex justify-center items-center w-[80px] h-[20px] bg-bg2 rounded ">
              <p className=" text-sm text-primary ">{activity.hour}</p>
            </div>
          </div>
        </div>
      )}
      {/* logo */}
      <div className=" mms:flex tl:hidden items-center h-full ">
        <figure>
          <img
            src={logo}
            alt="Logo app"
            width={100}
            height={100}
            className=" w-[50px] h-[50px] "
          />
        </figure>
        <div className=" ml-2 my-3 text-center">
          <p
            className={` mr-2 w-auto text-sm text-start text-white ${fonts.heading3} overflow-hidden whitespace-nowrap text-ellipsis `}
            title={`${user?.name} ${user?.lastname}`}
          >
            {user?.name} {user?.lastname}
          </p>
          <p
            className={` w-[170px] text-start text-content ${fonts.body1} overflow-hidden whitespace-nowrap text-ellipsis `}
            title={user?.email}
          >
            {user?.email}
          </p>
        </div>
      </div>
    </div>
  );
};

// NO RESPONSIVE MENU
const Menu = ({ user, logout }) => {
  return (
    <div className="menu mms:hidden tl:block border-r border-borders overflow-y-auto ">
      <div className=" flex flex-col items-center justify-center w-full min-h-[210px] h-[30%] ">
        <figure>
          <img src={logo} alt="Logo app" width={100} height={100} />
        </figure>
        <div className="my-3 text-center">
          <p
            className={`w-[170px] text-white ${fonts.heading3} overflow-hidden whitespace-nowrap text-ellipsis `}
            title={`${user?.name} ${user?.lastname}`}
          >
            {user?.name} {user?.lastname}
          </p>
          <p
            className={` w-[170px] text-content ${fonts.body1} overflow-hidden whitespace-nowrap text-ellipsis `}
            title={user?.email}
          >
            {user?.email}
          </p>
        </div>
      </div>

      <div className=" flex flex-col justify-evenly min-h-[390px] h-[50%] text-title ">
        <ItemMenu
          to="/dashboard/nuevo-creyente/crear"
          icon="fa-users"
          title="Nuevos Creyentes"
          subtitle="Agregar creyente"
        />
        <ItemMenu
          to="/dashboard/nuevos-creyentes"
          icon="fa-chart-bar"
          title="Creyentes"
          subtitle="Ver progresos"
        />
        <ItemMenu
          to="/dashboard/departamentos"
          icon="fa-layer-group"
          title="Departamentos"
        />
        {user?.is_admin && (
          <ItemMenu
            to="/dashboard/admin"
            icon="fa-users-cog"
            title="Administración"
          />
        )}
        <ItemMenu to="/dashboard/cuenta" icon="fa-user-cog" title="Cuenta" />
      </div>

      <div className=" flex justify-start items-center w-full min-h-[110px] h-[20%] text-title ">
        <button
          onClick={logout}
          className="flex items-center pl-[20px] py-2 w-full hover:bg-bg2 hover:duration-300"
        >
          <i className=" text-[30px] fa-solid fa-sign-out-alt"></i>
          <p className="ml-[10px] text-14px">Salir</p>
        </button>
      </div>
    </div>
  );
};
const ItemMenu = ({ to, icon, title, subtitle = null }) => {
  return (
    <Link
      to={to}
      className="flex items-center pl-[20px] py-2 w-full hover:bg-bg2 hover:duration-300"
    >
      <i className={`fa-solid ${icon} text-[30px]`}></i>
      <div className="flex flex-col justify-center ml-[10px]">
        <p className="text-14px">{title}</p>
        {subtitle && (
          <small className="text-12px text-content">{subtitle}</small>
        )}
      </div>
    </Link>
  );
};

// RESPONSIIVE MENU

const ResponsiveMenu = ({ user, logout }) => {
  const [showMenu, setShowMenu] = useState(false); // [true, false]
  return (
    <div className="menu mms:block tl:hidden mms:w-full mms:h-[70px] fixed z-40 right-0 bottom-0 left-0 border-t border-borders bg-bg ">
      <div className=" relative flex justify-between h-full text-title">
        <ResponsiveItemMenu
          to="/dashboard/nuevo-creyente/crear"
          icon="fa-users"
          title="Nuevos Creyentes"
          subtitle="Agregar creyente"
        />
        <ResponsiveItemMenu
          to="/dashboard/nuevos-creyentes"
          icon="fa-chart-bar"
          title="Creyentes"
          subtitle="Ver progresos"
        />
        <ResponsiveItemMenu
          to="/dashboard/departamentos"
          icon="fa-layer-group"
          title="Departamentos"
        />
        {user?.is_admin && (
          <ResponsiveItemMenu
            to="/dashboard/admin"
            icon="fa-users-cog"
            title="Administración"
          />
        )}
        {/* <ItemMenu icon="fa-place-of-worship" title="Actividades" /> */}
        <div
          className={`absolute ${
            showMenu ? "flex" : "hidden"
          } right-0 -top-[100px] flex flex-col justify-around w-[150px] h-[100px] bg-bg2 shadow-sm border-t border-l border-options `}
        >
          {/* <div className="my-3 text-center">
            <p
              className={` mr-2 w-auto text-sm text-start text-white ${fonts.heading3} overflow-hidden whitespace-nowrap text-ellipsis `}
              title={`${user?.name} ${user?.lastname}`}
            >
              {user?.name} {user?.lastname}
            </p>
          </div> */}
          <div className=" block ">
            <Link
              to="/dashboard/cuenta"
              className=" mms:w-full flex justify-start items-center px-[10px] h-[44px] hover:bg-bg3 hover:duration-300 "
            >
              <i className={` mms:text-[20px] fa-solid fa-user-cog `}></i>
              <p className=" mms:w-[90%] mms:text-base ml-2 text-start ">
                Cuenta
              </p>
            </Link>
          </div>
          <div className=" flex justify-start items-center w-full h-[44px] text-title  ">
            <button
              onClick={logout}
              className="flex items-center px-[10px] py-2 w-full hover:bg-bg3 hover:duration-300"
            >
              <i className=" text-[20px] fa-solid fa-sign-out-alt"></i>
              <p className="ml-[10px] text-base ">Salir</p>
            </button>
            {/* <ItemMenu icon="fa-sign-out-alt" title="Salir" /> */}
          </div>
        </div>
        <button
          onClick={() => setShowMenu(!showMenu)}
          className="mms:flex mms:flex-col mms:justify-center mms:items-center w-[60px] h-full hover:bg-bg2 hover:duration-300 "
        >
          <i className="fa-solid fa-bars"></i>
          <small className=" text-[12px] ">Más</small>
        </button>
      </div>
    </div>
  );
};

const ResponsiveItemMenu = ({ to, icon, title, subtitle = null }) => {
  return (
    <Link
      to={to}
      className=" mms:w-[60px] mm:w-[calc(100%/5)] flex flex-col justify-center items-center h-full hover:bg-bg2 hover:duration-300 "
    >
      <i className={` mms:text-[20px] fa-solid ${icon} text-[30px]`}></i>
      <p className=" mms:w-[90%] mms:text-[11px] text-center text-14px overflow-hidden whitespace-nowrap text-ellipsis ">
        {title}
      </p>
    </Link>
  );
};
