/*
 * This page is used to show the selected department
 */
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import EducationContainer from "../components/departments/education/EducationContainer";
import AfirmationContainer from "../components/departments/afirmation/AfirmationContainer";
import VisitsContainer from "../components/departments/visits/VisitsContainer";
import GiveGraceContainer from "../components/departments/give-grace/GiveGraceContainer";
import EvangelimsContainer from "../components/departments/evangelism/EvangelismContainer";
import IntersetionContainer from "../components/departments/intersetion/IntersetionContainer";
import LiberationContainer from "../components/departments/liberation/LiberationContainer";
import NetworksContainer from "../components/departments/networks/NetworksContainer";

export default function DepartmentVisits() {
  const { deptoId } = useParams();

  const showSelectedDepto = () => {
    switch (deptoId) {
      case "1":
        return <EducationContainer />;
      case "2":
        return <AfirmationContainer />;
      case "3":
        return <GiveGraceContainer />;
      case "4":
        return <VisitsContainer />;
      case "5":
        return <IntersetionContainer />;
      case "6":
        return <EvangelimsContainer />;
      case "7":
        return <NetworksContainer />;
      case "8":
        return <LiberationContainer />;
      default:
        return;
    }
  };

  return <Layout>{showSelectedDepto()}</Layout>;
}
