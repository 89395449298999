import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useNotifications } from "reapop";

import DeptoContext from "../../context/Departments/Context";
import { fonts } from "../../assets/styles";

export default function Networks_NetworkDetailsModal() {
  const { selectedNetwork } = useContext(DeptoContext);
  const [selectedDiscipleship, setSelectedDiscipleship] = useState(null);
  const [discipleships, setDiscipleships] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [selectedNetworkId, setSelectedNetworkId] = useState("0"); // from input select

  const { notify } = useNotifications();

  // handle change from one network to another
  const handleNetworkChange = async () => {
    if (!selectedDiscipleship) {
      return notify({
        title: "Aviso",
        message: "Seleccione un nueva red",
        status: "warning",
      });
    }
    if (selectedNetworkId === "0") {
      return notify({
        title: "Aviso",
        message: "Seleccione una nueva red",
        status: "warning",
      });
    }
    if (parseInt(selectedNetworkId) === selectedNetwork.id)
      return notify({
        title: "Aviso",
        message: "No puede mover un discipulado a la misma red",
        status: "warning",
      });
    const { data } = await axios.put(
      `/api/v1/discipleships/change_network/${selectedDiscipleship.id}`,
      { id_network: selectedNetworkId }
    );
    if (data.status_code === 201) {
      setSelectedDiscipleship(null);
      setSelectedNetworkId("0");
      getDiscipleships();
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
  };

  // get disicpleships by selected network
  const getDiscipleships = async () => {
    const { data } = await axios.get(
      `/api/v1/discipleships/network/${selectedNetwork.id}`
    );
    setDiscipleships(data);
  };
  // to show the networks list and move a discipleship to another network
  const getAllNetworks = async () => {
    const { data } = await axios.get("/api/v1/networks");
    setNetworks(data);
  };
  useEffect(() => {
    getDiscipleships();
    getAllNetworks();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className=" mms:px-[10px] mm:px-[20px] min-h-[500px] max-h-[600px] overflow-y-auto ">
      {/* title */}
      <div className=" mb-4 text-center ">
        <h1 className={`mb-1 ${fonts.heading4} text-title`}>
          Detalles de la Red
        </h1>
        <p className=" text-primary ">{selectedNetwork.name}</p>
      </div>

      {/* content */}
      <div className=" mms:flex-col flex justify-between ">
        {/* selected networkk */}
        <div className=" mms:w-full mb-8 px-[10px] w-[400px] ">
          {/* network info */}
          <div className=" mb-6  w-full h-[200px] bg-bg3 rounded-[10px]">
            <div className=" p-[10px] w-full h-[100px] border-b border-options ">
              <p className=" text-third">Líder 1 de Red:</p>
              <p className=" w-full text-content overflow-hidden whitespace-nowrap text-ellipsis ">
                {selectedNetwork.leader_one_fullname}
              </p>
              <p className=" w-full text-content overflow-hidden whitespace-nowrap text-ellipsis ">
                {selectedNetwork.leader_one_email}
              </p>
            </div>
            {selectedNetwork.leader_two_email ? (
              <div className=" p-[10px] w-full h-[100px] ">
                <p className=" text-third">Líder 2 de Red:</p>
                <p className=" w-full text-content overflow-hidden whitespace-nowrap text-ellipsis ">
                  {selectedNetwork.leader_two_fullname}
                </p>
                <p className=" w-full text-content overflow-hidden whitespace-nowrap text-ellipsis ">
                  {selectedNetwork.leader_two_email}
                </p>
              </div>
            ) : (
              <div className=" p-[10px] w-full h-[100px] ">
                <p className=" text-content ">Sin líder 2</p>
              </div>
            )}
          </div>

          {/* cdp asignations */}
          <div className=" relative p-[20px] pt-0 w-full h-[280px] bg-bg3 rounded-[10px] overflow-y-auto">
            <div className=" sticky top-0 pb-2 text-center text-third bg-bg3 ">
              <small>Discipulados de esta Red</small>
            </div>
            <div className={`text-start w-full `}>
              {discipleships.length > 0 ? (
                discipleships.map((discipleship) => (
                  <div
                    className=" mms:flex-col mms:items-start mms:py-2 tm:flex-row tm:items-center flex justify-between mb-[15px] px-[10px] py-1 bg-bg4 rounded "
                    key={discipleship.id}
                  >
                    <div className={`${fonts.body1} text-title`}>
                      <p>{discipleship.leader_one_fullname}</p>
                      {discipleship.leader_two_fullname && (
                        <p>{discipleship.leader_two_fullname}</p>
                      )}
                    </div>
                    <div className=" mms:mt-2 tm:mt-0 ">
                      <button
                        onClick={() => setSelectedDiscipleship(discipleship)}
                        className={` px-[10px] py-1 ${fonts.body1} bg-Red text-title rounded `}
                      >
                        Cambiar de red
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className=" text-center text-content ">
                  <p>No hay Discipulados</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* cdps list to asign */}
        <div className=" mms:w-full px-[10px] w-[400px] ">
          <div className=" relative p-[10px] pt-0 h-[calc(100%-30px)] max-h-[500px] overflow-y-auto bg-bg3 rounded-[10px] ">
            <div className=" sticky top-0 h-[30px] text-center text-third bg-bg3 border-b border-options ">
              <small>Cambiar discipulado de Red</small>
            </div>
            {selectedDiscipleship ? (
              <div className=" h-auto ">
                <div className=" mms:h-auto pt-2 h-[120px] border-b border-options ">
                  <p className=" text-primary ">Información del Discipulado</p>
                  <div className="my-2 text-content  ">
                    <p className=" text-title ">Líder 1 de Discipulado: </p>
                    <p>{selectedDiscipleship.leader_one_fullname}</p>
                  </div>
                  {selectedDiscipleship.leader_two_fullname && (
                    <div className=" text-content ">
                      <p className=" text-title ">Líder 2 de Discipulado: </p>
                      <p>{selectedDiscipleship.leader_two_fullname}</p>
                    </div>
                  )}
                </div>
                <div className=" mt-4 ">
                  <p className=" mb-2 text-title ">
                    Mover de{" "}
                    <span className=" px-1 py-[2px] bg-options rounded ">
                      {selectedNetwork.name}
                    </span>{" "}
                    a:
                  </p>
                  <p className=" text-content ">Seleccionar nueva Red</p>
                  <select
                    onChange={(e) => setSelectedNetworkId(e.target.value)}
                    className={`  ${
                      selectedNetworkId !== "0" ? "text-title" : "text-content"
                    } indent-1 w-[200px] h-[36px] bg-bg4 rounded `}
                    value={selectedNetworkId}
                  >
                    <option value="0">Seleccionar</option>
                    {networks.map((network) => (
                      <option value={network.id} key={network.id}>
                        {network.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className=" mt-6 ">
                  <button
                    onClick={handleNetworkChange}
                    className=" w-[150px] h-[36px] bg-primary rounded "
                  >
                    Mover de Red
                  </button>
                </div>
              </div>
            ) : (
              <div className=" flex justify-center items-center mb-4 h-[100px]  ">
                <p className=" text-content ">Seleccione un discipulado</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
