import { useContext, useEffect } from "react";

import DeptoContext from "../../../../context/Departments/Context";

export default function EvangelismHeaderAdmin() {
  const { setShowModal, setMenuModal, evangelismMenu, setEvangelismMenu } =
    useContext(DeptoContext);

  useEffect(() => {
    setEvangelismMenu();
  }, []);

  return (
    <div
      className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px]
    flex items-center px-[80px] h-[75px] w-full border-b border-borders overflow-x-auto "
    >
      {/* integrants */}
      <div className="flex items-center">
        <button
          onClick={() => {
            localStorage.setItem(
              "evangelism_menu",
              JSON.stringify({
                menu: "INTEGRANTS",
              })
            );
            setEvangelismMenu();
          }}
          className={` flex justify-center items-center h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            evangelismMenu?.menu === "INTEGRANTS"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          Integrantes
        </button>
        {evangelismMenu?.menu === "INTEGRANTS" && (
          <button
            onClick={() => {
              setMenuModal("ADD-MEMBER");
              setShowModal(true);
            }}
            className={` flex justify-center items-center ml-2 h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px]
              bg-primary text-white`}
          >
            Agregar
          </button>
        )}
      </div>
      <div className=" mx-2 w-[1px] h-[36px] border-r border-options "></div>
      {/* activities */}
      <div className="flex items-center mr-4">
        <button
          onClick={() => {
            localStorage.setItem(
              "evangelism_menu",
              JSON.stringify({
                menu: "ACTIVITIES-ADMIN",
              })
            );
            setEvangelismMenu();
          }}
          className={` flex justify-center items-center h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            evangelismMenu?.menu === "ACTIVITIES-ADMIN"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          Actividades
        </button>
        <button
          onClick={() => {
            localStorage.setItem(
              "evangelism_menu",
              JSON.stringify({
                menu: "CREATE-ACTIVITY",
              })
            );
            setEvangelismMenu();
          }}
          className={` flex justify-center items-center ml-2 min-w-[140px] h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            evangelismMenu?.menu === "CREATE-ACTIVITY"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          Crear Actividad
        </button>
      </div>
    </div>
  );
}
