import { useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import DeptoContext from "../../context/Departments/Context";

import { fonts } from "../../assets/styles";

const schema = yup.object({
  name: yup
    .string()
    .required("Ingrese su nombre por favor.")
    .max(32, "Máximo 32 carácteres"),
  lastname: yup
    .string()
    .required("Ingrese su apellido por favor.")
    .max(32, "Máximo 32 carácteres"),
  email: yup
    .string()
    .email("Ingrese un correo válido por favor")
    .required("Ingrese su correo por favor")
    .max(64, "Máximo 64 carácteres"),
  phone: yup
    .number()
    .typeError("Debe ser un número válido")
    .required("Ingrese su número de teléfono por favor"),
});

export default function AddMemberTutor() {
  const { getAvailableMembers, setShowModal, setTutor } =
    useContext(DeptoContext);

  // form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (formdata) => {
    // localStorage.setItem("tutor_info", JSON.stringify(formdata));
    setTutor(formdata);
    setShowModal(false);
  };

  useEffect(() => {
    getAvailableMembers();
  }, []);

  return (
    <div className=" mms:mx-auto mms:w-[90%] w-[400px]  ">
      <div className="mb-8">
        <div className=" mb-6 text-center">
          <h1 className={`mb-1 ${fonts.heading3} text-title`}>
            Agregar Tutor del miembro
          </h1>
        </div>
        <div className=" w-full h-auto bg-bg3 rounded-[10px] overflow-y-auto">
          {/**/}
          {/**/}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] pb-[40px] w-full h-auto"
          >
            <div className=" mt-6 w-full">
              <label className="text-subtitle block mb-2">
                Nombre <span className="text-Red">*</span>
              </label>{" "}
              <input
                className=" indent-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
                {...register("name")}
                maxLength={32}
              />
              {<small className="text-red-500">{errors.name?.message}</small>}
            </div>

            <div className=" w-full">
              <label className="text-subtitle block mb-2">
                Apellido <span className="text-Red">*</span>
              </label>{" "}
              <input
                className=" indent-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
                {...register("lastname")}
                maxLength={32}
              />
              {
                <small className="text-red-500">
                  {errors.lastname?.message}
                </small>
              }
            </div>

            <div className=" mms:mb-6 w-full">
              <label className="text-subtitle mb-2 block">
                Correo Electrónico <span className="text-Red">*</span>
              </label>
              <input
                {...register("email")}
                className=" indent-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
                maxLength={64}
              />
              <small className="text-red-500">{errors.email?.message}</small>
            </div>
            {/* phone */}
            <div className=" mms:mb-6 w-full">
              <label className="text-subtitle mb-2 block">
                Teléfono <span className="text-Red">*</span>
              </label>
              <input
                {...register("phone")}
                className=" indent-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
                maxLength={16}
              />
              <small className="text-red-500">{errors.phone?.message}</small>
            </div>

            <div className=" flex justify-center mt-8 w-full">
              <button className="bg-primary text-white w-full h-[44px] rounded uppercase">
                Crear
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
