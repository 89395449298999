import axios from "axios";
import { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import DeptoContext from "../../../../context/Departments/Context";
import { fonts } from "../../../../assets/styles";
import { useNotifications } from "reapop";

export default function Integrants() {
  const { membersByDepartment, getMembersByDepartment } =
    useContext(DeptoContext);

  const { notify } = useNotifications();

  const { deptoId } = useParams();

  // remove member from the selected department
  const removeMemberFromDepartment = async (memberId) => {
    try {
      const { data } = await axios.delete(
        `/api/departments/${deptoId}/remove/member/${memberId}/`
      );
      getMembersByDepartment(deptoId); // update directors list
      notify({
        title: "¡Éxito!",
        message: data.message,
        status: "success",
      });
    } catch (error) {}
  };

  useEffect(() => {
    getMembersByDepartment(deptoId);
  }, []);

  return (
    <div className=" w-auto h-full bg-bg overflow-y-auto ">
      <div className="  mms:px-[10px] mms:pt-[20px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px] w-full h-auto ">
        <div className=" mb-4 text-center w-full ">
          <h1
            className={` mms:text-[24px] ts:text-[32px] ${fonts.heading4} text-title uppercase `}
          >
            Educadores
          </h1>
        </div>
        {/*   */}
        <div className=" mms:py-[20px] w-full min-h-[450px] h-auto rounded-[10px] p-[20px] bg-bg2 ">
          {membersByDepartment.length > 0 ? (
            membersByDepartment.map((member) => (
              <div
                className=" mms:flex-col mms:items-start mms:px-[10px] mms:h-auto mm:px-[20px] ts:flex-row py-[10px] h-[64px] px-[20px]
              flex justify-between items-center bg-bg3 rounded mb-[20px]"
                key={member.id}
              >
                <div>
                  <div>
                    <p className={`${fonts.heading3} text-title`}>
                      {member.name} {member.lastname}
                    </p>
                  </div>
                  <div className={` tm:flex ${fonts.body1} text-content`}>
                    <p>Email: {member.email}</p>
                    <p className=" mms:mx-0 tm:mx-4 ">
                      Teléfono: {member.phone}
                    </p>
                  </div>
                </div>
                <div className=" mms:mt-3 ">
                  <button
                    onClick={() => removeMemberFromDepartment(member.id)}
                    className=" px-[10px] h-[28px] bg-Red rounded "
                  >
                    Remover
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className=" text-center text-content ">
              <span>No hay integrantes</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
