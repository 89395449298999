import { useContext } from "react";

import DeptoContext from "../../../../context/Departments/Context";

export default function AfirmationHeaderAdmin() {
  const { setShowModal, setMenuModal, afirmationMenu, setAfirmationMenu } =
    useContext(DeptoContext);

  return (
    <div
      className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px]
    flex items-center px-[80px] h-[75px] w-full border-b border-borders overflow-x-auto "
    >
      {/* integrants */}
      <div className="flex items-center ">
        <button
          onClick={() => {
            localStorage.setItem(
              "afirmation_menu",
              JSON.stringify({ menu: "INTEGRANTS" })
            );
            setAfirmationMenu();
          }}
          className={` flex justify-center items-center h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            afirmationMenu.menu === "INTEGRANTS"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          Integrantes
        </button>
        {afirmationMenu?.menu === "INTEGRANTS" && (
          <button
            onClick={() => {
              setMenuModal("ADD-MEMBER");
              setShowModal(true);
            }}
            className={` flex justify-center items-center ml-2 h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
              true ? "bg-primary text-white" : "bg-bg2 text-content"
            }`}
          >
            Agregar
          </button>
        )}
      </div>
      <div className=" mx-2 w-[1px] h-[36px] border-r border-options "></div>
      {/* cdps */}
      <div className="flex items-center mr-4">
        <button
          onClick={() => {
            localStorage.setItem(
              "afirmation_menu",
              JSON.stringify({ menu: "CDPS" })
            );
            setAfirmationMenu();
          }}
          className={` flex justify-center items-center h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            afirmationMenu.menu === "CDPS" ||
            afirmationMenu.menu === "CDP-DETAILS"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          CDPs
        </button>
        <button
          onClick={() => {
            localStorage.setItem(
              "afirmation_menu",
              JSON.stringify({ menu: "CREATE-CDP" })
            );
            setAfirmationMenu();
          }}
          className={` flex justify-center items-center ml-2 h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            afirmationMenu.menu === "CREATE-CDP"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          Crear
        </button>
      </div>
    </div>
  );
}
