/*
 * Esta pagina es para mostra un menu de opciones para el departamento de educacion
 */
import { useContext } from "react";

import DeptoContext from "../../../context/Departments/Context";
import ProtectedDepartment from "../ProtectedDepartment";

// members
import VisitsNewConvertsList from "./members/VisitsNewConvertsList";
// admin
import VisitsHeaderAdmin from "./director/VisitsHeaderAdmin";
import VisitsIntegrants from "./director/VisitsIntegrants";

export default function VisitsContainer() {
  const { user } = useContext(DeptoContext);

  return (
    <ProtectedDepartment>
      <div className=" w-full h-full">
        {user?.is_director && <VisitsHeaderAdmin />}
        <div
          className={` ${user?.is_director ? "h-[calc(100%-75px)]" : "h-full"}`}
        >
          {Object.keys(user).length > 0 ? (
            user?.is_director ? (
              <VisitsIntegrants />
            ) : (
              <VisitsNewConvertsList />
            )
          ) : (
            <div>
              {" "}
              <h1>Cargando...</h1>
            </div>
          )}
        </div>
      </div>
    </ProtectedDepartment>
  );
}
