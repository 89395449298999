import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { format } from "date-fns";
import { useNotifications } from "reapop";

import DeptoContext from "../../../../context/Departments/Context";
import { fonts } from "../../../../assets/styles";
// import "./styles.css";

export default function LiberationRetreatsAttendance() {
  const { setLiberationMenu } = useContext(DeptoContext);
  const [retreats, setRetreats] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedRetreat, setSelectedRetreat] = useState(null);
  const [selectedProcess, setSelectedProcess] = useState(
    "Nuevo Creyente > Creciendo hacia Afuera"
  );

  const handleOnChangeProcess = (idProcess) => {
    // get all retreats by process id
    getAllRetreatsByProcess(idProcess);
    // get all students by unit (i unit where the student can take the retreat. Only in the Creciento hacia Arriba unit of the each process
    getAllStudentsByUnitId(idProcess * 3);
    setSelectedRetreat(null);
    setSelectedProcess(
      idProcess === "1"
        ? "Nuevo Creyente > Creciendo hacia Afuera"
        : idProcess === "2"
        ? "Discipulo > Creciendo hacia Afuera"
        : "Lider > Creciendo hacia Afuera"
    );
  };

  const getAllStudentsByUnitId = async (unitId) => {
    // unitId = idProcess * 3
    const { data } = await axios.get(
      `/api/v1/students/unit/${unitId}/retreat/`
    );
    setStudents(data);
  };

  const getAllRetreatsByProcess = async (idProcess) => {
    const { data } = await axios.get("/api/v1/retreats/" + idProcess);
    setRetreats(data);
  };
  useEffect(() => {
    const idProcess = 1;
    getAllRetreatsByProcess(idProcess); // 1 by default
    getAllStudentsByUnitId(idProcess * 3); // 3 by default
  }, []);

  return (
    <div className=" mms:px-[10px] mms:h-auto mm:px-[20px] ml:px-[40px] tm:px-[60px] tl:h-full ll:px-[80px] py-[20px] w-full overflow-y-auto ">
      {/* title */}
      <div className=" mb-3 pt-3 text-center bg-bg ">
        <h1 className={` mms:text-[24px] ts:text-[32px] text-title uppercase `}>
          Liberacion
        </h1>
        <p className=" text-content ">Asistencia a retiros</p>
      </div>

      {/* brearcrumbs */}
      <div className=" flex justify-start items-end mb-2 w-full h-[50px] bg-bg ">
        <p className=" text-content">
          <button
            onClick={() => {
              localStorage.setItem(
                "liberation_menu",
                JSON.stringify({ menu: "RETREATS" })
              );
              setLiberationMenu();
            }}
            className=" text-white"
          >
            Retiros
          </button>
          {" > "} Asistencias
        </p>
      </div>

      {/* content */}
      <div className=" mms:flex-col mms:h-auto lm:flex-row flex justify-between w-full h-full ">
        {/* left */}
        <div className=" mms:mb-[10px] mms:w-full lm:w-[40%] h-[calc(100%-150px)] rounded-[10px] ">
          {/* process type */}
          <div className=" w-full h-[100px] bg-bg2 rounded-[10px] ">
            <div className=" mb-6 px-[20px] w-full">
              <label className="text-subtitle block mb-2">Tipo de Retiro</label>{" "}
              <select
                onChange={(e) => handleOnChangeProcess(e.target.value)}
                className=" px-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
              >
                <option value="1">Nuevo Creyente</option>
                <option value="2">Discipulos</option>
                <option value="3">Lider</option>
              </select>
            </div>
          </div>

          {/* retreats list */}
          <div className=" mms:min-h-[400px] mms:h-[500px] mt-[10px] pb-[20px] w-full h-full bg-bg2 rounded-[10px] ">
            <div className=" w-full h-[40px] text-subtitle bg-bg2 text-center">
              <small>Retiros de hace 7 días en adelante.</small>
            </div>
            <div className=" flex flex-wrap w-full h-[calc(100%-40px)] overflow-y-auto ">
              {retreats.length > 0 ? (
                retreats.map((retreat) => (
                  <RetreatCard
                    retreat={retreat}
                    setSelectedRetreat={setSelectedRetreat}
                    key={retreat.id}
                  />
                ))
              ) : (
                <div className=" w-full text-content text-center ">
                  <p>No hay retiros</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* right */}
        <div className=" mms:w-full lm:w-[55%] h-[calc(100%-150px)] rounded-[10px] ">
          {/* selected retreat */}
          <div className=" mms:mb-[10px] mms:h-auto w-full h-[100px] bg-bg2 rounded-[10px] ">
            {selectedRetreat ? (
              <div className=" px-[20px] py-[10px] ">
                {/* type of retreat */}
                <p>
                  {selectedRetreat.id_retreats_type === 1
                    ? "Nuevo Creyente > Retiro de Bienvenida"
                    : selectedRetreat.id_retreats_type === 2
                    ? "Discipulo > Retiro de Discipulo"
                    : "Lider > Retiro de Lider"}
                </p>
                {/* date */}
                <p className=" text-title ">
                  Fecha:{" "}
                  <span className=" text-content ">
                    {format(
                      new Date(selectedRetreat.date),
                      "dd 'de' MMM 'de' yyyy"
                    )}
                  </span>
                </p>
                {/* managers */}
                <div className=" mms:flex-col flex ">
                  <p className=" text-title ">Encargados:</p>
                  <div className=" mms:max-h-[70px] w-[200px] max-w-[200px] overflow-x-auto ">
                    {selectedRetreat.managers
                      .split("\n")
                      .map((manager, index) => (
                        <p className=" mx-[1px] text-content" key={index}>
                          {" | "} {manager}
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className=" flex justify-center items-center w-full h-[100px] text-subtitle ">
                <p>Seleccione un retiro para la asistencia</p>
              </div>
            )}
          </div>

          {/* students list */}
          <div className=" mms:h-[300px] px-[20px] py-[10px] w-full tm:h-[500px] bg-bg2 rounded-[10px] overflow-y-auto ">
            {selectedProcess && (
              <div className=" mb-2 text-center text-content ">
                <small>
                  Estudiantes en el proceso de{" "}
                  <span className=" mms:block ">{selectedProcess}</span>
                </small>
              </div>
            )}
            {students.length > 0 ? (
              students.map((student) => (
                <Student
                  student={student}
                  selectedRetreat={selectedRetreat}
                  getAllStudentsByUnitId={getAllStudentsByUnitId}
                  key={student.id}
                />
              ))
            ) : (
              <div className=" text-center text-subtitle ">
                <p>No hay estudiantes en {selectedProcess}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const RetreatCard = ({ retreat, setSelectedRetreat }) => {
  let retreat_type = "Retiro de Bienvenida";
  if (retreat.id_retreats_type === 2) {
    retreat_type = "Retiro de Discipulo";
  } else if (retreat.id_retreats_type === 3) {
    retreat_type = "Retiro de Lider";
  }
  return (
    <div className=" mms:w-[90%] mm:w-[300px] mx-auto mb-[20px] p-[10px] h-auto bg-bg3 rounded-[10px]">
      <div className=" mb-3 pb-2 border-b border-options">
        <p className=" text-third">Tipo:</p>
        <p className=" text-content">{retreat_type}</p>
      </div>
      <div className=" mb-3  pb-2 border-b border-options">
        <p className=" text-third">Fecha y Hora:</p>
        <p className=" text-content">
          {format(new Date(retreat.date), "dd 'de' MMM 'de' yyyy")}
          {" | "}
          {retreat.hour}
        </p>
      </div>
      <div className=" mb-3 pb-2 border-b border-options">
        <p className=" text-third">
          {retreat.location.startsWith("https://") ? "Ubicación" : "Dirección"}
        </p>
        <div className=" text-content">
          {retreat.location.startsWith("https://") ? (
            <a
              href={retreat.location}
              target="_blank"
              rel="noreferrer"
              className=" flex justify-center items-center w-[100px] text-white bg-Green rounded "
            >
              {" "}
              Abrir <i className=" ml-2 fa-solid fa-location-arrow"></i>
            </a>
          ) : (
            <p className=" overflow-hidden whitespace-nowrap text-ellipsis ">
              {retreat.location}
            </p>
          )}
        </div>
      </div>
      <div className=" mb-3 pb-2 border-b border-options">
        <p className=" text-third">Encargados:</p>
        <ul className=" max-h-[70px] overflow-y-auto">
          {retreat.managers.split("\n").map((manager, index) => (
            <li className=" text-content" key={index}>
              {" "}
              - {manager}
            </li>
          ))}
        </ul>
      </div>
      <div className=" mb-3">
        <button
          onClick={() => setSelectedRetreat(retreat)}
          className=" flex justify-center items-center mt-2 w-full h-[36px] bg-primary text-white text-sm rounded "
        >
          Seleccionar para asignar
          <i className=" ml-2 text-xl fa-solid fa-check"></i>
        </button>
      </div>
    </div>
  );
};

const Student = ({ student, selectedRetreat, getAllStudentsByUnitId }) => {
  const { notify } = useNotifications();

  const handleAttendance = async () => {
    if (!selectedRetreat) {
      notify({
        title: "Aviso",
        message: "Seleccione un retiro para asignar la asistencia",
        status: "warning",
      });
      return;
    }

    // mark the student as attended
    const { data } = await axios.post("/api/v1/retreat_attendance/", {
      id_student: student.id,
      id_retreat: selectedRetreat.id,
    });
    if (data.status_code === 201) {
      getAllStudentsByUnitId(selectedRetreat.id_retreats_type * 3);
      notify({
        title: "Exito",
        message: "Asistencia asignada correctamente",
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: "Ocurrio un error al asignar la asistencia",
        status: "error",
      });
    }
  };

  return (
    <div className=" mms:flex-col mms:items-start ts:flex-row ts:items-center flex justify-between mb-[20px] px-[20px] py-2 bg-bg3 rounded ">
      <p className=" mms:mb-2 text-title ">
        {student.name} {student.lastname}
      </p>
      <div>
        <button
          onClick={handleAttendance}
          className=" px-[20px] h-[36px] bg-Green rounded "
        >
          Asistio <i className=" fa-solid fa-check-circle"></i>
        </button>
      </div>
    </div>
  );
};
