import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useNotifications } from "reapop";

import DeptoContext from "../../context/Departments/Context";
import { fonts } from "../../assets/styles";

export default function TranslateStudentModal() {
  const { getStudentsByUnit, setShowModal } = useContext(DeptoContext);
  const { notify } = useNotifications();

  // student
  const [student, setStudent] = useState(() => {
    const student = JSON.parse(localStorage.getItem("student"));
    return student !== null ? student : null;
  });
  // process
  const [currentProcess, setCurrentProcess] = useState(() => {
    const process = JSON.parse(localStorage.getItem("process"));
    return process !== null ? process : null;
  });
  const [process, setProcess] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState(null);
  const [showProcessOptions, setShowProcessOptions] = useState(false);
  // units
  const [currentUnit, setCurrentUnit] = useState(() => {
    const unit = JSON.parse(localStorage.getItem("unit"));
    return unit !== null ? unit : null;
  });
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [showUnitOptions, setShowUnitOptions] = useState(false);

  /*
   * Petición para trasladar a estudiante
   */
  const translateStudent = async () => {
    try {
      if (selectedUnit === null) {
        notify({
          title: "Aviso",
          message: "Seleccione una unidad por favor.",
          status: "warning",
        });
        return;
      }
      const { data } = await axios.put(
        `/api/v1/students/translate/${student.id}/${selectedUnit.id}/`
      );
      if (data.status_code === 200) {
        notify({
          title: "Exito",
          message: data.message,
          status: "success",
        });
        getStudentsByUnit(currentUnit.id); // get all students by unit
        setShowModal(false);
      } else {
        notify({
          title: "Error",
          message: data.message,
          status: "error",
        });
      }
    } catch (error) {
      notify({
        title: "Error",
        message:
          "Ha habido un error en el servidor. Intente nuevamente por favor.",
        status: "error",
      });
    }
  };

  /*
   * Show the Unit select
   */
  useEffect(() => {
    setShowProcessOptions(false);
  }, [selectedUnit]);
  /*
   * Cargar todas las unidades por proceso
   */
  const getUnitsByProcess = async () => {
    const { data } = await axios.get(
      `/api/v1/units/process/${selectedProcess.id}`
    );
    setUnits(data);
    setShowUnitOptions(false);
  };
  useEffect(() => {
    setSelectedUnit(null);
    selectedProcess !== null && getUnitsByProcess();
  }, [selectedProcess]);

  /*
   * Cargar todos los procesos
   */
  const getProcesses = async () => {
    const { data } = await axios.get("/api/v1/processes/");
    setProcess(data);
  };
  useEffect(() => {
    getProcesses();
  }, []);

  return (
    <div className=" px-[10px] w-full h-full ">
      <div className="mb-8">
        {/* title */}
        <div className=" mb-6 text-center">
          <h1 className={`mb-1 ${fonts.heading4} text-title`}>
            Trasladar estudiante
          </h1>
        </div>

        <div className=" mb-6 py-4 border-y border-bg3 ">
          <div className=" mms:flex-col ts:flex-row flex mb-2">
            <p className=" text-title">Estudiante:</p>
            <p className=" mms:ml-0 ts:ml-2 text-content">
              {student.name} {student.lastname}
            </p>
          </div>
          <div className=" mms:flex-col ts:flex-row flex">
            <p className=" text-title">Actualmente en:</p>
            <p className=" mms:ml-0 ts:ml-2 text-content">
              {currentProcess.name} {">"} {currentUnit.name}
            </p>
          </div>
        </div>

        {/* school filters */}
        <div className=" mb-6 ">
          <div className=" mb-2">
            <p className=" text-title">Trasladar a:</p>
          </div>
          <div className=" mms:flex-col ts:flex-row mms:h-[90px] flex py-0 w-full h-[40px]">
            <div className=" mms:mb-2 mms:w-full flex flex-col justify-center mr-[10px] w-[200px] h-[40px] ">
              <SelectOptions
                title={selectedProcess}
                noTitle="Seleccionar Proceso"
                items={process}
                setSelection={setSelectedProcess}
                showOptions={showProcessOptions}
                setShowOptions={setShowProcessOptions}
              />
            </div>
            <div className=" mms:w-full flex flex-col justify-center w-[230px] h-[40px] ">
              <SelectOptions
                title={selectedUnit}
                noTitle="Seleccionar unidad"
                items={units}
                setSelection={setSelectedUnit}
                showOptions={showUnitOptions}
                setShowOptions={setShowUnitOptions}
              />
            </div>
          </div>
        </div>

        {/* tranladar action */}
        <div className=" my-1 ">
          <div className=" my-[16px] h-[48px]">
            <button
              onClick={translateStudent}
              className=" w-full h-full bg-Green rounded"
            >
              Trasladar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const SelectOptions = ({
  title,
  noTitle,
  items = [],
  setSelection,
  showOptions,
  setShowOptions,
}) => {
  // const [showCategoryOptions, setShowCategoryOptions] = useState(false);

  return (
    <div className=" w-full h-full">
      <div className=" h-full">
        <div className=" relative w-full h-full bg-bg3 rounded">
          <button
            type="button"
            onClick={() => setShowOptions(!showOptions)}
            className="flex justify-between items-center px-4 w-full h-full text-content"
          >
            {title ? (
              <span className=" text-title ">{title.name}</span>
            ) : (
              <span className=" text-content ">{noTitle}</span>
            )}
            <i className="text-white fa-solid fa-angle-down"></i>
          </button>
          <div
            className={`absolute top-[calc(45px+0px)] z-10 w-full bg-bg3
                  ${showOptions ? "block" : "hidden"}
                `}
          >
            {items.length > 0 ? (
              items.map((item, i) => (
                <button
                  type="button"
                  onClick={() => {
                    setSelection(item);
                    setShowOptions(false);
                  }}
                  className="flex items-center px-2 w-full h-[50px] text-content hover:text-title hover:bg-bg4"
                  key={i}
                >
                  {item.name}
                </button>
              ))
            ) : (
              <div className=" flex items-center justify-center h-[32px] text-content ">
                <small>No hay opciones</small>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
