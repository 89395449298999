import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useNotifications } from "reapop";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { fonts } from "../../../../assets/styles";

const schema = yup.object().shape({
  address: yup
    .string()
    .required("Ingrese una dirección por favor")
    .max(64, "Máximo 64 carácteres"),
  day: yup.string().required("Ingrese un día por favor"),
  hour: yup.string().required("Ingrese una hora por favor"),
});

export default function AfirmationIntegrants() {
  // new
  const [allMembers, setAllMembers] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [cdpLeaderOne, setCdpLeaderOne] = useState(null); // cdpLeaderOne > discipleshipLeaderOne
  const [cdpLeaderTwo, setCdpLeaderTwo] = useState(null); // cdpLeaderTwo > discipleshipLeaderTwo
  const [selectedNetwork, setSelectedNetwork] = useState(null);

  const inputCodeRef = useRef();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { notify } = useNotifications();

  const onSubmit = async (formdata) => {
    if (!cdpLeaderOne) {
      return notify({
        title: "Aviso",
        message: "Seleccione al menos un líder de Discipulado",
        status: "warning",
      });
    }
    if (!selectedNetwork) {
      return notify({
        title: "Aviso",
        message: "Seleccione una Red por favor",
        status: "warning",
      });
    }
    const discipleshipData = {
      leader_one_fullname: `${cdpLeaderOne.name} ${cdpLeaderOne.lastname}`,
      leader_one_email: cdpLeaderOne.email,
      leader_one_code: cdpLeaderOne.code,
      leader_two_fullname: cdpLeaderTwo
        ? `${cdpLeaderTwo.name} ${cdpLeaderTwo.lastname}`
        : null,
      leader_two_email: cdpLeaderTwo ? cdpLeaderTwo.email : null,
      leader_two_code: cdpLeaderTwo ? cdpLeaderTwo.code : null,
      address: formdata.address,
      day: formdata.day,
      hour: formdata.hour,
      id_network: selectedNetwork, // this has the id of the network
    };
    const { data } = await axios.post(
      "/api/v1/discipleships",
      discipleshipData
    );
    if (data.status_code === 201) {
      reset();
      localStorage.removeItem("cdpNetworkLeaderOne");
      localStorage.removeItem("cdpNetworkLeaderTwo");
      setCdpLeaderOne(null);
      setCdpLeaderTwo(null);
      setSelectedNetwork(null);
      getAllMembers();
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
  };

  useEffect(() => {
    localStorage.getItem("cdpDiscipleshipLeaderOne") &&
      setCdpLeaderOne(
        JSON.parse(localStorage.getItem("cdpDiscipleshipLeaderOne"))
      );
    localStorage.getItem("cdpDiscipleshipLeaderTwo") &&
      setCdpLeaderTwo(
        JSON.parse(localStorage.getItem("cdpDiscipleshipLeaderTwo"))
      );
  }, [setCdpLeaderOne, setCdpLeaderTwo]);

  const getMemberByFilternName = async (name) => {
    if (name.length > 0) {
      const membersResp = await axios.get(`/api/v1/members/search/${name}`);
      const netIntegrants = await axios.get("/api/v1/networks_integrants");
      const data = membersResp.data.filter((member) => {
        return !netIntegrants.data.some(
          (integrant) => integrant.id_member === member.id
        );
      });
      data.sort((a, b) => a.name.localeCompare(b.name));
      setAllMembers(data);
    } else {
      getAllMembers();
    }
  };

  const getAllMembers = async () => {
    const membersResp = await axios.get("/api/v1/members"); // all members (including leaders but not disciples)
    // filtrar los miembros que no estan en la tabla networks_integrants
    const netIntegrants = await axios.get("/api/v1/networks_integrants");
    const data = membersResp.data.filter((member) => {
      return !netIntegrants.data.some(
        (integrant) => integrant.id_member === member.id
      );
    });
    data.sort((a, b) => a.name.localeCompare(b.name));
    setAllMembers(data);
  };
  const getAllNetworks = async () => {
    const { data } = await axios.get("/api/v1/networks");
    setNetworks(data);
  };
  useEffect(() => {
    getAllMembers();
    getAllNetworks();
  }, []);

  return (
    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px] py-[20px] h-full overflow-y-auto bg-bg ">
      <div className=" mms:px-[10px] mm:px-[20px]  px-[40px] py-[20px] min-h-[500px] max-h-auto w-full bg-bg2 rounded-[10px] overflow-y-auto">
        {/* title */}
        <div className=" mb-4 text-center w-full">
          <h1 className={`text-title ${fonts.heading4} uppercase`}>
            Crear Discipulado
          </h1>
        </div>
        <div className=" mms:px-[10px] mm:px-[20px] mms:flex-col-reverse ls:flex-row flex justify-between border border-options rounded-[10px] ">
          {/* create network form */}
          <div className="  mms:w-full w-[45%] p-[10px] h-auto">
            <div className=" mb-[32px] w-full h-[180px] ">
              {cdpLeaderOne ? (
                <div className=" flex justify-between items-center py-[10px] h-[95px] ">
                  <div>
                    <p className=" text-title ">Líder de Red 1</p>
                    <p className=" text-subtitle ">
                      {cdpLeaderOne.name} {cdpLeaderOne.lastname}{" "}
                    </p>
                    <small className=" text-content ">
                      Email: {cdpLeaderOne.email}
                    </small>
                  </div>
                  <button
                    onClick={() => {
                      localStorage.removeItem("cdpDiscipleshipLeaderOne");
                      setCdpLeaderOne(null);
                    }}
                    className=" px-[10px] h-[32px] w-auto bg-Red rounded"
                  >
                    Quitar
                  </button>
                </div>
              ) : (
                <div className=" flex justify-center items-center pb-[10px] h-[85px] ">
                  <p className=" text-content ">
                    Seleccione Líder 1 de Discipulado
                  </p>
                </div>
              )}
              {cdpLeaderTwo ? (
                <div className=" flex justify-between items-center py-[10px] h-[95px]  border-y border-options ">
                  <div>
                    <p className=" text-title ">Líder de Red 2</p>
                    <p className=" text-subtitle ">
                      {cdpLeaderTwo.name} {cdpLeaderTwo.lastname}
                    </p>
                    <small className=" text-content ">
                      Email: {cdpLeaderTwo.email}
                    </small>
                  </div>
                  <button
                    onClick={() => {
                      localStorage.removeItem("cdpDiscipleshipLeaderTwo");
                      setCdpLeaderTwo(null);
                    }}
                    className=" px-[10px] h-[32px] w-auto bg-Red rounded"
                  >
                    Quitar
                  </button>
                </div>
              ) : (
                <div className=" flex justify-center items-center py-[10px] h-[95px]  border-y border-options ">
                  <p className=" text-content ">
                    Seleccione un Líder 2 de Discipulado
                  </p>
                </div>
              )}
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              {/* address */}
              <div className=" mb-6 w-full">
                <label className="text-subtitle block mb-2">Dirección</label>
                <input
                  className=" indent-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
                  {...register("address")}
                  maxLength={64}
                />
                <small className="text-red-500">
                  {errors.address?.message}
                </small>
              </div>

              <div className=" flex justify-between">
                {/* day */}
                <div className=" mms:mb-6 w-[45%]">
                  <label className="text-subtitle mb-2 block">
                    Día <span className="text-Red">*</span>
                  </label>
                  <select
                    className=" px-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
                    {...register("day", { required: true })}
                  >
                    <option value="Lunes">Lunes</option>
                    <option value="Martes">Martes</option>
                    <option value="Miercóles">Miercóles</option>
                    <option value="Jueves">Jueves</option>
                    <option value="Viernes">Viernes</option>
                    <option value="Sábado">Sábado</option>
                    <option value="Domingo">Domingo</option>
                  </select>
                  <small className="text-red-500">{errors.day?.message}</small>
                </div>

                {/* Hour */}
                <div className=" mms:mb-6 w-[45%]">
                  <label className="text-subtitle mb-2 block">
                    Hora <span className="text-Red">*</span>
                  </label>
                  <select
                    className=" px-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
                    {...register("hour", { required: true })}
                  >
                    <option value="07:00 AM">07:00 AM</option>
                    <option value="08:00 AM">08:00 AM</option>
                    <option value="09:00 AM">09:00 AM</option>
                    <option value="10:00 AM">10:00 AM</option>
                    <option value="11:00 AM">11:00 AM</option>
                    <option value="12:00 PM">12:00 PM</option>
                    <option value="13:00 PM">01:00 PM</option>
                    <option value="14:00 PM">02:00 PM</option>
                    <option value="15:00 PM">03:00 PM</option>
                    <option value="16:00 PM">04:00 PM</option>
                    <option value="17:00 PM">05:00 PM</option>
                    <option value="18:00 PM">06:00 PM</option>
                    <option value="19:00 PM">07:00 PM</option>
                    <option value="20:00 PM">08:00 PM</option>
                    <option value="21:00 PM">09:00 PM</option>
                    <option value="22:00 PM">10:00 PM</option>
                    <option value="23:00 PM">11:00 PM</option>
                  </select>
                  <small className="text-red-500">{errors.hour?.message}</small>
                </div>
              </div>

              {/* networks select */}
              <div className=" my-6 w-full ">
                <label className="text-subtitle block mb-2">
                  Seleccionar Red <span className="text-Red">*</span>
                </label>{" "}
                <select
                  onChange={(e) => setSelectedNetwork(e.target.value)}
                  className=" px-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
                  defaultValue={selectedNetwork}
                >
                  <option value="">Seleccione una opción</option>
                  {networks.map((network) => (
                    <option value={network.id} key={network.id}>
                      {network.name + " | " + network.leader_one_fullname}
                      {network.leader_two_fullname &&
                        network.leader_two_fullname}
                    </option>
                  ))}
                </select>
              </div>

              <div className=" flex justify-start mt-8 w-full">
                <button
                  // onClick={handleSubmit}
                  className="bg-primary text-white w-[130px] h-[44px] rounded uppercase"
                >
                  Crear
                </button>
              </div>
            </form>
          </div>

          {/* list users */}
          <div className=" mms:border-l-0 ls:border-l mms:w-full w-[55%] p-[10px] h-auto border-options ">
            {/* filter */}
            <div className=" flex justify-between mb-[20px] w-full h-[44px] bg-bg ">
              <input
                type="text"
                onChange={(e) => getMemberByFilternName(e.target.value)}
                ref={inputCodeRef}
                placeholder="Buscar usuario por nombre"
                className=" indent-3 w-[calc(100%-44px)] h-full bg-bg3 placeholder-gray-500 outline-none "
              />
              <div
                onClick={() => {
                  inputCodeRef.current.value = "";
                  getAllMembers();
                }}
                className=" flex justify-center items-center w-[44px] h-full bg-bg cursor-pointer"
              >
                <i className=" text-[24px] fa-solid fa-times"></i>
              </div>
            </div>
            <div className=" h-auto ">
              <div className="p-[20px] w-full h-[450px] bg-bg3 rounded-[10px] overflow-y-auto ">
                {allMembers.length > 0 ? (
                  allMembers.map((member) => (
                    <UserCardList
                      {...{
                        member,
                        setCdpLeaderOne,
                        setCdpLeaderTwo,
                      }}
                      key={member.id}
                    />
                  ))
                ) : (
                  <div className=" text-center ">
                    <p className=" text-content ">
                      {inputCodeRef.current?.value !== ""
                        ? "El código no existe"
                        : "No hay usuarios disponibles"}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// components userList
const UserCardList = ({ member, setCdpLeaderOne, setCdpLeaderTwo }) => {
  if (
    member?.code ===
      JSON.parse(localStorage.getItem("cdpDiscipleshipLeaderOne"))?.code ||
    member?.code ===
      JSON.parse(localStorage.getItem("cdpDiscipleshipLeaderTwo"))?.code
  ) {
    return;
  }

  return (
    <div className=" mms:flex-col mms:items-start mms:h-auto mms:border-b-none lxl:flex-row lxl:items-center border-b py-[10px] flex justify-between items-center h-[56px] border-options">
      <div className=" mms:mb-2 lxl:mb-0 ">
        <p className={`text-title`}>
          {member.name} {member.lastname}
        </p>
      </div>
      <div>
        <button
          onClick={() => {
            localStorage.setItem("cdpNetworkLeaderOne", JSON.stringify(member));
            setCdpLeaderOne(member);
          }}
          className=" mr-[10px] w-[80px] h-[28px] bg-primary rounded-[5px]"
        >
          Líder 1
        </button>

        <button
          onClick={() => {
            localStorage.setItem("cdpNetworkLeaderTwo", JSON.stringify(member));
            setCdpLeaderTwo(member);
          }}
          className="w-[95px] h-[28px] bg-primary rounded-[5px]"
        >
          Líder 2
        </button>
      </div>
    </div>
  );
};
