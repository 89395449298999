// members
export const GET_AVAILABLE_MEMBERS = "GET_AVAILABLE_MEMBERS";
export const GET_MEMBERS_TO_BE_STUDENTS = "GET_MEMBERS_TO_BE_STUDENTS";

export const GET_DIRECTORS_BY_DEPARMENT = "GET_DIRECTORS_BY_APARMENT";
export const GET_MEMBERS_BY_DEPARTMENT = "GET_MEMBERS_BY_DEPARTMENT";
export const GET_ALL_DEPARTMENTS = "GET_ALL_DEPARTMENTS";

// select a department menu
export const EDUCATION_MENU = "EDUCATION_MENU";
export const AFIRMATION_MENU = "AFIRMATION_MENU";
export const VISITS_MENU = "VISITS_MENU";
export const EVANGELISM_MENU = "EVANGELISM_MENU";
export const INTERSETION_MENU = "INTERSETION_MENU";
export const LIBERATION_MENU = "LIBERATION_MENU";
export const NETWORKS_MENU = "NETWORKS_MENU";
export const NETWORKS_MEMBER_MENU = "NETWORKS_MEMBER_MENU";

// get current user
export const GET_USER = "GET_USER";

// modal
export const SHOW_MODAL = "SHOW_MODAL";
export const MENU_MODAL = "MENU_MODAL";

// tutor
export const SET_TUTOR = "SET_TUTOR";

// student
export const GET_STUDENTS_BY_UNIT = "GET_STUDENTS_BY_UNIT";
export const SELECTED_STUDENT_TO_PETITION = "SELECTED_STUDENT_TO_PETITION";

// CDPs
export const SET_SELECTED_CDP = "SET_SELECTED_CDP";

// Selected Affirmer
export const SELECTED_AFFIRMER = "SELECTED_AFFIRMER";

// Netwoks
export const SELECTED_DISCIPLESHIP = "SELECTED_DISCIPLESHIP";
export const SELECTED_NETWORK = "SELECTED_NETWORK";

// admin
export const ADMIN_USER_DETAILS = "ADMIN_USER_DETAILS";

