/*
 * Esta pagina es para mostra un menu de opciones para el departamento de educacion
 */
import { useEffect, useContext } from "react";

import DeptoContext from "../../../context/Departments/Context";
import ProtectedDepartment from "../ProtectedDepartment";

// members
import AfirmationMenuAsignations from "./members/AfirmationMenuAsignations";
// admin
import AfirmationHeaderAdmin from "./director/AfirmationHeaderAdmin";
import AfirmationIntegrants from "./director/AfirmationIntegrants";
import AfirmationCreateCDP from "./director/AfirmationCreateCDP";
import AfirmationCDPsList from "./director/AfirmationCDPsList";
import AfirmationCDPDetails from "./director/AfirmationCDPDetails";

export default function AfirmationContainer() {
  const { setAfirmationMenu, user } = useContext(DeptoContext);

  const changeAdminMenu = () => {
    switch (JSON.parse(localStorage.getItem("afirmation_menu"))?.menu) {
      case "INTEGRANTS":
        return <AfirmationIntegrants />;
      case "CDPS":
        return <AfirmationCDPsList />;
      case "CREATE-CDP":
        return <AfirmationCreateCDP />;
      case "CDP-DETAILS":
        return <AfirmationCDPDetails />;
      default:
        return <AfirmationIntegrants />;
    }
  };

  useEffect(() => {
    // esto es para que cuando se cambie el menu en los miembros, se reinicie en el admin a "INTEGRANTS"
    if (user?.is_director) {
      // si no hay nada en el localstorage
      if (!localStorage.getItem("afirmation_menu")) {
        localStorage.setItem(
          "afirmation_menu",
          JSON.stringify({ menu: "INTEGRANTS" }) // restart admin menu to "INTEGRANTS"
        );
      }
    }

    setAfirmationMenu();
  }, []);

  return (
    <ProtectedDepartment>
      <div className=" w-full h-full bg-bg ">
        {user?.is_director && <AfirmationHeaderAdmin />}
        <div
          className={` ${user?.is_director ? "h-[calc(100%-75px)]" : "h-full"}`}
        >
          {Object.keys(user).length > 0 ? (
            user?.is_director ? (
              changeAdminMenu()
            ) : (
              <AfirmationMenuAsignations />
            )
          ) : (
            <div>
              <h1>Cargando...</h1>
            </div>
          )}
        </div>
      </div>
    </ProtectedDepartment>
  );
}
