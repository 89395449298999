import axios from "axios";
import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useNotifications } from "reapop";

import { fonts } from "../../../../assets/styles";

export default function UnsubscribeStudents() {
  const [studentsByUnit, setStudentsByUnit] = useState([]);
  // process
  const [process, setProcess] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState(null);
  // units
  const [units, setUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  // show select options
  const [showProcessOptions, setShowProcessOptions] = useState(false);
  const [showUnitOptions, setShowUnitOptions] = useState(false);

  const getStudentsByUnit = async (unitId) => {
    const { data } = await axios.get(`/api/v1/students/all/unit/${unitId}/`);
    data.sort((a, b) => a.name.localeCompare(b.name));
    setStudentsByUnit(data);
  };

  useEffect(() => {
    if (selectedUnit !== null) {
      setShowProcessOptions(false); // hide all select options
      selectedUnit && getStudentsByUnit(selectedUnit.id); // get all students by unit
    }
  }, [selectedUnit]);

  /*
   * Cargar todas las unidades por proceso
   */
  const getUnitsByProcess = async () => {
    const { data } = await axios.get(
      `/api/v1/units/process/${selectedProcess.id}`
    );
    setUnits(data);
  };
  useEffect(() => {
    if (selectedProcess !== null) {
      getUnitsByProcess();
      setShowUnitOptions(false); // hide all select options
      setStudentsByUnit([]); // reset students

      setSelectedUnit(null);
    }
  }, [selectedProcess]);

  /*
   * Cargar todos los procesos
   */
  const getProcesses = async () => {
    const { data } = await axios.get("/api/v1/processes/");
    setProcess(data);
  };
  useEffect(() => {
    getProcesses();
  }, []);

  // const handleBlurOne = () => {
  //   setShowProcessOptions(false);
  // };
  // const handleBlurTwo = () => {
  //   setShowUnitOptions(false);
  // };

  return (
    <div
      className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px] mms:py-[40px] ts:py-[20px]
    px-[80px] h-full bg-bg "
    >
      {/* title */}
      <div className=" mb-4 text-center w-full ">
        <h1 className={` mms:text-[24px] ts:text-32px text-title uppercase `}>
          Dar de baja estudiantes
        </h1>
      </div>

      {/* header options */}
      <div className=" mms:px-[10px] mms:flex-col mms:py-2 mms:h-auto mm:px-[20px] ml:px-[40px] ts:flex-row flex justify-start items-center mb-[24px] w-full h-[80px] bg-bg2 rounded-[10px] ">
        <div className=" mms:mb-2 mms:w-full ts:mb-0 tm:w-[200px] flex flex-col justify-center ts:mr-[10px] h-full ">
          <SelectOptions
            title={selectedProcess}
            noTitle="Seleccionar Proceso"
            items={process}
            setSelection={setSelectedProcess}
            showOptions={showProcessOptions}
            setShowOptions={setShowProcessOptions}
            // handleBlur={handleBlurOne}
          />
        </div>
        <div className=" mms:w-full tm:w-[230px] flex flex-col justify-center h-full ">
          <SelectOptions
            title={selectedUnit}
            noTitle="Seleccionar Unidad"
            items={units}
            setSelection={setSelectedUnit}
            showOptions={showUnitOptions}
            setShowOptions={setShowUnitOptions}
            // handleBlur={handleBlurTwo}
          />
        </div>
      </div>

      {/* students container */}
      <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] py-[40px] w-full min-h-[450px] h-auto bg-bg2 rounded-[10px] overflow-y-auto">
        {selectedUnit === null && (
          <p className=" w-full text-content text-center">
            Aquí se mostraran los estudiantes del Proceso y Unidad seleccionados
          </p>
        )}
        {selectedUnit !== null &&
        studentsByUnit !== null &&
        studentsByUnit.length === 0 ? (
          <p className=" w-full text-content text-center">
            No hay estudiantes en esta unidad
          </p>
        ) : (
          studentsByUnit?.map((student) => (
            <StudentCard
              student={student}
              key={student.id}
              getStudentsByUnit={getStudentsByUnit}
            />
          ))
        )}
      </div>
    </div>
  );
}

const SelectOptions = ({
  title,
  noTitle,
  items = [],
  setSelection,
  showOptions,
  setShowOptions,
  // handleBlur
}) => {
  return (
    <div className=" w-full ">
      <div className="">
        <div className=" relative w-full bg-bg3 rounded">
          <button
            type="button"
            onClick={() => setShowOptions(!showOptions)}
            className="flex justify-between items-center px-4 w-full h-[50px] text-content"
          >
            {title ? (
              <>
                <span className=" text-white">{title.name}</span>
                <i className="text-white fa-solid fa-angle-down"></i>
              </>
            ) : (
              <>
                <span className=" text-content">{noTitle}</span>
                <i className="text-content fa-solid fa-angle-down"></i>
              </>
            )}
          </button>
          <div
            className={`absolute top-[calc(50px+10px)] z-10 w-full bg-bg3 border-x border-b border-bg shadow-lg rounded-b
                  ${showOptions ? "block" : "hidden"}
                `}
          >
            {items.length > 0 ? (
              items.map((item, i) => (
                <button
                  type="button"
                  onClick={() => {
                    setSelection(item);
                    setShowOptions(false);
                  }}
                  className="flex items-center px-2 w-full h-[50px] text-content hover:text-title hover:bg-bg4 "
                  key={i}
                >
                  {item.name}
                </button>
              ))
            ) : (
              <div className=" flex items-center justify-center h-[32px] text-content ">
                <small>No hay opciones</small>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const StudentCard = ({ student, getStudentsByUnit }) => {
  // const [process, setProcess] = useState({}); // process name

  const { notify } = useNotifications();

  const confirmUnsubscribe = () => {
    confirmAlert({
      title: "Dar de baja estudiante",
      message:
        "¿Está seguro de dar de baja a este estudiante? Se borrara su progreso de estudiante. (Podrá agregarlo nuevamente).",
      buttons: [
        {
          label: "Confirmar",
          onClick: () => unsubscribeStudent(),
        },
        {
          label: "Cancelar",
        },
      ],
    });
  };

  const unsubscribeStudent = async () => {
    try {
      const { data } = await axios.post(
        `/api/v1/students/unsubscribe`,
        student
      );
      if (data.status_code === 200) {
        getStudentsByUnit(student.id_unit);
        notify({
          title: "Exito",
          message: data.message,
          status: "success",
        });
      } else {
        notify({
          title: "Error",
          message: data.message,
          status: "error",
        });
      }
    } catch (error) {
      notify({
        title: "Error",
        message: `Ha habido un error en el servidor. Intente nuevamente por favor.`,
        status: "error",
      });
    }
  };

  const confirmFinishedStudentProcess = () => {
    confirmAlert({
      title: "Finalizar proceso de estudiante",
      message:
        "¿Finalizar el proceso de este estudiante? Se limpiaran sus datos de estudiante.",
      buttons: [
        {
          label: "Confirmar",
          onClick: () => finishedStudentProcess(),
        },
        {
          label: "Cancelar",
        },
      ],
    });
  };

  const finishedStudentProcess = async () => {
    try {
      const { data } = await axios.delete(
        `/api/v1/students/finished-process/${student.id}`
      );
      if (data.status_code === 200) {
        getStudentsByUnit(student.id_unit);
        notify({
          title: "Exito",
          message: data.message,
          status: "success",
        });
      } else {
        notify({
          title: "Error",
          message: data.message,
          status: "error",
        });
      }
    } catch (error) {
      notify({
        title: "Error",
        message: `Ha habido un error en el servidor. Intente nuevamente por favor.`,
        status: "error",
      });
    }
  };

  return (
    <div
      className={` mms:flex-col mms:items-start ts:flex-row ts:items-center flex justify-between items-center mb-[20px] px-[20px] py-[10px] w-full min-h-[64px] bg-bg3 text-title ${fonts.heading3} rounded `}
      key={student.id}
    >
      <div className=" mms:mb-2 ">
        <p>
          {student.name} {student.lastname}
        </p>
        {student.id_unit === 9 && student.retreat_leaders && (
          <small className=" text-[14px] text-Green ">
            Retiro de Líder <i className=" fa-solid fa-check-circle "></i>
          </small>
        )}
      </div>
      <div className={` ${fonts.body1}`}>
        {student.id_unit === 9 && student.retreat_leaders ? (
          <button
            onClick={confirmFinishedStudentProcess}
            className=" mms:ml-0 ts:ml-[10px] px-[10px] w-auto h-[34px] text-white bg-primary rounded "
          >
            Finalizar
          </button>
        ) : (
          <button
            onClick={confirmUnsubscribe}
            className=" mms:ml-0 ts:ml-[10px] px-[10px] w-auto h-[34px] text-white bg-Red rounded "
          >
            Dar de baja
          </button>
        )}
      </div>
    </div>
  );
};
