import axios from "axios";
import { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import DeptoContext from "../../../../context/Departments/Context";
import { fonts } from "../../../../assets/styles";
import { useNotifications } from "reapop";

export default function AfirmationIntegrants() {
  const { membersByDepartment, getMembersByDepartment } =
    useContext(DeptoContext);

  const { notify } = useNotifications();

  const { deptoId } = useParams();

  // remove member from the selected department
  const removeMemberFromDepartment = async (member) => {
    // verify if the member doesn't have any asignation
    try {
      const {
        data: { new_converts },
      } = await axios.post(`/api/v1/new_convert/affirmer/asignations/`, {
        email_affirmer: member.email,
      });
      // remove affirmer from department
      if (new_converts.length === 0) {
        const { data } = await axios.delete(
          `/api/departments/${deptoId}/remove/member/${member.id}/`
        );
        getMembersByDepartment(deptoId); // update directors list
        notify({
          title: "¡Éxito!",
          message: data.message,
          status: "success",
        });
        return;
      } else {
        notify({
          title: "Advertencia",
          message: "Por favor elimine las asignaciones antes de remover.",
          status: "warning",
        });
      }
    } catch (error) {}
  };

  useEffect(() => {
    getMembersByDepartment(deptoId);
  }, []);

  return (
    <div className=" mms:h-auto tl:h-full py-[40px] w-full overflow-y-auto bg-bg ">
      <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] tm:px-[60px] ll:px-[80px] w-full h-auto ">
        <div className="w-full text-center mb-[32px]">
          <h1
            className={` mms:text-[24px] ts:text-[32px] text-title ${fonts.heading4} uppercase `}
          >
            Afirmadores
          </h1>
        </div>

        <div className="  mms:py-[20px] w-full min-h-[400px] h-auto rounded-[10px] p-[20px] bg-bg2 ">
          {membersByDepartment.length > 0 ? (
            membersByDepartment.map((member) => (
              <AffirmerCard
                {...{ member, removeMemberFromDepartment }}
                key={member.id}
              />
            ))
          ) : (
            <div>
              <p className=" text-center text-content ">No hay afirmadores.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const AffirmerCard = ({ member, removeMemberFromDepartment }) => {
  const { setShowModal, setMenuModal, setSelectedAffirmer } =
    useContext(DeptoContext);

  return (
    <div
      className=" mms:flex-col mms:items-start mms:py-[10px] mms:h-auto ts:flex-row ts:items-center
    flex justify-between items-center h-[64px] px-[20px] bg-bg3 rounded-[10px] mb-[20px]"
    >
      <div>
        <div>
          <p className={`${fonts.heading3} text-title`}>
            {member.name} {member.lastname}
          </p>
        </div>
        <div className={`${fonts.body1} text-content`}>
          <span className="">Teléfono: {member.phone}</span>
        </div>
      </div>
      <div className=" mms:mt-1 mms:mb-4 ts:m-0 text-center">
        <button
          onClick={() => {
            setSelectedAffirmer(member);
            setShowModal(true);
            setMenuModal("AFFIRMER-DETAILS");
          }}
        >
          <span className=" underline mr-2">Detalles</span>
          <i className=" fa-solid fa-up-right-from-square"></i>
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            setSelectedAffirmer(member);
            setShowModal(true);
            setMenuModal("ASIGN-AFFIRMER");
          }}
          className=" mr-[10px] px-[10px] h-[28px] bg-primary rounded"
        >
          Asignar
        </button>
        <button
          onClick={() => removeMemberFromDepartment(member)}
          className=" px-[10px] h-[28px] bg-Red rounded-[5px]"
        >
          Remover
        </button>
      </div>
    </div>
  );
};
