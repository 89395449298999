import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNotifications } from "reapop";

const schema = yup.object({
  name: yup
    .string()
    .required("Ingrese un nombre de actividad por favor.")
    .max(64, "Máximo 64 carácteres"),
  location: yup.string().required("Ingrese una ubicación/dirección por favor."),
  date: yup.string().required("Ingrese una fecha por favor."),
  // hour: yup.string().required("Ingrese una hora por favor."),
  details: yup
    .string()
    .required("Ingrese los detalles por favor.")
    .max(1024, "Máximo 1024 carácteres"),
  managers: yup
    .string()
    .required("Ingrese a los encargados por favor.")
    .max(256, "Máximo 256 carácteres"),
});

export default function AfirmationIntegrants() {
  // const { membersByDepartment, getMembersByDepartment } =
  //   useContext(DeptoContext);
  const { notify } = useNotifications();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit = async (formdata) => {
    // console.log(formdata);
    const { data } = await axios.post("/api/v1/evangelism_activity/", formdata);
    if (data.status_code === 201) {
      reset();
      notify({
        title: "Exito",
        message: data.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
  };

  return (
    <div className="mms:px-[10px] mms:h-auto mm:px-[20px] ml:px-[40px] tl:h-full ll:px-[80px] py-[20px] bg-bg overflow-y-auto ">
      {/* title */}
      <div className="w-full text-center mb-[18px]">
        <h1 className={` mms:text-[24px] ts:text-[28px] text-title uppercase `}>
          Crear actividad de Evangelismo
        </h1>
      </div>

      {/* content */}
      <div className=" mx-auto h-auto w-full rounded ">
        {/* form */}
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" mms:px-[10px] mms:w-full mm:px-[20px] ml:px-[40px] mx-auto py-[20px] ts:w-[500px] h-auto bg-bg2 rounded-[10px] "
        >
          {/* name */}
          <div className=" mb-6 w-full">
            <label className="text-subtitle block mb-2">
              Nombre <span className="text-Red">*</span>
            </label>{" "}
            <input
              className=" indent-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
              {...register("name")}
              maxLength={64}
            />
            {<small className="text-red-500">{errors.name?.message}</small>}
          </div>

          {/* location */}
          <div className=" mb-6 w-full">
            <label className="text-subtitle block mb-2">
              Ubicación {"(URL)"} / Dirección
              <small className=" block text-content">
                Copiar de la opción "Compartir" en Google Maps
              </small>
            </label>{" "}
            <input
              className=" indent-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
              {...register("location")}
              maxLength={128}
            />
            {<small className="text-red-500">{errors.location?.message}</small>}
          </div>

          <div className=" mms:flex-col ts:flex-row flex justify-between">
            {/* date */}
            <div className=" mms:w-full ts:w-[45%] mb-6 ">
              <label className="text-subtitle block mb-2">
                Fecha <span className="text-Red">*</span>
              </label>{" "}
              <input
                // className=" indent-2 w-full h-[44px] bg-bg4 text-content outline-none rounded-[10px]"
                className="w-full p-2 bg-bg4 rounded shadow-sm text-content focus:outline-none focus:shadow-outline-blue appearance-none"
                type="date"
                {...register("date")}
              />
              {<small className="text-red-500">{errors.date?.message}</small>}
            </div>

            {/* CDP Day/Hour */}
            <div className=" mms:mb-6 mms:w-full ts:w-[45%]">
              <label className="text-subtitle mb-2 block">
                Hora de realización
              </label>
              <select
                className=" px-2 w-full h-[44px] bg-bg4 text-content outline-none rounded "
                {...register("hour", { required: true })}
              >
                <option value="01:00 AM">01:00 AM</option>
                <option value="02:00 AM">02:00 AM</option>
                <option value="03:00 AM">03:00 AM</option>
                <option value="04:00 AM">04:00 AM</option>
                <option value="05:00 AM">05:00 AM</option>
                <option value="06:00 AM">06:00 AM</option>
                <option value="07:00 AM">07:00 AM</option>
                <option value="08:00 AM">08:00 AM</option>
                <option value="09:00 AM">09:00 AM</option>
                <option value="10:00 AM">10:00 AM</option>
                <option value="11:00 AM">11:00 AM</option>
                <option value="12:00 PM">12:00 PM</option>
                <option value="13:00 PM">01:00 PM</option>
                <option value="14:00 PM">02:00 PM</option>
                <option value="15:00 PM">03:00 PM</option>
                <option value="16:00 PM">04:00 PM</option>
                <option value="17:00 PM">05:00 PM</option>
                <option value="18:00 PM">06:00 PM</option>
                <option value="19:00 PM">07:00 PM</option>
                <option value="20:00 PM">08:00 PM</option>
                <option value="21:00 PM">09:00 PM</option>
                <option value="22:00 PM">10:00 PM</option>
                <option value="23:00 PM">11:00 PM</option>
                <option value="00:00 AM">00:00 AM</option>
              </select>
              <small className="text-red-500">{errors.phone?.message}</small>
            </div>
          </div>

          {/* details */}
          <div className=" mb-6 w-full">
            <label className="text-subtitle block mb-2">
              Detalles <span className="text-Red">*</span>
            </label>{" "}
            <textarea
              className=" indent-2 w-full h-[100px] bg-bg4 text-content outline-none rounded "
              {...register("details")}
              maxLength={1024}
            ></textarea>
            {<small className="text-red-500">{errors.details?.message}</small>}
          </div>

          {/* managers */}
          <div className=" mb-6 w-full">
            <label className="text-subtitle block mb-2">
              Encargados <span className="text-Red">*</span>
            </label>{" "}
            <textarea
              className=" indent-2 w-full h-[100px] bg-bg4 text-content outline-none rounded "
              {...register("managers")}
              maxLength={256}
            ></textarea>
            {<small className="text-red-500">{errors.managers?.message}</small>}
          </div>

          <div className=" flex justify-center mt-8 w-full">
            <button className="bg-primary text-white w-full h-[44px] rounded uppercase">
              Crear
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
