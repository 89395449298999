/*
 * Esta pagina es para mostra un menu de opciones de Intercesión
 */
import { useContext } from "react";

import DeptoContext from "../../../context/Departments/Context";
import ProtectedDepartment from "../ProtectedDepartment";

// members
import MenuPrayPetitions from "../intersetion/members/MenuPrayPetitions";
// admin
import IntersetionHeaderAdmin from "./director/IntersetionHeaderAdmin";
import IntersetionIntegrants from "./director/IntersetionIntegrants";

export default function IntersetionContainer() {
  const { intersetionMenu, user } = useContext(DeptoContext);

  const changeAdminMenu = () => {
    switch (intersetionMenu.menu) {
      case "INTEGRANTS":
        return <IntersetionIntegrants />;
      case "PETITIONS":
        return <MenuPrayPetitions />;
      default:
        return <IntersetionIntegrants />;
    }
  };

  return (
    <ProtectedDepartment>
      <div className=" w-full h-full">
        {user?.is_director && <IntersetionHeaderAdmin />}
        <div
          className={` ${user?.is_director ? "h-[calc(100%-75px)]" : "h-full"}`}
        >
          {/* changeAdminMenu() */}
          {Object.keys(user).length > 0 ? (
            user?.is_director ? (
              changeAdminMenu()
            ) : (
              <MenuPrayPetitions />
            )
          ) : (
            <div>
              <h1>Cargando...</h1>
            </div>
          )}
        </div>
      </div>
    </ProtectedDepartment>
  );
}
