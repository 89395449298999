import axios from "axios";
import { useState, useContext } from "react";
import { useNotifications } from "reapop";

import DeptoContext from "../../context/Departments/Context";
import { fonts } from "../../assets/styles";

export default function AdminUserDetailsModal() {
  const { adminUserDetails: user } = useContext(DeptoContext);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const { notify } = useNotifications();

  const handleChangePassword = async () => {
    if (password === "") {
      notify({
        title: "Aviso",
        message: "Ingrese una contraseña por favor.",
        status: "warning",
      });
    }

    const { data } = await axios.put(
      `/api/v1/members/change-password/${user.code}`,
      { password }
    );
    if (data.status_code === 200) {
      setPassword("");
      notify({
        title: "Exito",
        message: "Contraseña reestablecida con éxito.",
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: data.message,
        status: "error",
      });
    }
  };

  return (
    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] w-full h-full ">
      <div className="">
        <div className=" mb-6 text-center">
          <h1 className={`mb-1 ${fonts.heading3} uppercase text-title`}>
            Detalles del usuario
          </h1>
        </div>
        {/* affirmer info */}
        <div className=" p-[20px] w-full h-auto bg-bg3 rounded-[10px]">
          <div>
            <p className=" mb-1 text-white">Información del usuario:</p>
            <div className=" ">
              <p className=" text-content">
                <i className=" mr-2 fa-solid fa-user"></i>
                {user?.name} {user?.lastname}
              </p>
              <p className=" my-1 text-content">
                <i className=" mr-2 fa-solid fa-envelope"></i>
                {user?.email}
              </p>
              <p className=" my-1 text-content">
                <i className=" mr-2 fa-solid fa-mobile"></i>
                {user?.phone}
              </p>
            </div>
          </div>
        </div>
        {/* new converts list */}
        <div className=" mt-[20px] py-[20px] w-full min-h-[250px] bg-bg2 rounded-[10px]">
          <div className=" mb-2 w-full text-[18px] text-center text-title ">
            <p className={`${fonts.heading3} uppercase `}>
              Reestablecer contraseña
            </p>
          </div>
          <div className=" p-[20px] h-[calc(300px-44px)] bg-bg3 rounded-[10px] overflow-y-auto">
            <div className=" mt-6 w-full">
              <label className="text-subtitle block mb-2">
                Nueva contraseña <span className="text-Red">*</span>
              </label>
              <div className=" flex justify-between ">
                <input
                  type={showPassword ? "text" : "password"}
                  className=" indent-2 w-[calc(100%-44px)] h-[44px] bg-bg4 text-title outline-none rounded "
                  onChange={(e) => setPassword(e.target.value)}
                  value={password || ""}
                  maxLength={32}
                />
                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className=" flex justify-center items-center w-[44px] bg-bg2 cursor-pointer "
                >
                  <i className={` fa-solid ${showPassword ? "fa-eye-slash" : "fa-eye"} `}></i>
                </div>
              </div>
              {<small className="text-red-500"></small>}
            </div>
            <div className=" flex justify-start mt-8 w-full">
              <button
                onClick={handleChangePassword}
                className="bg-primary text-white w-[150px] h-[44px] rounded uppercase "
              >
                Actualizar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
