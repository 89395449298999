import { useContext } from "react";

import DeptoContext from "../../../../context/Departments/Context";

export default function HeaderAdmin() {
  const { networksMenu, setNetworksMenu } = useContext(DeptoContext);

  return (
    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] flex items-center px-[80px] h-[75px] w-full border-b border-borders overflow-y-auto ">
      {/* integrants */}
      {/* <div className="flex items-center">
        <button
          onClick={() => {
            localStorage.setItem(
              "networks_menu",
              JSON.stringify({ menu: "INTEGRANTS" })
            );
            setNetworksMenu();
          }}
          className={` flex justify-center items-center h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            networksMenu?.menu === "INTEGRANTS"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          Integrantes
        </button>
        {networksMenu?.menu === "INTEGRANTS" && (
          <button
            onClick={() => {
              setMenuModal("ADD-MEMBER");
              setShowModal(true);
            }}
            className={` flex justify-center items-center ml-2 h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
              true ? "bg-primary text-white" : "bg-bg2 text-content"
            }`}
          >
            Agregar
          </button>
        )}
      </div>

      <div className=" mx-2 w-[1px] h-[36px] border-r border-options "></div> */}

      {/* networks */}
      <div className="flex items-center">
        <button
          onClick={() => {
            localStorage.setItem(
              "networks_menu",
              JSON.stringify({ menu: "NETWORKS-ADMIN" })
            );
            setNetworksMenu();
          }}
          className={` flex justify-center items-center h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            networksMenu?.menu === "NETWORKS-ADMIN"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          Redes
        </button>
        <button
          onClick={() => {
            localStorage.setItem(
              "networks_menu",
              JSON.stringify({ menu: "CREATE-NETWORKS" })
            );
            setNetworksMenu();
          }}
          className={` flex justify-center items-center ml-2 w-[100px] h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            networksMenu?.menu === "CREATE-NETWORKS"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          Crear Red
        </button>
      </div>

      <div className=" mx-2 w-[1px] h-[36px] border-r border-options "></div>

      {/* discipleships */}
      <div className="flex items-center">
        <button
          onClick={() => {
            localStorage.setItem(
              "networks_menu",
              JSON.stringify({ menu: "DISCIPLESHIPS" })
            );
            setNetworksMenu();
          }}
          className={` flex justify-center items-center h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            networksMenu?.menu === "DISCIPLESHIPS"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          Discipulados
        </button>
        <button
          onClick={() => {
            localStorage.setItem(
              "networks_menu",
              JSON.stringify({
                menu: "CREATE-DISCIPLESHIP",
              })
            );
            setNetworksMenu();
          }}
          className={` flex justify-center items-center ml-2 w-[150px] h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            networksMenu?.menu === "CREATE-DISCIPLESHIP"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          Crear Discipulado
        </button>
      </div>

      <div className=" mx-2 w-[1px] h-[36px] border-r border-options "></div>

      {/* attendances */}
      <div className="flex items-center mr-4">
        <button
          onClick={() => {
            localStorage.setItem(
              "networks_menu",
              JSON.stringify({
                menu: "ATTENDANCES",
              })
            );
            setNetworksMenu();
          }}
          className={` flex justify-center items-center h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            networksMenu?.menu === "ATTENDANCES"
              ? "bg-primary text-white"
              : "bg-bg2 text-content"
          }`}
        >
          Asistencias
        </button>
      </div>
    </div>
  );
}
