import { useContext } from "react";

import DeptoContext from "../../../../context/Departments/Context";

export default function VisitsHeaderAdmin() {
  const {
    setShowModal,
    setMenuModal,
    // setEducationMenu,
  } = useContext(DeptoContext);

  return (
    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] flex items-center px-[80px] h-[75px] w-full border-b border-borders">
      <div className="flex items-center mr-4">
        {/* <button
          onClick={() =>
            setEducationMenu({ menu: "ADMIN-INTEGRANTS", processId: null })
          }
          className={` flex justify-center items-center h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            true ? "bg-primary text-white" : "bg-bg2 text-content"
          }`}
        >
          Integrantes
        </button> */}
        <button
          onClick={() => {
            setMenuModal("ADD-MEMBER");
            setShowModal(true);
          }}
          className={` flex justify-center items-center ml-2 h-[36px] px-[10px] rounded-r-[5px] rounded-l-[5px] ${
            true ? "bg-primary text-white" : "bg-bg2 text-content"
          }`}
        >
          Agregar Integrante
        </button>
      </div>
    </div>
  );
}
