import {
  // get members
  GET_AVAILABLE_MEMBERS,
  GET_MEMBERS_TO_BE_STUDENTS,
  // departments
  GET_DIRECTORS_BY_DEPARMENT,
  GET_MEMBERS_BY_DEPARTMENT,
  GET_ALL_DEPARTMENTS,
  // department menu
  EDUCATION_MENU,
  AFIRMATION_MENU,
  VISITS_MENU,
  EVANGELISM_MENU,
  INTERSETION_MENU,
  LIBERATION_MENU,
  NETWORKS_MENU,
  NETWORKS_MEMBER_MENU,
  // get current user
  GET_USER,
  // modal
  SHOW_MODAL,
  MENU_MODAL,
  // tutor
  SET_TUTOR,
  // students
  GET_STUDENTS_BY_UNIT,
  SELECTED_STUDENT_TO_PETITION,
  // CDPs
  SET_SELECTED_CDP,
  // Selected Affirmer
  SELECTED_AFFIRMER,
  // Networks
  SELECTED_DISCIPLESHIP,
  SELECTED_NETWORK,
  // admin
  ADMIN_USER_DETAILS,
} from "../types";

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADMIN_USER_DETAILS:
      return { ...state, adminUserDetails: payload };
    case SELECTED_NETWORK:
      return { ...state, selectedNetwork: payload };
    case SELECTED_DISCIPLESHIP:
      return { ...state, selectedDiscipleship: payload };
    case SELECTED_AFFIRMER:
      return { ...state, selectedAffirmer: payload };
    case SET_SELECTED_CDP:
      return { ...state, selectedCdp: payload };
    // students
    case SELECTED_STUDENT_TO_PETITION:
      return { ...state, selectedStudentToPetition: payload };
    case GET_STUDENTS_BY_UNIT:
      return { ...state, studentsByUnit: payload };
    // departments
    case NETWORKS_MEMBER_MENU:
      return { ...state, networksMemberMenu: payload };
    case NETWORKS_MENU:
      return { ...state, networksMenu: payload };
    case LIBERATION_MENU:
      return { ...state, liberationMenu: payload };
    case INTERSETION_MENU:
      return { ...state, intersetionMenu: payload };
    case EVANGELISM_MENU:
      return { ...state, evangelismMenu: payload };
    case VISITS_MENU:
      return { ...state, visitsMenu: payload };
    case AFIRMATION_MENU:
      return { ...state, afirmationMenu: payload };
    case SET_TUTOR:
      return { ...state, tutor: payload };
    case MENU_MODAL:
      return { ...state, menuModal: payload };
    case SHOW_MODAL:
      return { ...state, show_modal: payload };
    case GET_USER:
      return { ...state, user: payload };
    case EDUCATION_MENU:
      return { ...state, educationMenu: payload };
    case GET_ALL_DEPARTMENTS:
      return { ...state, departments: payload };
    case GET_MEMBERS_BY_DEPARTMENT:
      return {
        ...state,
        membersByDepartment: payload,
      };
    case GET_DIRECTORS_BY_DEPARMENT:
      return {
        ...state,
        directorsByApartment: payload,
      };
    case GET_AVAILABLE_MEMBERS:
      return {
        ...state,
        availableMembers: payload,
      };
    case GET_MEMBERS_TO_BE_STUDENTS:
      return { ...state, membersToBeStudents: payload };
    default:
      return state;
  }
};

export default reducer;
