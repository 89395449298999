import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { useNotifications } from "reapop";
import { confirmAlert } from "react-confirm-alert";

import DeptoContext from "../../context/Departments/Context";
import { fonts } from "../../assets/styles";

export default function AffirmerDetails() {
  const { selectedAffirmer, setShowModal } = useContext(DeptoContext);
  const [newConverts, setNewConverts] = useState([]);
  const [showMembers, setShowMembers] = useState(false);

  const { notify } = useNotifications();

  const confirmationRemoveNewConvert = (code_new_convert) => {
    confirmAlert({
      title: "Remover Afirmador",
      message: `¿Está seguro que desea remover este Nuevo Creyente?`,
      buttons: [
        {
          label: "Sí",
          onClick: () => removeAffirmerAssignation(code_new_convert),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const removeAffirmerAssignation = async (code_new_convert) => {
    const requestData = {
      code_new_convert,
      code_affirmer: selectedAffirmer.code,
    };
    let resp = 0;
    if (showMembers) {
      const { data } = await axios.put(
        "/api/v1/affirmer/member/remove/asignation",
        requestData
      );
      resp = data;
    } else {
      const { data } = await axios.put(
        "/api/v1/affirmer/new-convert/remove/asignation",
        requestData
      );
      resp = data;
    }
    if (resp.status_code === 200) {
      notify({
        title: "¡Éxito!",
        message: resp.message,
        status: "success",
      });
    } else {
      notify({
        title: "Error",
        message: resp.message,
        status: "error",
      });
    }
    setShowModal(false);
  };

  const getMembersByAffirmer = async () => {
    const { data } = await axios.post(`/api/v1/member/affirmer/asignations/`, {
      email_affirmer: selectedAffirmer.email,
    });
    setNewConverts(data.new_converts);
  };
  const getNewConvertsByAffirmer = async () => {
    const { data } = await axios.post(
      `/api/v1/new_convert/affirmer/asignations/director`,
      { email_affirmer: selectedAffirmer.email }
    );
    setNewConverts(data.new_converts);
  };
  useEffect(() => {
    if (showMembers) {
      getMembersByAffirmer();
    } else {
      getNewConvertsByAffirmer();
    }
  }, [showMembers]);

  return (
    <div className=" mms:px-[10px] mm:px-[20px] ml:px-[40px] w-full h-full ">
      <div className="mb-[32px]">
        <div className=" mb-2 text-center">
          <h1 className={`mb-1 ${fonts.heading3} uppercase text-title`}>
            Detalles del Afirmador
          </h1>
        </div>
        {/* affirmer info */}
        <div className=" p-[20px] w-full h-auto bg-bg3 rounded-[10px]">
          <div>
            <p className=" mb-1 text-white">Información del afirmador:</p>
            <div className=" ">
              <p className=" text-content">
                <i className=" mr-2 fa-solid fa-user"></i>
                {selectedAffirmer.name} {selectedAffirmer.lastname}
              </p>
              <p className=" my-1 text-content">
                <i className=" mr-2 fa-solid fa-envelope"></i>
                {selectedAffirmer.email}
              </p>
              <p className=" my-1 text-content">
                <i className=" mr-2 fa-solid fa-mobile"></i>
                {selectedAffirmer.phone}
              </p>
            </div>
          </div>
        </div>

        {/* new converts list */}
        <div className=" mt-[20px] p-[20px] w-full min-h-[300px] bg-bg3 rounded-[10px]">
          <div className=" mb-1 text-subtitle ">
            <p>Mostrar:</p>
          </div>
          <div className=" mms:mb-2 mms:bg-bg3 ml:px-0 mm:bg-bg2 flex justify-start items-center h-auto overflow-x-auto rounded-[25px] ml:max-w-[350px] ">
            <div className=" flex items-center px-[2px] mms:w-full w-[300px] h-[32px] bg-[#D9D9D9] text-content rounded-[25px] ">
              <button
                onClick={() => setShowMembers(true)}
                className={` mms:w-[50%] h-[30px] ${
                  showMembers
                    ? " bg-primary text-white "
                    : " bg-[#d9d9d9] text-content"
                } ${fonts.body1} rounded-[25px] outline-none`}
              >
                Usuarios
              </button>
              <button
                onClick={() => setShowMembers(false)}
                className={` mms:w-[50%] h-[30px] ${
                  !showMembers
                    ? " bg-primary text-white "
                    : " bg-[#d9d9d9] text-content"
                } ${fonts.body1} rounded-[25px] outline-none`}
              >
                Creyentes
              </button>
            </div>
          </div>
          {/* new converts */}
          <div className=" text-content">
            <small className={` ${fonts.body1}`}>
              Listado de personas asignadas al afirmador{" "}
              <span className=" text-Green font-bold ">
                ({newConverts.length})
              </span>
            </small>
          </div>
          <div className=" p-[10px] h-[calc(300px-44px)] bg-bg4 rounded overflow-y-auto">
            {newConverts.length > 0 ? (
              newConverts.map((newConvert) => (
                <div
                  className=" mms:flex-col mms:items-start mms:h-auto tm:flex-row tm:items-center
                  flex justify-between items-center mb-[10px] pb-[6px] h-[50px] border-b border-content "
                  key={newConvert.id}
                >
                  <div>
                    <p className=" text-title">
                      {newConvert.name} {newConvert.lastname}
                    </p>
                    <small className=" text-content">
                      Código: {newConvert.code}
                    </small>
                  </div>
                  <button
                    onClick={() =>
                      confirmationRemoveNewConvert(newConvert.code)
                    }
                    className={` mms:mt-2 ts:mt-0 px-[10px] h-[28px] bg-Red ${fonts.body1} rounded`}
                  >
                    Remover
                  </button>
                </div>
              ))
            ) : (
              <p className=" text-center text-content ">No hay asignaciones</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
